import { CallOrderDetails } from "../Constants/CallOrder"

export const getcallorderdata = (callorder) => {
    return {
        type: CallOrderDetails.GET_CALLORDERDETAILS,
        payload: callorder
    };
};

export const savecallorderdata = (callorder) => {
    return {
        type: CallOrderDetails.POST_CALLORDERDETAILS,
        payload: callorder
    };
};

export const updatecallorderdata = (callorder) => {
    return {
        type: CallOrderDetails.UPDATE_CALLORDERDETAILS,
        payload: callorder
    };
};

export const deletecallorderdata = (callorder) => {
    return {
        type: CallOrderDetails.DELETE_CALLORDERDETAILS,
        payload: callorder
    };
};

export const deletegroupdata = (callorder) => {
    return {
        type: CallOrderDetails.DELETE_GROUP,
        payload: callorder
    };
};

export const updateusername = (callorder) => {
    return {
        type: CallOrderDetails.UPDATE_USERNAME,
        payload: callorder
    };
};

export const updategroupname = (callorder) => {
    return {
        type: CallOrderDetails.UPDATE_GROUPNAME,
        payload: callorder
    };
};

export const getsupportgroupdata = (callorder) => {
    return {
        type: CallOrderDetails.GET_SUPPORTGROUPDETAILS,
        payload: callorder
    };
};

export const getusersforsupport = (callorder) => {
    return {
        type: CallOrderDetails.GETUSERS_FORSUPPORTGROUP,
        payload: callorder
    };
};

export const savegroupandusers = (callorder) => {
    return {
        type: CallOrderDetails.POST_GROUPANDUSERS,
        payload: callorder
    };
};

export const getgroupsdata = (callorder) => {
    return {
        type: CallOrderDetails.GET_ALLGROUPS,
        payload: callorder
    };
};



