import React, { useEffect, useState, useRef } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import Layout from "../Layout";
import Table from "antd/lib/table";
import Loader from "../loader";
import TechnicianGroupList from "./TechnicianGroupList";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from "react-i18next";
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import { Tooltip } from "antd";
import {
    getAllAssignedTechniciansByGroup, putSupportDataOnCalendar, getSubCompaniesByUserId, postSupportEvent, putSupportEvent, deleteSupportEvent, getAllEventByGroupId,
    getallSupperAdmins, assignSupportFallback, getFallbackBySupportId, getUsersAndOrderByDate, swapTechnicianPresentOrder, deletefallback,
    getSitesBySupportGroupId
} from "../../Redux/Api/fetch.api";
import TechnicianIcon from "../../assets/images/customer-support.png";
import { Scheduler } from 'smart-webcomponents-react/scheduler';
import '../../../src/calendar.css';
import clockIcon from "../../assets/images/clock-icon.svg";
import Clock from "../Support/Clock";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Title from "../Title";
import SettingsIcon from '@mui/icons-material/Settings';
import Trash_light from "../../assets/images/Trash_light.svg";
import { Modal } from 'antd';

const SupportTechnician = () => {
    const { t } = useTranslation('supportCalender');
    const scrollButtonsPosition = 'far';
    const schedulerRef = useRef();
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [technicians, setTechnicians] = useState([]);
    const [grouptechnicians, setGroupTechnicians] = useState([]);
    const [events, setEvents] = useState([]);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [groupId, setGroupId] = useState(null);
    const [siteId, setSiteId] = useState(null);
    const [supportId, setSupportId] = useState(null);
    const [dataLoaded, setDataLoaded] = useState(false);
    const history = useHistory();
    const [selectedTechnicians, setSelectedTechnicians] = useState([]);
    const [resourceTechnicians, setResourceTechnicians] = useState([]);
    const timeZoneName = "Eastern Standard Time"
    const [statuses, setStatuses] = useState([]);
    const [orderUpdated, setOrderUpdated] = useState(false);
    const [supperAdmins, setSupperAdmins] = useState([]);
    const [selectedAdmin, setSelectedAdmin] = useState("");
    const [fallbackId, setFallbackId] = useState(0);
    const [fallbackName, setFallbackName] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const [draggedUserId, setDraggedUserId] = useState();
    const [draggedOrderId, setDraggedOrderId] = useState();
    const [droppedUserId, setTechnicianDropUserId] = useState();
    const [droppedOrderId, setTechnicianDropOrderId] = useState();
    const [presentDate, setPresentDate] = useState();
    const [isShadeActive, setIsShadeActive] = useState(true);
    const [isPanelOpen, setIsPanelOpen] = useState(true);
    const [isCurrentTimeIndicator, setIsCurrentTimeIndicator] = useState(true);
    const [currentTime, setCurrentTime] = useState(new Date());
    const [supportedSites, setSupportedSites] = useState([]);

    let siteid;
    let supportid;
    let groupid;
    const views = ['day', 'week', 'month'];
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const yesterdayStart = new Date(today);
    yesterdayStart.setDate(today.getDate() - 1);

    // #region Handle TimeZone
    dayjs.extend(utc);
    dayjs.extend(timezone);

    useEffect(() => {
        // Remove panel when component is unmounted (or when route changes)
        return () => {
            let panel = document.getElementById('change-order-panel');
            if (panel) {
                panel.remove();
            }
        };
    }, []);

    useEffect(() => {

        // Parse query parameters from the URL
        const queryParams = new URLSearchParams(location.search);
        const siteIdFromUrl = queryParams.get('siteId');
        const supportIdFromUrl = queryParams.get('supportId');
        const groupIdFromUrl = queryParams.get('groupId');
        // Check if `groupIdFromUrl` is a valid number and greater than 0
        if (!isNaN(groupIdFromUrl) && groupIdFromUrl > 0) {
            groupid = groupIdFromUrl
            siteid = siteIdFromUrl;
            supportid = supportIdFromUrl;
            // Update state with the groupId obtained from URL
            setGroupId(groupIdFromUrl);
            getAllAssignedTechnician(groupIdFromUrl)
            initializeEventScheduler(groupIdFromUrl)
            getFallbackAdminBySupportID(groupIdFromUrl);
            getAllFallbackSupperAdmins();
            getAllActiveSitesBySupportGroupId(groupIdFromUrl);
            history.replace({ search: queryParams.toString() });
        }
        setOrderUpdated(false);
    }, [location.search, orderUpdated]);

    useEffect(() => {
        const schedulerElement = schedulerRef.current.nativeElement;

        // Add event listener to allow drag over events (to prevent default browser behavior)
        schedulerElement.addEventListener('dragover', (e) => e.preventDefault());

        // Add event listener to handle the drop event (for external drag-and-drop functionality)
        schedulerElement.addEventListener('drop', handleDrop);

        // Add event listeners for tooltips
        document.addEventListener('mouseover', handleMouseOver);
        document.addEventListener('mouseout', handleMouseOut);

        // Cleanup: remove event listeners when the component is unmounted
        return () => {
            schedulerElement.removeEventListener('dragover', (e) => e.preventDefault());
            schedulerElement.removeEventListener('drop', handleDrop);

            // Clean up event listeners on component unmount
            document.removeEventListener('mouseover', handleMouseOver);
            document.removeEventListener('mouseout', handleMouseOut);
        };
    }, []);

    useEffect(() => {
        filterEvents();
    }, [selectedTechnicians, events]);

    useEffect(() => {

        if (droppedOrderId > 0 && droppedUserId !== draggedUserId)
            swapTechnicianOrder();
    }, [droppedOrderId]);

    useEffect(() => {
        // Initial set of the EST time
        setCurrentTime(getESTTime());

        // Update the current time every minute (60000ms)
        const interval = setInterval(() => {
            setCurrentTime(getESTTime());
        }, 60000);

        // Clean up the interval on component unmount
        return () => clearInterval(interval);
    }, []);

    const filterEvents = () => {
        // Filter the events based on selected technicians
        // If no technicians are selected, include all events
        const filteredEvents = events.filter(event =>
            selectedTechnicians.length === 0 || selectedTechnicians.some(selected => selected.id === event.clientId)
        );
        // Update the data source of the scheduler with the filtered events
        schedulerRef.current.dataSource = new window.Smart.DataAdapter({
            dataSource: filteredEvents,
            dataSourceType: 'array',
            dataFields: getSchedulerDataFields()
        });
    };

    const getTechniciansByDate = async (date) => {
        setPresentDate(date)
        const payload = {
            groupId: groupid != null ? groupid : groupId,
            date: date,
        };
        try {
            let response;
            response = await getUsersAndOrderByDate(payload);
            if (response.data && response.data.data) {
                return response.data.data; // Assuming response.data.data is an array of technicians

            }
            return [];
        }
        catch (err) {
            // Log the error and display an error toast notification
            console.error("Error:", err);
        }
    }

    const swapTechnicianOrder = async () => {
        setLoading(true)
        const payload = {
            oldUserId: draggedUserId,
            oldCallOrderId: draggedOrderId,
            newUserId: droppedUserId,
            newCallOrderId: droppedOrderId,
            groupId: groupid != null ? groupid : groupId,
            date: presentDate
        };

        try {
            let response;
            response = await swapTechnicianPresentOrder(payload);

            if (response.data.success) {
                await updateTechnicianPanel(presentDate);
                initializeEventScheduler(groupid != null ? groupid : groupId);
                toast.success(response.data.message, {
                    className: "toastSuccess",
                });
            }
            setLoading(false)
            setDraggedUserId()
            setDraggedOrderId()
            setTechnicianDropOrderId()
            setTechnicianDropUserId()

            return [];
        }
        catch (err) {
            // Log the error and display an error toast notification
            console.error("Error:", err);
        }

    }

    const updateTechnicianPanel = async (date) => {

        // Fetch the updated technicians list
        const updatedTechnicians = await getTechniciansByDate(date);

        // Find the technician list inside the panel
        const technicianList = document.getElementById('change-order-panel').querySelector('ul');

        // Clear the current list
        technicianList.innerHTML = '';

        // Populate the list with updated technicians
        updatedTechnicians.forEach((technician, index) => {
            bindTechnician(technician, index, technicianList);
        });

        setDraggedUserId()
        setDraggedOrderId()
        setTechnicianDropOrderId()
        setTechnicianDropUserId()
    };

    // Get the fallbacl supper admin 
    const getAllFallbackSupperAdmins = async () => {

        try {
            const { data: { data: supperAdmins } } = await getallSupperAdmins();
            setSupperAdmins(supperAdmins);

        } catch (error) {
            console.error("Error fetching support events:", error);  // Log any errors encountered
        }
    };

    // Get the fallbacl supper admin 
    const getFallbackAdminBySupportID = async (supportGroupId) => {

        try {

            const response = await getFallbackBySupportId(supportGroupId);

            if (response.data.data && response.data.data.superAdminId > 0) {
                setFallbackName(response.data.data.fallbackPerson);
                setSelectedAdmin(response.data.data.superAdminId);
                setFallbackId(response.data.data.fallbackId);
            }
            //else {
            //    getAllFallbackSupperAdmins();
            //}

        } catch (error) {
            console.error("Error fetching support events:", error);  // Log any errors encountered
        }
    };

    // Get the fallbacl supper admin 
    const getAllActiveSitesBySupportGroupId = async (supportGroupId) => {

        try {
            const response = await getSitesBySupportGroupId(supportGroupId);

            if (response.data && response.data.data.length > 0) {
                setSupportedSites(response.data.data);
            }

        } catch (error) {
            console.error("Error fetching support events:", error);  // Log any errors encountered
        }
    };

    // Function to assign or update fallback
    const assignFallback = async () => {
        let successMessage;
        let successUpdateMessage;

        if (!selectedAdmin) {
            toast.error("Please select a user"), {
                className: "toastSuccess",
            };
            return;
        }

        const payload = {
            fallbackId: fallbackId,
            supportGroupId: groupId,
            superAdminId: selectedAdmin,
            assignedBy: localStorage.getItem('UserId')
        };

        try {
            let response;

            // Assign new fallback
            response = await assignSupportFallback({ payload });
            successMessage = t("Fallbackhasbeensuccessfullyassigned")
            successUpdateMessage = t("Fallbackhasbeensuccessfullyupdated")

            if (response && response.data.success) {
                getFallbackAdminBySupportID(groupId);
                setIsEdit(false)
                if (fallbackId == 0) {
                    toast.success(successMessage, {
                        className: "toastSuccess",
                    });
                }
                else {
                    toast.success(successUpdateMessage, {
                        className: "toastSuccess",
                    });
                }
            }


            //if (response && response.data.success) {
            //    toast.success(response.data.message, {
            //        className: "toastSuccess",
            //    });
            //}
        } catch (error) {
            console.error("Error assigning/updating fallback:", error);
        }
    };

    // Function to format the header of a timeline based on orientation and date details
    const timelineHeaderFormatFunction = (date, orientation, isHeaderDetails, dateValue) => {
        // Check if the timeline orientation is horizontal
        if (orientation === 'horizontal') {
            const segments = dateValue.split(' ');
            // If the dateValue contains exactly two segments (e.g., day number and day name)
            if (segments.length === 2) {
                // Return formatted HTML with the day name and day number in separate divs
                return `<div>${segments[1]}</div><div class="header-day-number">${segments[0]}</div>`;
            }
        }
        return dateValue;
    };

    const resources = [
        {
            label: 'Technician',
            value: 'userId',
            dataSource: resourceTechnicians
        }
    ];

    const initializeEventScheduler = async (groupid) => {

        try {

            // Fetch events from the API
            const response = await getAllEventByGroupId(groupid);

            // Convert the event times to the user's local time zone
            const eventsInLocalTime = response.data.data.map(event => ({
                ...event,
                // Ensure StartDate and EndDate are formatted in the user's local time
                StartDate: dayjs(event.StartDate).tz(dayjs.tz.guess()).format('YYYY-MM-DDTHH:mm:ss'),
                EndDate: dayjs(event.EndDate).tz(dayjs.tz.guess()).format('YYYY-MM-DDTHH:mm:ss')
            }));

            // Initialize the scheduler with the converted event data
            schedulerRef.current.dataSource = new window.Smart.DataAdapter({
                dataSource: eventsInLocalTime, // Use converted event times
                dataSourceType: 'array',
                dataFields: getSchedulerDataFields()
            });

            // Set the events in the local state
            setEvents(eventsInLocalTime);

            // Apply any custom calendar settings (if needed)
            await customCalendarSettings();
        } catch (error) {
            // Handle any errors that occur during the event fetching process
            console.error("Error fetching support events:", error);
        }
    };

    // Function to apply custom settings to the calendar scheduler component
    const customCalendarSettings = () => {
        const scheduler = document.querySelector('smart-scheduler');

        // Listen for the edit dialog opening event on the scheduler
        scheduler.addEventListener('editDialogOpen', async function (event) {
            // Get the editors used in the edit dialog
            const editors = event.detail.editors;
            // If there are no editors, exit the function
            if (!editors) {
                return;
            }
            // Get references to various editors and elements in the dialog
            const schedulerEvent = event.detail.item,
                allDayEditor = editors.allDay,
                repeatEditor = editors.repeat,
                statusEditor = editors.status,
                labelEditor = editors.label,
                descriptionEditor = editors.description,
                notificationEditor = editors.notifications,
                disableLabel = editors.label,
                disableStartDate = editors.dateStart,
                disableEndDate = editors.dateEnd,
                disableBackgroundColor = editors.backgroundColor,
                resourceDataEditor = editors.resourceData,
                resourcesEditor = editors.resources;

            // Hidden all properties 
            repeatEditor.classList.add('smart-hidden');
            allDayEditor.classList.add('smart-hidden');
            notificationEditor.classList.add('smart-hidden');
            resourceDataEditor.classList.add('smart-hidden');
            resourcesEditor.classList.add('smart-hidden');

            // Set custom placeholders for the label and description editors
            labelEditor.querySelector('.smart-element').placeholder = 'Enter Support Note...';
            descriptionEditor.querySelector('.smart-element').placeholder = 'Enter Support Description...';
            statusEditor.querySelector('#schedulerstatusLabel').textContent = "Sub Companies"


            // Get today's date without time for comparison
            const today_ = new Date();
            today_.setHours(0, 0, 0, 0);  // Set time to 00:00:00 for accurate date comparison

            // Get the start date from the scheduler event and remove the time component
            const startDate = new Date(schedulerEvent.dateStart);
            startDate.setHours(0, 0, 0, 0);  // Set time to 00:00:00 for accurate date comparison

            // Check if the start date is before today or if the event falls within restricted hours
            if (startDate < today_) {
                // Hide Ok footer 
                event.detail.target.footerPosition = 'none';
                disableLabel.classList.add('smart-disabled');
                descriptionEditor.classList.add('smart-disabled');
                disableStartDate.classList.add('smart-disabled');
                disableEndDate.classList.add('smart-disabled');
                disableBackgroundColor.classList.add('smart-disabled');
            } else {
                // Show Ok footer 
                event.detail.target.footerPosition = 'bottom';
                disableLabel.classList.remove('smart-disabled');
                descriptionEditor.classList.remove('smart-disabled');
                disableStartDate.classList.remove('smart-disabled');
                disableEndDate.classList.remove('smart-disabled');
                disableBackgroundColor.classList.remove('smart-disabled');
            }
        });
    }

    function getSchedulerDataFields() {
        return [
            { name: 'id', map: 'eventId', dataType: 'number' },
            { name: 'label', map: 'label', dataType: 'string' },
            { name: 'dateStart', map: 'startDate', dataType: 'date' }, // Change to 'date'
            { name: 'dateEnd', map: 'endDate', dataType: 'date' },     // Change to 'date'
            { name: 'description', map: 'description', dataType: 'string' },
            { name: 'allDay', map: 'isAllDay', dataType: 'bool' },
            { name: 'backgroundColor', map: 'backGroundColor', dataType: 'string' },
            { name: 'isDrag', map: 'isDrag', dataType: 'bool' },
            { name: 'userId', map: 'clientId', dataType: 'number' },
            { name: 'orderId', map: 'orderId', dataType: 'number' },
            { name: 'groupName', map: 'supportGroup', dataType: 'string' },
            { name: 'conference', map: 'conference', dataType: 'string' },
            { name: 'status', map: 'status', dataType: 'string' }
        ];
    }

    const getAllAssignedTechnician = async (groupIdFromUrl) => {
        try {
            const response = await getAllAssignedTechniciansByGroup(groupIdFromUrl);  // Fetch events from API

            // Map the unique clients as resources for the scheduler (technicians)
            const technicians_ = response.data.data.map(resource => ({
                label: resource.userName,  // Display name for the technician
                id: resource.clientId,   // Unique ID for the technician,
                supportGroup: resource.supportGroup,
                orderId: resource.orderId,
                image: TechnicianIcon,
                groupId: groupIdFromUrl,
                timeZone: resource.timeZone
            }));

            setTechnicians(technicians_);
            setResourceTechnicians(technicians_);

        } catch (error) {
            console.error("Error fetching support events:", error);  // Log any errors encountered
        }
    };

    const toggleCollapse = () => {
        setIsCollapsed(prevState => !prevState);  // Toggle the collapse state
    };

    const handleTechnicianSelection = (id) => {
        const technician = technicians.find(t => t.id === id);

        setSelectedTechnicians(prev => {
            const updatedSelected = prev.some(t => t.id === id)
                ? prev.filter(t => t.id !== id) // If technician is already selected, remove it
                : [...prev, technician]; // If not selected, add it

            //  Update resource technicians with the new selected technicians
            if (updatedSelected && updatedSelected.length > 0) {
                setResourceTechnicians(updatedSelected);
            }
            else {
                setResourceTechnicians(technicians);
            }

            return updatedSelected; // Return the updated state
        });
    };

    // Function to handle the drag start event for external tasks (technicians)
    const handleDragStart = (event, label, bgColor, userId, supportGroup, orderId, groupId) => {
        const data = JSON.stringify({ label, bgColor, userId, supportGroup, orderId, groupId });  // Serialize data as JSON
        event.dataTransfer.setData('application/json', data);  // Set the data in JSON format
    };

    const handleDrop = (event) => {
        const data = event.dataTransfer.getData('application/json');  // Retrieve the label of the dropped task
        const { label, bgColor, userId, orderId } = JSON.parse(data);
        const scheduler = schedulerRef.current;
        if (scheduler) {
            // Get the drop location date/time based on the coordinates of the drop event
            const dropDate = scheduler.getDateFromCoordinates(event.clientX, event.clientY);
            const startDate = new Date(dropDate);
            startDate.setHours(0, 0, 0, 0);  // Set time to 00:00:00 for accurate date comparison

            // Validation checking 
            if (startDate >= yesterdayStart) {
                const newEvent = {
                    label: label,  // Set the label of the event (technician's name)
                    dateStart: dropDate,  // Start time of the new event
                    dateEnd: new Date(dropDate.getTime() + 3600000), // Set duration to 1 hour
                    backgroundColor: bgColor,  // Background color for the event
                    userName: label,
                    userId: userId,
                    description: "",
                    //orderId: orderId
                };

                // Add the new event to the existing list of events
                setEvents((prevEvents) => {
                    const updatedEvents = [...prevEvents, newEvent];
                    scheduler.dataSource = [];
                    schedulerRef.current.dataSource = new window.Smart.DataAdapter({
                        dataSource: updatedEvents,
                        dataSourceType: 'array',
                        dataFields: getSchedulerDataFields()
                    });

                    // Insert data into the databse on drag and drop
                    refreshData('insert', newEvent);
                    return updatedEvents;
                });
            }
            else {
                toast.error(t("Pleasenoteupdates"), {
                    className: "toastError",
                });
            }
        }
    };

    const refreshData = async (action, eventItem) => {
        let response;
        let successMessage;
        let errorMessage;
        if (!eventItem.userId) {
            toast.error("Event not added", {
                className: "toastError",
            });
            return;
        }

        // Update local groupId
        const localGroupId = groupid != null ? groupid : groupId;

        // Prepare the payload with the exact date/time as selected by the user
        const payload = {
            EventId: eventItem.id,
            EventName: eventItem.label,
            // Send date as it is without any conversion
            StartDate: dayjs(eventItem.dateStart).format('YYYY-MM-DDTHH:mm:ss'),
            EndDate: dayjs(eventItem.dateEnd).format('YYYY-MM-DDTHH:mm:ss'),
            Description: eventItem.description,
            IsAllDay: eventItem.allDay,
            SupportUserGroupId: eventItem.userId,
            BackGroundColor: eventItem.backgroundColor,
            Conference: eventItem.conference,
            GroupID: localGroupId,
            OrderId: eventItem.orderId
        };

        try {

            let response;
            switch (action) {
                case 'insert':
                    response = await postSupportEvent(payload); // Handle Insert
                    successMessage = t("Eventcreatedsuccessfully")
                    errorMessage = t("Noeventsadded")

                    break;
                case 'update':
                    response = await putSupportEvent(payload); // Handle Update
                    successMessage = t("Eventupdatedsuccessfully")
                    errorMessage = t("Eventnotfoundorcouldnotbeupdated")
                    await initializeEventScheduler(localGroupId); // Refresh event data

                    break;
                case 'delete':

                    response = await deleteSupportEvent(eventItem.id); // Handle Delete
                    successMessage = t("Eventdeletedsuccessfully")
                    errorMessage = t("Eventnotfound.")
                    break;
                default:
                    throw new Error("Unknown action type");
            }
            if (response.data.success) {
                toast.success(successMessage, {
                    className: "toastSuccess",
                });


            } else {
                toast.error(errorMessage, {
                    className: "toastError",
                });
                // Refresh event data

            }
            //if (siteid > 0 && supportid > 0) {
            //    await updateSupportData();
            //}

            // Remove the opened panel (Change Order)
            let panel = document.getElementById('change-order-panel');
            if (panel) {
                panel.remove(); // Remove the old panel
            }

            await initializeEventScheduler(localGroupId); // Refresh event data
        } catch (err) {
            console.error("Error:", err);

        }
    };

    const handleTechnicianDragStart = (technician) => {

        setDraggedUserId(technician.clientId);
        setDraggedOrderId(technician.orderId)
    };

    const handleTechnicianDrop = (technician) => {
        setTechnicianDropUserId(technician.clientId)
        setTechnicianDropOrderId(technician.orderId)
        // swapTechnicianOrder();
    };

    // Function to customize the appearance of an event in the scheduler 
    const eventTemplate = function (eventContent, eventObj) {

        const scheduler = this;
        // Wrapper for event content
        let eventContentWrapper = eventContent.querySelector('.event-content-wrapper');
        if (!eventContentWrapper) {
            eventContentWrapper = document.createElement('div');
            eventContentWrapper.classList.add('event-content-wrapper');
        }
        // Get or create various elements for the event display
        let label = eventContent.querySelector('label'),
            time = eventContent.querySelector('div.time'),
            orderNoContainer = eventContent.querySelector('.support-order-no-container'),
            // descLabel = eventContent.querySelector('.desc'),
            orderIdCircle = eventContent.querySelector('.support-Orderid-circle'),
            groupName = eventContent.querySelector('.support-GroupName'),
            img = eventContent.querySelector('img'),
            inviteButton = eventContent.querySelector('.invite-link-button'); // Check for existing button

        // Label for the technician's name or event
        if (!label) {
            label = document.createElement('label');
            label.classList.add('time', 'support-technician-name');
            label.title = "Click to reorder"; // Add tooltip to the label
            eventContentWrapper.appendChild(label);

            label.onclick = async () => {

                let gettechnicians = [];
                // Call the api and get the technicians based on selcted date and support group
                gettechnicians = await getTechniciansByDate(formatDate(eventObj.dateStart));

                // Check if the panel already exists
                let panel = document.getElementById('change-order-panel');
                if (panel) {
                    panel.remove(); // Remove the old panel
                }

                // Create the panel element
                panel = document.createElement('div');
                panel.id = 'change-order-panel';
                panel.style.position = 'fixed';
                panel.style.right = '0';
                panel.style.top = '0';
                panel.style.width = '500px';
                panel.style.height = '100%';
                panel.style.backgroundColor = '#585858';
                panel.style.boxShadow = '0 0 10px rgba(0,0,0,0.3)';
                panel.style.padding = '20px';
                panel.style.overflowY = 'auto';
                panel.style.zIndex = '1000';
                panel.style.fontFamily = 'Arial, sans-serif';

                // Add header with close icon
                const header = document.createElement('div');
                header.style.display = 'flex';
                header.style.justifyContent = 'space-between';
                header.style.alignItems = 'center';
                header.style.marginBottom = '20px';

                const headerTitle = document.createElement('h3');
                headerTitle.textContent = 'Change Technician Order';
                header.appendChild(headerTitle);

                const closeButton = document.createElement('button');
                closeButton.style.border = 'none';
                closeButton.style.background = 'none';
                closeButton.style.fontSize = '16px';
                closeButton.style.cursor = 'pointer';
                closeButton.style.color = '#fff';
                closeButton.title = 'Click to close';
                closeButton.textContent = '✖'; // Close icon
                closeButton.onclick = () => {
                    panel.remove(); // Remove the panel when the close button is clicked
                };
                header.appendChild(closeButton);
                panel.appendChild(header);

                // Add icon and text (Selected Date)
                const selectedDateContainer = document.createElement('div');
                selectedDateContainer.style.display = 'flex';
                selectedDateContainer.style.alignItems = 'center';
                selectedDateContainer.style.backgroundColor = '#f4a261';
                selectedDateContainer.style.borderRadius = '10px';
                selectedDateContainer.style.padding = '10px 15px';
                selectedDateContainer.style.marginBottom = '20px';

                const circleIcon = document.createElement('div');
                circleIcon.style.width = '30px';
                circleIcon.style.height = '30px';
                circleIcon.style.borderRadius = '50%';
                circleIcon.style.backgroundColor = '#007bff';
                circleIcon.style.display = 'flex';
                circleIcon.style.justifyContent = 'center';
                circleIcon.style.alignItems = 'center';
                circleIcon.style.marginRight = '10px';

                const circleIconContent = document.createElement('span');
                circleIconContent.textContent = '📅'; // Use emoji or replace with an image for date
                circleIconContent.style.color = 'white';
                circleIcon.style.fontSize = '14px';
                circleIcon.appendChild(circleIconContent);

                const selectedDateText = document.createElement('span');
                selectedDateText.textContent = 'Selected Date : ' + formatDate(eventObj.dateStart); // Replace with dynamic date if needed
                selectedDateText.style.color = '#000';
                selectedDateText.style.fontWeight = 'bold';

                selectedDateContainer.appendChild(circleIcon);
                selectedDateContainer.appendChild(selectedDateText);
                panel.appendChild(selectedDateContainer);

                // Add draggable list of technicians with Order ID
                const technicianList = document.createElement('ul');
                technicianList.style.listStyle = 'none';
                technicianList.style.padding = '0';
                technicianList.style.margin = '0';

                // bind technicians 
                gettechnicians.forEach((technician, index) => {
                    bindTechnician(technician, index, technicianList);
                });

                panel.appendChild(technicianList);
                document.body.appendChild(panel);
            };
        }

        // Time label
        if (!time) {
            time = document.createElement('div');
            time.classList.add('time', 'support-small-time');
            eventContentWrapper.appendChild(time);
        }

        // Group Name
        if (!groupName) {
            groupName = document.createElement('div');
            groupName.classList.add('support-GroupName');
            eventContentWrapper.appendChild(groupName);
        }

        // Order No container for aligning Order ID and Circle
        if (!orderNoContainer) {
            orderNoContainer = document.createElement('div');
            orderNoContainer.classList.add('support-order-no-container');
            eventContentWrapper.appendChild(orderNoContainer);
        }

        // Order ID circle
        if (!orderIdCircle) {
            orderIdCircle = document.createElement('div');
            orderIdCircle.classList.add('support-Orderid-circle');
            orderNoContainer.appendChild(orderIdCircle);
        }

        // Set text content
        label.innerHTML = eventObj.orderId
            ? `${eventObj.label} <span class="support-tech-order-circle">${eventObj.orderId}</span>`
            : `${eventObj.label}`;

        let startFrom = new Intl.DateTimeFormat(scheduler.locale, {
            hour: scheduler.hourFormat,
            minute: scheduler.minuteFormat
        }).format(eventObj.dateStart);

        let endTo = new Intl.DateTimeFormat(scheduler.locale, {
            hour: scheduler.hourFormat,
            minute: scheduler.minuteFormat
        }).format(eventObj.dateEnd);

        time.textContent = `${startFrom} - ${endTo}`;

        // Set the Order ID circle color and text
        orderIdCircle.style.backgroundColor = eventObj.orderIdColor || '#FF5722'; // Default color
        orderIdCircle.textContent = eventObj.orderId;

        // Check if "Order Id:" label already exists
        let orderIdLabel = orderNoContainer.querySelector('.support-order-id-label');
        if (!orderIdLabel) {
            orderIdLabel = document.createElement('span');
            orderIdLabel.classList.add('support-order-id-label');
            orderIdLabel.textContent = "Order Id : ";
            orderNoContainer.insertBefore(orderIdLabel, orderIdCircle); // Insert before the circle
        }

        // Set additional information
        // descLabel.textContent = "Description : " + (eventObj.description || "N/A");
        groupName.textContent = "Group : " + (eventObj.groupName || "N/A");

        if (!eventContentWrapper.parentElement) {
            eventContent.appendChild(eventContentWrapper);
        }

        // Add right-click event (context menu) to the entire event
        eventContent.oncontextmenu = (event) => {
            event.preventDefault(); // Prevent the default browser context menu
            console.log('Right-click detected on the event!');
            openRightClickMenu(event, eventObj); // Call a custom function to handle the right-click menu
        };

        return eventContent;
    }

    const bindTechnician = (technician, index, technicianList) => {
        const listItem = document.createElement('li');
        listItem.className = 'change-order-list-item';
        listItem.style.setProperty('--bgColor', technician.bgColor || '#ffffff');
        listItem.draggable = true;

        // Circle for Order ID
        const orderCircle = document.createElement('div');
        orderCircle.className = 'change-order-circle';
        orderCircle.textContent = technician.orderId;

        // Technician Name
        const techName = document.createElement('span');
        techName.textContent = technician.userName;

        // Drag Icon
        const dragIcon = document.createElement('span');
        dragIcon.className = 'change-order-drag-icon';
        dragIcon.textContent = '≡'; // Drag icon (you can replace this with an actual image or SVG)
        dragIcon.title = "Technician drag and drop"

        listItem.appendChild(orderCircle);
        listItem.appendChild(techName);
        listItem.appendChild(dragIcon);

        // Add drag event listeners
        listItem.addEventListener('dragstart', (e) => {
            e.dataTransfer.setData('text/plain', index.toString());
            handleTechnicianDragStart(technician);
            listItem.classList.add('dragging');
        });

        listItem.addEventListener('dragend', () => {
            listItem.classList.remove('dragging');
        });

        listItem.addEventListener('dragover', (e) => {
            e.preventDefault(); // Allow dropping
            listItem.classList.add('dragover');
        });

        listItem.addEventListener('dragleave', () => {
            listItem.classList.remove('dragover');
            listItem.classList.add('dragleave');
        });

        listItem.addEventListener('drop', (e) => {
            e.preventDefault();
            const draggedIndex = parseInt(e.dataTransfer.getData('text/plain'), 10);
            const draggedItem = technicianList.children[draggedIndex];
            handleTechnicianDrop(technician);
            listItem.classList.remove('dragover', 'dragleave');
        });

        technicianList.appendChild(listItem);
    }

    // Function to handle right-click menu
    const openRightClickMenu = (event, eventObj) => {

        // Remove existing context menu if any
        let existingMenu = document.getElementById('schedule-context-menu');
        if (existingMenu) existingMenu.remove();

        // Create a custom context menu
        const contextMenu = document.createElement('div');
        contextMenu.id = 'schedule-context-menu';
        contextMenu.style.top = `${event.clientY}px`;
        contextMenu.style.left = `${event.clientX}px`;

        // Add blue circle
        const blueCircle = document.createElement('div');
        blueCircle.className = 'blue-circle';
        blueCircle.style.backgroundColor = eventObj.backgroundColor;
        contextMenu.appendChild(blueCircle);

        // Add event details container with border
        const eventDetails = document.createElement('div');
        eventDetails.className = 'event-details';

        // Event name
        const eventName = document.createElement('div');
        eventName.className = 'event-name';
        eventName.textContent = eventObj.label;

        // Circle with order ID
        const orderIdCircle = document.createElement('div');
        orderIdCircle.className = 'order-id-circle';
        orderIdCircle.textContent = eventObj.orderId;

        eventName.appendChild(orderIdCircle);

        // Event time
        const eventTime = document.createElement('div');
        eventTime.className = 'event-time';
        eventTime.textContent = formatTimeRange(eventObj.dateStart, eventObj.dateEnd);

        eventDetails.appendChild(eventName);
        eventDetails.appendChild(eventTime);
        contextMenu.appendChild(eventDetails);

        // Add delete icon
        const deleteIcon = document.createElement('div');
        deleteIcon.className = 'delete-icon';

        const deleteImg = document.createElement('img');
        deleteImg.src = Trash_light;
        deleteImg.alt = 'Delete Icon';
        deleteImg.title = "Click to delete event";

        deleteIcon.appendChild(deleteImg);
        deleteIcon.onclick = () => {
            const confirmDelete = window.confirm('Are you sure you want to delete this event?');
            if (confirmDelete) {
                refreshData('delete', eventObj);
                contextMenu.remove(); // Remove the menu after action
            }
        };

        contextMenu.appendChild(deleteIcon);

        // Append the context menu to the body
        document.body.appendChild(contextMenu);

        // Remove the menu if clicked outside
        const closeContextMenu = (e) => {
            if (!contextMenu.contains(e.target)) {
                contextMenu.remove();
                document.removeEventListener('click', closeContextMenu);
            }
        };

        document.addEventListener('click', closeContextMenu);
    };

    const formatTimeRange = function (startDate, endDate) {
        const options = { hour: 'numeric', minute: 'numeric' }; // 12-hour format
        const formatter = new Intl.DateTimeFormat('en-US', options);

        const startTime = formatter.format(new Date(startDate));
        const endTime = formatter.format(new Date(endDate));

        return `${startTime} - ${endTime}`;
    }

    const formatDate = function (date) {
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();

        return `${month}-${day}-${year}`;
    }

    const handleSchedulerUpdate = async (detail, isDrag = false) => {
        const { item, itemDateRange } = detail;
        if (itemDateRange.dateStart && itemDateRange.dateEnd) {
            // Get the start date from the scheduler event and remove the time component
            const startDate = new Date(itemDateRange.dateStart);
            startDate.setHours(0, 0, 0, 0);  // Set time to 00:00:00 for accurate date comparison

            // Validation checking 
            if (itemDateRange.dateStart >= yesterdayStart) {
                item.dateStart = itemDateRange.dateStart;
                item.dateEnd = itemDateRange.dateEnd;
                item.isDrag = isDrag;
                refreshData('update', item);
                //if (siteid > 0) {
                //    await updateSupportData();
                //}
            }
            else {
                toast.error(t("Pleasenote"), {
                    className: "toastError",
                });
                // Re-initialize the event scheduler after each successful action
                await initializeEventScheduler(groupid);
            }
        }
    }

    const handleMouseOver = (event) => {
        // Check if the mouse is over an event element
        if (event.target.classList.contains('smart-scheduler-event-content')) {
            const content = event.target;
            const label = content.querySelector('.support-technician-name')?.textContent || 'No label';
            const time = content.querySelector('.support-small-time')?.textContent || 'No time';
            // const desc = content.querySelector('.desc')?.textContent || 'No desc';
            const groupName = content.querySelector('.support-GroupName')?.textContent || 'No group';
            const orderId = getOrderId(label);

            const tooltip = document.createElement('div');
            tooltip.className = 'event-tooltip';
            tooltip.innerHTML = `
            Technician : ${label.replace(/\d+/g, '').trim()}<br>
            Order Id : ${orderId}<br>
            Assigned Time : ${time}<br>
          
            ${groupName}
        `; // Use <br> for line breaks

            document.body.appendChild(tooltip);

            // Position the tooltip
            const rect = content.getBoundingClientRect();
            tooltip.style.left = `${rect.left}px`;
            tooltip.style.top = `${rect.bottom + 5}px`; // Position below the event
            tooltip.classList.add('visible');
        }
    };

    const getOrderId = (name) => {
        const text = name;

        // Extract the number from the text
        const number = text.match(/\d+/); // Matches one or more digits

        // Convert to a number type if needed
        const extractedNumber = number ? parseInt(number[0], 10) : null;
        return extractedNumber;
    }

    const handleCancel = () => {
        setIsEdit(false);
        setSelectedAdmin(""); // Reset selection if needed
    };

    const handleEdit = () => {
        setIsEdit(true);
    };

    // Remove tooltips when the mouse leaves the event area
    const handleMouseOut = (event) => {
        // Remove tooltips when mouse leaves the event area
        if (event.target.classList.contains('smart-scheduler-event-content')) {
            const tooltips = document.querySelectorAll('.event-tooltip');
            tooltips.forEach(tooltip => tooltip.remove());
        }
    };

    // Function to get the current time in EST
    const getESTTime = () => {
        const estTime = new Date(
            new Date().toLocaleString('en-US', { timeZone: 'America/New_York' })
        );
        return estTime;
    };

    const handleShadeToggle = () => {
        setIsShadeActive(!isShadeActive);
    };

    const handleCurrentToggle = () => {
        setIsCurrentTimeIndicator(!isCurrentTimeIndicator);
    };

    const { confirm } = Modal;

    const handleDelete = () => {
        confirm({
            title: t("Areyousureyouwanttodeletefallbackperson"),
            okText: t("Yes"),
            okType: 'danger',
            cancelText: t("No"),
            onOk() {
                deleteFallBack()
            },
            onCancel() {
            },
        });
    }

    const deleteFallBack = async () => {
        let successMessage;
        let errorMessage;
        try {
            const response = await deletefallback(fallbackId);
            successMessage = t("Fallbackhasbeensuccessfullyremoved")
            errorMessage = t("Fallbacknotfound")
            if (response.data.success) {
                toast.success(successMessage, {
                    className: "toastSuccess",
                });
            } else {
                toast.error(errorMessage, {
                    className: "toastError",
                });
            }
            setFallbackId(0)
            setIsEdit(false)
            setSelectedAdmin("")
        }
        catch (err) {
            console.error("Error:", err);

        }
    }

    const formatTimeZone = (timeSpan) => {
        // Check if timeSpan is null or invalid
        if (!timeSpan) return ""; // Return a fallback value if timeSpan is invalid

        // Parse the timeSpan string (HH:mm:ss format)
        const [hours, minutes] = timeSpan.split(":").map(Number);

        // Check for invalid time format
        if (isNaN(hours) || isNaN(minutes)) return "Invalid Time"; // Handle invalid format

        // Determine the period (AM/PM)
        const period = hours >= 12 ? "pm" : "am";

        // Format hours for 12-hour clock
        const formattedHours = hours % 12 || 12; // Convert 0 to 12 for AM/PM
        const formattedMinutes = minutes.toString().padStart(2, "0");

        // Return formatted time
        return `${formattedHours}${formattedMinutes !== "00" ? `:${formattedMinutes}` : ""}${period}`;
    };

    // #region Random calendar colors 
    const colorAssignments = new Map(); // Map to store user-color assignments
    let colorIndex = 0;
    const getUniqueColor = (id) => {
       
        if (!colorAssignments.has(id)) {
            // Assign the next available color
            colorAssignments.set(id, colors[colorIndex]);
            colorIndex = (colorIndex + 1) % colors.length; // Cycle through colors
        }
        return colorAssignments.get(id);
    };

    // Array of random colors to apply to the technician task items
    const colors = ['#007bff', '#28a745', '#dc3545', '#ffc107', '#17a2b8', '#343a40', '#6f42c1', '#20c997', '#e83e8c'];
    // #endregion

    // Function to get the first two letters from the userName
    const getUserInitials = (userName) => {
        const nameParts = userName.split(' '); // Split the name into words
        const initials = nameParts
            .map(part => part.charAt(0).toUpperCase()) // Get first letter of each word
            .join(''); // Join them to form the initials
        return initials.slice(0, 2); // Return the first two initials (e.g., "SS" for "Saunak Saha")
    };

    const handleDragEnd = (event) => handleSchedulerUpdate(event.detail, true);
    const handleResizeEnd = (event) => handleSchedulerUpdate(event.detail);
    const handleItemUpdate = (event) => refreshData('update', event.detail.item);
    const handleItemRemove = (event) => refreshData('delete', event.detail.item);   

    return (
        <div>
            <Title title="Support Technician"></Title>
            <>
                {loading ? <Loader /> : ""}
                <Layout>
                    <div style={styles.supportcontainer} className="supportcontainer">
                        <div className="support-Cont-child" style={{ ...styles.navbar, width: isCollapsed ? '80px' : '22%', minWidth: isCollapsed ? '80px' : '22%' }}>
                            <div style={styles.header}>
                                {!isCollapsed && <h4>{t("SupportTechnicians")}</h4>}

                                <Tooltip title={isCollapsed ? t("OpenMenu") : t("Close Menu")}>
                                    <button onClick={toggleCollapse} className="support-technicians-toggleButton">
                                        {isCollapsed ? <MenuOpenIcon fontSize="small" /> : <MenuIcon fontSize="small" />}
                                    </button>
                                </Tooltip>
                            </div>

                            {!isCollapsed && (
                                <>
                                    <div
                                       // style={{ maxHeight: 'calc(100vh -50px)', height:'100%', overflowY:'scroll' }}
                                       // style={{ overflowY: 'auto', maxHeight: '600px' }}
                                    > 
                                        <TechnicianGroupList
                                            technicians={technicians}
                                            // loading={false}
                                            selectedTechnicians={selectedTechnicians}
                                            handleTechnicianSelection={handleTechnicianSelection}
                                            handleDragStart={handleDragStart}
                                            refreshOrderStatus={() => setOrderUpdated(true)}
                                        />
                                    </div>

                                    <div class="settings-container">
                                        {/* #LANGUAGE_UPDATE */}
                                        <div class="settings-title">Associated Sites</div>

                                        <div className="event-container">
                                            <div className="event-list">
                                                {supportedSites.map((admin, index) => (
                                                    <div className="event" key={index}>
                                                        <div className="event-row">
                                                            <div className="event-name">{admin.siteName}</div>
                                                            <div className="time-zone">{admin.timeZone || "Unknown Time Zone"}</div>
                                                        </div>

                                                        <div className="support-details-row">
                                                            {(admin.startTime && admin.endTime) ? (
                                                                <div className="time-slot">
                                                                    Operating hours : {formatTimeZone(admin.startTime)} - {formatTimeZone(admin.endTime)}
                                                                </div>
                                                            ) : (
                                                                <div className="time-slot">No Operating hours</div>
                                                            )}

                                                            <div className="support-user-circle-container">
                                                                {admin.users?.map((user, userIndex) => (
                                                                    <div className="support-user-circle" style={{ backgroundColor: getUniqueColor(user.userId) }}
                                                                        key={userIndex} title={user.userName}>
                                                                        {getUserInitials(user.userName)}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>


                                    </div>

                                    <div class="settings-container">
                                        {/* #LANGUAGE_UPDATE */}
                                        <div class="settings-title">Settings</div>

                                        <div className="toggle-container">
                                            <div className="toggle-label">Current Time Indicator : </div>
                                            <div
                                                className={`toggle-btn ${isCurrentTimeIndicator ? "active" : ""}`}
                                                onClick={handleCurrentToggle}
                                            >
                                                <div className="indicator"></div>
                                            </div>
                                        </div>

                                        <div className="toggle-container">
                                            <div className="toggle-label">Shade Until Current Time : </div>
                                            <div
                                                className={`toggle-btn ${isShadeActive ? "active" : ""}`}
                                                onClick={handleShadeToggle}
                                            >
                                                <div className="indicator"></div>
                                            </div>
                                        </div>

                                        {/* #LANGUAGE_UPDATE */}
                                        <div className="support-technicians-note">
                                            <span className="bold">Note :</span> Click on username to reorder the technicians.
                                        </div>

                                        <div className="support-technicians-note">
                                            <span className="bold">Note :</span> Right-click on an event to delete it.
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>

                        <div className="d-flex flex-column clock-support">
                            <div className="d-flex flex-wrap align-items-center w-100 justify-content-between gap-2 fallback-heading">
                                <div className="fallback-panel flex-1">
                                    <h3>Fallback</h3>

                                    {fallbackId == 0 || isEdit ? (
                                        <>
                                            <div className="fallback-dropdown">
                                                <select
                                                    id="userSelect"
                                                    value={selectedAdmin}
                                                    onChange={(e) => setSelectedAdmin(e.target.value)}
                                                >
                                                    <option value="">-- Select a User --</option>
                                                    {supperAdmins.map(admin => (
                                                        <option key={admin.userId} value={admin.userId}>
                                                            {`${admin.lastName} ${admin.firstName}`}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            {!isEdit ? (
                                                <button className="fallback-assign-btn text-white btn-primary" onClick={assignFallback}>
                                                    {t("Assign")}
                                                </button>
                                            ) : (
                                                <>
                                                    <button className="fallback-edit-btn text-white btn-primary" onClick={assignFallback}>
                                                        {t("Update")}
                                                    </button>
                                                    <button className="fallback-edit-btn text-white btn-primary" onClick={handleDelete} >
                                                        {t("Delete")}
                                                    </button>
                                                    <button className="fallback-edit-btn text-white btn-primary" onClick={handleCancel} >
                                                        {t("Cancel")}
                                                    </button>
                                                </>
                                            )}
                                        </>

                                    ) : (
                                        <div class="fallback-info">
                                            <span class="fallback-name">{fallbackName}</span>
                                            <button className="fallback-edit-btn text-white btn-primary" onClick={handleEdit}>
                                                {t("Edit")}
                                            </button>
                                        </div>
                                    )}
                                </div>

                                <div className="time-zone ">
                                    <div className="clock-wrap"><img src={clockIcon} alt="" /><Clock timeZoneName={timeZoneName} currentUtcOffset="-6.00" isDST="true" />
                                    </div>
                                </div>

                            </div>

                            <div style={styles.scheduler} className="scheduler-calendar"
                            >
                                <Scheduler
                                    ref={schedulerRef}
                                    currentTime={currentTime}
                                    view="week"
                                    id="scheduler"
                                    onDragEnd={handleDragEnd}
                                    onResizeEnd={handleResizeEnd}
                                    onItemUpdate={handleItemUpdate}
                                    onItemRemove={handleItemRemove}
                                    currentTimeIndicator={isCurrentTimeIndicator}
                                    shadeUntilCurrentTime={isShadeActive}
                                    //timeZone='US Eastern Standard Time'
                                    eventTemplate={eventTemplate}
                                    timelineHeaderFormatFunction={timelineHeaderFormatFunction}
                                    resources={resources}
                                    statuses={statuses.length ? statuses : [{ label: "Loading...", value: "loading" }]}
                                    // Disable all creation-related features
                                    enableEventCreation={false}
                                    disableContextMenu={true}

                                    // Prevent cell clicks from creating events
                                    onCellClick={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        return false;
                                    }}
                                    // Additional props to disable interactions
                                    disableSelection={true}
                                    disableWindowEditor={true}
                                    //onDragStart={(event) => {
                                    //event.preventDefault();
                                    //event.stopPropagation();
                                    //return false;
                                    //}}
                                    views={["week", "month", "day"]}
                                />
                            </div>
                        </div>
                    </div>
                    <Toaster />
                </Layout>
            </>
        </div>
    )
}

const styles = {
    supportcontainer: {
        display: 'flex',
        width: '100%',
        minHeight: '100vh',
        borderRadius: '10px',
        overflow: 'hidden',
        padding: '10px',

    },

    navbar: {
        transition: 'width 0.3s ease',  // Smooth transition for collapsing
        padding: '20px',  // Padding inside the navbar
        borderRight: '2px solid #ccc',  // Border separating the navbar and scheduler
        overflow: 'hidden',  // Hide overflow content when collapsed
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '10px 0 0 10px', // Rounded corners on the left side
        maxHeight: 'calc(100vh - 50px)',
        height: '100%',
        overflowY:'scroll !important',


    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',  // Align items to the start and end
        marginBottom: '15px',  // Add space below the header
    },
    scheduler: {
        width: '100%',  // Adjust width based on collapsed navbar width (80px when collapsed)

        padding: '20px',  // Add padding around the scheduler
        borderRadius: '0 10px 10px 0', // Rounded corners on the right side

    }
};

export default connect()(SupportTechnician);
