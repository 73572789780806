import React, { useState, useEffect } from "react";
import Layout from "../Layout";
import Title from "../Title";
import Loader from "../loader";
import InfiniteScroll from "react-infinite-scroll-component";
import { Menu, Drawer, Checkbox, Button, Row, Col, List ,Typography} from "antd";
import * as url from "../../api/uri_helper";
import '../../../src/parts.css';
import  NoImage from  '../../../src/assets/images/No_Image.png';
import { connect, useDispatch, useSelector } from "react-redux";
import {
  UnorderedListOutlined,
  DeploymentUnitOutlined,
 
} from "@ant-design/icons";
import { ImageOutlined } from "@mui/icons-material";
import axios from "axios";
import { SettingsPowerRounded } from "@mui/icons-material";
import { UserOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import {  fetchUserMenudata } from "../../Redux/Api/fetch.api";
import { getuserroleandmenu } from '../../Redux/Actions/UsersActions';
import NotAuthorised from "../Authentication/NotAuthorised";

const PartsDeviceMapping = () => {
  const { t } = useTranslation('partsDeviceMapping');
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("unmapped");
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [checkboxData, setCheckboxData] = useState([]);
  const [checkboxMappedData, setCheckboxMappedData] = useState([]);
  const [uncheckedDevices, setUncheckedDevices] = useState([]);
  const [partName, setPartName] = useState("");
  const [partId, setPartId] = useState();
  const UserID = localStorage.getItem("UserId");
 const [currentPage, setCurrentPage] = useState(1);
const [hasMore, setHasMore] = useState(true); 
const [mappedHasMore, setMappedHasMore] = useState(true);
const [mappedCurrentPage, setMappedCurrentPage] = useState(1);
const [enableSerach, setEnableSearch] = useState(false);
  const [userRoleData, setUserRoleData] = useState([]);
  const loggedUserId = localStorage.getItem('UserId');
  const [roleName, setRoleName] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    getUserDetails();
}, [])
  useEffect(() => {
    const params = new URLSearchParams(decodeURIComponent(window.location.search));
    const fetchedPartId = params.get("partId");
    setPartId(fetchedPartId);
    setPartName(params.get("partName"));
  }, []);
  // API call to fetch Unmapped data
  const fetchData = async (page=1) => {
   
    setLoading(true);
    try {

      const unmappedPayload = {
        PageSize: 100,
        PageIndex: page,
        Description: "",
        PartId:partId
      };
      console.log("PartId is ",partId);
      const unmappedResponse = await axios.post(url.GET_ALLMAPPINGDEVICES,unmappedPayload);
      const newData = unmappedResponse.data.data;
      if (newData.length > 0) {
        setCheckboxData((prev) => [...prev, ...newData]);
        setCurrentPage(page);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getUserDetails = () => {
    fetchUserMenudata(loggedUserId)
        .then(response => {
           
            dispatch(getuserroleandmenu(response.data.data))
            setUserRoleData(response.data.data.userRoleDetails);
            if (response.data.data.userRoleDetails[0].roleName)
                setRoleName(response.data.data.userRoleDetails[0].roleName);
        })
        .catch((err) => {
            console.log("Err: ", err);
        });
}

  
 //fetch Mapped Data 
  const fetchMappedData = async (page = 1) => {
    try {

      setLoading(true);
      const mappedPayload = {
        PageSize: 100,
        PageIndex: page,
        PartId: partId, 
      };
      const mappedResponse = await axios.post(
        url.GET_ALLPARTSDEVICES,
        mappedPayload
      );
      const  newMappedData= mappedResponse.data.data;
      if (newMappedData.length > 0) {
        setCheckboxMappedData((prev) => [...prev, ...newMappedData]);
        setMappedCurrentPage(page);
      } else {
        setMappedHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
 
  //Update the Parts from  Mapped Devices to unmapped Devices                                                      
 const updatePartsDevices=async ()=> {

  setLoading(true);
  const payload=
  {
   PartId:partId,
   UncheckedDeviceTypeIds:uncheckedDevices
  }
   try{

    const response = await axios.put(url.UPDATE_PARTS_DEVICES,payload);
    if(response.data){
      setCheckboxMappedData((prevData) =>
        prevData.filter(
          (item) => !uncheckedDevices.includes(item.deviceTypeId)
        )
      );
     
      setUncheckedDevices([]);
      // fetchMappedData();
    } 
   }
   catch(error){
   console.error("Error in Updating Data" ,error);
   }
   finally {
    setLoading(false);
  }
 }
 

  useEffect(() => {
    if(partId){
      fetchData();
    }
    
  }, [partId]);


  // const [trigger, setTrigger] = useState(false)
  // useEffect(() => {
  //   fetchData();
  //   fetchMappedData
  // }, [trigger]);

  const handleMappedDevices = async (event) => {
    setLoading(true);
    const currentDate = new Date().toISOString();
    const payload = {
        PartId: partId,
        DeviceTypeIds: checkedDevices,
        UserId: UserID,
        MappedDate: currentDate,
        IsActive: true,
        IsDeleted: false,
    };

    try {
        // Send the request to the backend
        const response = await axios.post(url.MapDevices, payload);
        if (response.data) {
            // Filter out checked devices from checkboxData
            setCheckboxData(prevData => 
                prevData.filter(item => !checkedDevices.includes(item.deviceTypeID))
            );
            setCheckedDevices([]); // Reset checked devices
            // setTrigger(prev => !prev); // Optionally trigger re-fetching data
          
        }
    } catch (error) {
        console.error("Error submitting data:", error);
    } finally {
        setLoading(false);
    }
};
  

const toggleDrawer = () => {
  if (!drawerVisible) {
    fetchMappedData(); 
  } else {
    setCheckboxMappedData([]); 
  }
  setDrawerVisible(!drawerVisible);
};

  const handleSubmit = () => {
   
    if (activeTab === "unmapped") {
      handleMappedDevices();
    } else if (activeTab === "mapped") {
      updatePartsDevices();
    } else {
      console.error("Invalid tab selected");
    }
  };
  


  const [checkedDevices, setCheckedDevices] = useState([]); // To store selected devices
  const handleCheckboxChange = (e, deviceId) => {
    
    console.log(
      "Checkbox changed, Device ID: ",
      deviceId,
      " Checked: ",
      e.target.checked
    );
    setCheckedDevices((prevCheckedDevices) => {
      if (e.target.checked) {
        // Add deviceId to the array if checked
        return [...prevCheckedDevices, deviceId];
      } else {
        // Remove deviceId from the array if unchecked
        return prevCheckedDevices.filter((id) => id !== deviceId);
      }
    });
  };

  //for mapped data updation 
  const handleUncheckboxChange=(e,deviceId)=> {
  
    const isUnchecked = e.target.checked;
    console.log(
      "Checkbox changed, Device ID: ",
      deviceId,
      " UnChecked: ",
    !isUnchecked
    );
    setUncheckedDevices((prevUncheckedDevices)=>{
      if(!isUnchecked){
        return [...prevUncheckedDevices,deviceId];
      }
      else{
        return prevUncheckedDevices.filter((id)=>id!== deviceId);
      }
    });
  }
  console.log("unchecked devices",uncheckedDevices);
  console.log("checkedDevices", checkedDevices);

  return (
    <div>
   <Title title="Parts Mapping" />
   {roleName == null ? <Loader /> : roleName && roleName != "SuperAdmin" && roleName && roleName != "Developer"? <NotAuthorised></NotAuthorised> :

    <Layout>
      
      {loading && <Loader />}
      <section className="mapping_section ">
        <div className="row">
        {/* Left Sidebar */}
        <div className="sidebar col-lg-2 col-sm-12">
          <Menu
          className="sidebar_menu"
            mode="vertical"
            selectedKeys={[activeTab]}
            
            onClick={(e) => {
              setActiveTab(e.key);

              if (e.key === "mapped") {
                setCheckboxMappedData([]);
                  setMappedCurrentPage(1);
                  setMappedHasMore(true);
                  fetchMappedData();
              }
              else{
                setCheckboxMappedData([]);
                setCheckboxData([]);
                setCurrentPage(1);
                setHasMore(true);
                fetchData();
              }
            }}
            items={[
              {
                key: "unmapped",
                icon: (
                  <UnorderedListOutlined
                    style={{
                      color: activeTab === "unmapped" ? "#c2977e" : "inherit",
                    }}
                  />
                ),
                label: t("UnmappedDevices"),
                style: {
                  color: activeTab === "unmapped" ? "#c2977e" : "inherit",
                },
              },
              {
                key: "mapped",
                icon: (
                  <DeploymentUnitOutlined
                    onClick={fetchMappedData}
                    style={{
                      color: activeTab === "mapped" ? "#c2977e" : "inherit",
                    }}
                  />
                ),
                label: t("MappedDevices"),
                style: {
                  color: activeTab === "mapped" ? "#c2977e" : "inherit",
                },
              },
            ]}
          />
        </div>

        {/* Right Content Area */}
        <div className="col-lg-10 col-sm-12">
        <div className="context_area_heading" >
          <div
            className="header-section"
          >
            <div>
              <h4>
                {activeTab === "unmapped"
                  ? t("UnmappedDevices")
                  : activeTab === "mapped"
                  ? t("MappedDevices")
                  : "Wiring"}
              </h4>
            </div>
            <div>
              <h4>{t("Part")}: {partName}</h4>
            </div>
            {activeTab === "unmapped" && (
              <Button
                className="btn btn-primary d-flex align-items-center btn-new"
                onClick={toggleDrawer}
              >
                {t("MappedDevices")}
              </Button>
            )}
          </div>

          {/* Body Section */}
          <div className="relative-box">
            <div className="bordered-title">
              <h4 className="title-text">{t("DevicesList")}</h4>
            </div>
            <div className="assign-shape-box" id="assignshapebox">
             
                {/* //<Row gutter={[16, 16]}> */}
                {activeTab === "unmapped" && (
                  <InfiniteScroll
                    dataLength={checkboxData.length}
                    next={() => fetchData(currentPage + 1)} 
                    hasMore={hasMore} 
                    // loader={<p>Loading...</p>} 
                    scrollableTarget="assignshapebox"
                    // endMessage={
                    //   <p style={{ textAlign: "center" }}>
                    //     <b>No more devices to load</b>
                    //   </p>
                    // }
                  >
                    <Row gutter={[16, 16]}>
                      {checkboxData.map((item, index) => (
                        <Col xs={24} sm={12} md={8} lg={6} key={index}>
                          <Checkbox
                            onChange={(e) =>
                              handleCheckboxChange(e, item.deviceTypeID)
                            }
                            checked={checkedDevices.includes(
                              item.deviceTypeID
                            )}
                          >
                            <img
                              className="parts_img"
                              src={item.iconSource || NoImage}
                              alt="Device"
                            />
                            {item.description}
                          </Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </InfiniteScroll>
                )}

                  {activeTab === "mapped" ? (
                    checkboxMappedData.length > 0 ? (
                      <InfiniteScroll
                      dataLength={checkboxMappedData.length} 
                      next={() => fetchMappedData(mappedCurrentPage + 1)} 
                      hasMore={mappedHasMore} 
                      // loader={<p>Loading...</p>} 
                      scrollableTarget="assignshapebox"
                      
                    >
                      <Row gutter={[16, 16]}>
                      {checkboxMappedData.map((item, index) => (
                        <Col xs={24} sm={12} md={8} lg={6} key={index}>
                          <Checkbox
                            onChange={(e) =>
                              handleUncheckboxChange(e, item.deviceTypeId)
                            }
                            checked={
                              !uncheckedDevices.includes(item.deviceTypeId)
                            }
                          >
                            <img
                              src={item.iconSource || NoImage}
                              className="parts_img"
                              alt="Part"
                            />
                            {item.description}
                          </Checkbox>
                        </Col>
                      ))}
                   
                    </Row>
                    </InfiniteScroll>
                    ) : (
                      "No data found"
                    )
                  ) : null}
         
            </div>
          </div>

          {/* Action Buttons */}
          <div
          className="action_buttons"
          >
            <Button
            className="btn btn-primary d-flex align-items-center btn-new mr-2"
              type="btn btn-primary d-flex align-items-center btn-new mr-2" 
              onClick={handleSubmit}
            >
              {t("Submit")}
            </Button >
            {/* <Button className="btn btn-bordered ml-2" >  {t("Cancel")}</Button> */}
          </div>
        </div>
        </div>
        </div>
      </section>

      {/* Drawer Component */}
      {drawerVisible && (
  <Drawer
  title={
    <div className="drawer-title-container">
        <div className="drawer-user-icon">
            <UserOutlined />
        </div>
        <Typography.Text className="drawer-title-text">
            {`Part Name : ${partName}`}
        </Typography.Text>
    </div>
}
    placement="right"
    onClose={toggleDrawer}
    visible={drawerVisible}
    width={350}
    closable={false}
    className="parts-drawer"
    onOpenChange={fetchMappedData}
  >
    {checkboxMappedData && checkboxMappedData.length > 0 ? (
      
       <div >
          <InfiniteScroll
                      dataLength={checkboxMappedData.length} 
                      next={() => fetchMappedData(mappedCurrentPage + 1)} 
                      hasMore={mappedHasMore} 
                      // loader={<p>Loading...</p>} 
                      scrollableTarget="assignshapebox"
                      
                    >
      <List
        dataSource={checkboxMappedData}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
             avatar={ item.iconSource ? ( <img src={item.iconSource} alt="Device" className="parts_img"  /> ) 
             : ( <ImageOutlined style={{ fontSize: '40px', color: '#ccc' }} /> ) }
              title={item.description}
              description={`ID: ${item.deviceTypeId}`}
            />
          </List.Item>
        )}
      />
</InfiniteScroll>
      </div>
    ) : (
      <div className="default_error_text">
        No Device Found
      </div>
    )}
  </Drawer>
)}

    </Layout>
}
    </div>
  );
};

export default PartsDeviceMapping;
