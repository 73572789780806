import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import '../../../src/custom.css'
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import Arrow_leftbrown from "../../assets/images/Arrow_leftbrown.svg";
import { Input, Form, FormFeedback } from "reactstrap"
import { Formik, Field, useFormik } from 'formik';
import * as Yup from 'yup';
import * as url from "../../api/uri_helper";
import axios from "axios";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PhoneInput from 'react-phone-input-2'
import "react-phone-input-2/lib/bootstrap.css";
import { fetchAllRoles } from "../../Redux/Api/fetch.api";
import { getallroles } from '../../Redux/Actions/RolesActions';
import { fetchAllCompaniesdata, fetchUserById, fetchTimeZones, fetchSitesByCompanyId, fetchProjectDetails} from "../../Redux/Api/fetch.api";
import { getallcompanies } from '../../Redux/Actions/CompanyActions';
import { getsitesbycompanyid } from '../../Redux/Actions/SiteActions';
import { saveuserdetails, updateuserdata } from '../../Redux/Actions/UsersActions';
import Loader from "../loader";
import Layout from '../Layout';
import { fetchUserMenudata } from "../../Redux/Api/fetch.api";
import { getuserroleandmenu } from '../../Redux/Actions/UsersActions';
import { getallTimeZones } from '../../Redux/Actions/AccessibilityActions';
import { Checkbox, Select } from "antd";
import NotAuthorised from "../Authentication/NotAuthorised";
import { Tooltip, Switch } from 'antd';
import { useTranslation } from "react-i18next";

import Title from "../Title";

const Option = Select.Option;

const AddUser = () => {
    const { t } = useTranslation('UserAdd');
    const [name, setName] = useState("");
    const [comRoleName, setComRoleName] = useState(null);
    const [comId, setComId] = useState("");
    const [lname, setLname] = useState("");
    const [ifUpdate, setifUpdate] = useState(false)
    const [email, setEmail] = useState("");
    const [roleId, setRoleId] = useState();
    const [siteId, setSiteId] = useState();
    const [userId, setUserId] = useState();
    const [projects, setProjects] = useState([]);
    const [password, setPassword] = useState("");
    const [editpassword, setEditPassword] = useState();
    const [phnumber, setPhnumber] = useState("");
    const [passwordType, setPasswordType] = useState("password");
    const [selectProjects, setSelectProjects] = useState([]);
    const [roleName, setRoleName] = useState("");
    const [passwordTypeCon, setPasswordTypeCon] = useState("password");
    const [companyId, setCompanyId] = useState();
    const [phoneError, setPhoneError] = useState(false);
    const [selectedCountryCode, setSlectedCountryCode] = useState()
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch(); 
    const [allRoles, setAllRoles] = useState([]);
    const [allCompaniesData, setAllCompaniesData] = useState([]);
    const [allSiteData, setAllSiteData] = useState([]);
    const [allAppData, setAllAppData] = useState([]);
    const [currentId, setCurrentId] = useState(null);
    const [checked, setChecked] = useState(true);
    const [isActive, setisActive] = useState(true)
    const [disable, setDisable] = useState(false);
    const [rolesError, setRolesError] = useState(false);
    const [siteError, setsiteError] = useState(false);   
    const [companyError, setCompanyError] = useState(false);
    const [isTextBoxVisible, setIsTextBoxVisible] = useState(false);
    const [userRoleData, setUserRoleData] = useState([]);
    const [userCompanyData, setUserCompanyData] = useState([]);
    let history = useHistory();
    const [projectError, setProjectError] = useState(false);
    const loggedInUserId = localStorage.getItem('UserId') 
    const allLoginTypes = useSelector((state) => state.allLogindata.login);
    const [allowSupportCall, setAllowSupportCall] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const comingid = allLoginTypes.userId;
    const [timeZones, setTimeZones] = useState([]);
    const [timeZoneId, setTimeZoneId] = useState(0);
    const [isDST, setIsDST] = useState(false)
   
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    const handleChange = () => {
        setisActive(!isActive)
    }
    let pageButton = t("Update")

    if (!currentId) {
        pageButton = t("Save")
    }

    useEffect(() => {
        getUserDetails()
        getRolesDetails()
        getTimeZones();
      
    }, [])

    useEffect(() => {  
        
        setLoading(true)
        let urlData = location.pathname.split('/');
        setCurrentId(urlData[3])       
        if (currentId) {  
            setIsEdit(true);
            setLoading(true)
            fetchUserById(urlData[3]).then(
                response => {                   
                    setName(response.data.data.firstName);
                    setLname(response.data.data.lastName);
                    setEmail(response.data.data.emailId)
                    setPhnumber(response.data.data.phoneNumber)
                    setUserId(response.data.data.userId)                   
                    setRoleId(response.data.data.roles[0].roleId)
                    setisActive(response.data.data.isActive)
                    setCompanyId(response.data.data.companyId)    
                    setAllowSupportCall(response.data.data.isSupportCall)
                    setTimeZoneId(response.data.data.timeZoneId)
                    setIsDST(response.data.data.isDST)
                   // { response.data.data.apps ? setSelectProjects(getProject(response.data.data.apps)) : "" }
                    setLoading(false)
                    setifUpdate(true);
                })     
            setTimeout(() => {
                setLoading(false)
            }, 2000)
        }
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }, [currentId])
  
    useEffect(() => {
        getallcompaniesdata();
    }, [])
   
    const getUserDetails = () => {        
        fetchUserMenudata(loggedInUserId)
            .then(response => {                
                dispatch(getuserroleandmenu(response.data))
                setUserRoleData(response.data.data.userRoleDetails)
                setComRoleName(response.data.data.userRoleDetails[0].roleName)
                setUserCompanyData(response.data.data.userCompanyDetails)
                if (response.data.data.userRoleDetails[0].roleName == "Admin")
                setCompanyId(response.data.data.userCompanyDetails[0].companyId)                
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }

    const getTimeZones = () => {

        fetchTimeZones()
            .then(response => {

                dispatch(getallTimeZones(response.data))
                setTimeZones(response.data.data)
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }

    const handleToggle = () => {
        setAllowSupportCall(!allowSupportCall);
    };

    const togglePasswordCon = () => {
        if (passwordTypeCon === "password") {
            setPasswordTypeCon("text")
            return;
        }
        setPasswordTypeCon("password")
    }
    

    const getallcompaniesdata = () => {
        setLoading(true)
        fetchAllCompaniesdata()                   
            .then(response => {
                dispatch(getallcompanies(response.data))
                setAllCompaniesData(response.data.data)               
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
        setLoading(false)
    }

    const getRolesDetails = () => {           
        fetchAllRoles()
            .then(response => {              
                dispatch(getallroles(response.data))
                setAllRoles(response.data.data)
            })
            .catch((err) => {
                console.log("Err: ", err);
            })
    }
    const handleChangeIsDST = () => {
        setIsDST(!isDST)
    }

    const addUserData = async (e) => {            
        setDisable(false)
        setLoading(true)
        const formData = new FormData();
        formData.append("FirstName", name);
        formData.append("LastName", lname);
        formData.append("EmailId", email.toLowerCase());
        formData.append("PasswordHash", password);
        formData.append("PhoneNumber", phnumber);
        formData.append("RoleId", roleId);
        formData.append("LoggedInId", loggedInUserId);
        formData.append("CompanyId", companyId);
        formData.append("IsActive", isActive);
        formData.append("IsSupportCall", allowSupportCall);    
        formData.append("TimeZoneId", timeZoneId > 0 && timeZoneId != null ? timeZoneId : 0);
        if (timeZoneId > 0) {
            formData.append("IsDST", isDST !== null ? isDST : false);
        } else {
            formData.append("IsDST", false);
        } 
        const response_ = await axios({
            method: 'post',
            url: url.POST_ADDUSER,
            data: formData,
        }).then(response => { 
            
            if (response.data.success == false) {
                toast.error(t("EmailIdAlreadyExists"), {
                    className: "toastSuccess",
                });
            }
            else {
                dispatch(saveuserdetails(response.data))
                toast.success(t("UserCreatedsuccessfully"), {
                    className: "toastSuccess",
                });
                if (comRoleName == "SuperAdmin") {
                    history.push('/UserCompany/Add');
                }
                else {
                    history.push('/UserSite/Add');
                }
                
            }
            setLoading(false)
        })
            .catch((err) => {
                debugger;
                toast.error(err.response.data.message, {
                    className: "toastSuccess",
                },);
                setDisable(false);
            });
        setLoading(false)

    }

    const handleLinkClick = () => {       
        setIsTextBoxVisible(!isTextBoxVisible);
        setDisable(!disable)
    };

    const UpdateUserInformation = async (e) => {   
        debugger;
        setLoading(true)       
        const formData = new FormData();  
        formData.append("UserId", userId)
        formData.append("FirstName", name);
        formData.append("LastName", lname);
        formData.append("PasswordHash", editpassword ? editpassword : null);
        formData.append("EmailId", email.toLowerCase());       
        formData.append("PhoneNumber", phnumber);
        formData.append("RoleId", roleId);
        formData.append("LoggedInId", loggedInUserId);
        formData.append("CompanyId", companyId);
        formData.append("IsActive", isActive);
        formData.append("IsSupportCall", allowSupportCall);
        formData.append("TimeZoneId", timeZoneId > 0 && timeZoneId != null ? timeZoneId : 0);
        if (timeZoneId > 0) {
            formData.append("IsDST", isDST !== null ? isDST : false);
        } else {
            formData.append("IsDST", false);
        }
            const response_ = await axios({
                method: 'put',
                url: url.UPDATE_USER_DATA,
                data: formData,
            }).then(response => {                
                if (response.data.success == false) {
                    toast.error(t("UserAlreadyCreated"), {
                        className: "toastSuccess",
                    });                    
                }
                else {
                    dispatch(updateuserdata(response.data))
                    toast.success(t("Userupdatedsuccessfully"), {
                        className: "toastSuccess",
                    });
                    history.push('/User')                    
                }
            })
                .catch((err) => {
                    console.log("Err: ", err);
                });
            setLoading(false)

    }

    const handleTimeZoneDropDownChange = (e) => {
        setTimeZoneId(e.target.value)
        if (timeZoneId == 0) {
            setIsDST(false);
        }
    }

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: name ? name : '',
            lname: lname ? lname : '',           
            email: email ? email : '',
            password: password ? password : '',
            editpassword: editpassword ? editpassword : '',
        },

        validationSchema: Yup.object({
            name: Yup.string().required(t('PleaseEnterName')).matches(
                "^[A-Za-z][A-Za-z]*$",
                t("PleaseentervalidName")),

            lname: Yup.string().required(t('PleaseEnterLastName')).matches(
                "^[A-Za-z][A-Za-z]*$",
                t("PleaseentervalidLastName")),
           
            email: Yup.string().required(t('PleaseEnterEmail')).matches(
                /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                t("Pleaseentervalidemail")),

            password: Yup.string().when('currentId', {
                is: (currentId) => {
                    // return bool result and if its true then next THEN will be executed
                    return currentId === undefined && !ifUpdate
                },
                then: Yup.string().required(t("PleaseEnterPassword")).matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                    t("ValidationForPassword")), otherwise:
                    null,
            }),

            editpassword: Yup.string().nullable().matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                t("ValidationForConfirmPassword")
            ),

        }),

        onSubmit: (values) => {
            if (!phoneError && !rolesError && !companyError) {
                setDisable(true)
                 { currentId == undefined || currentId == null || currentId == isNaN ? addUserData(values) : UpdateUserInformation(values) };
             }
        }
    })

    const Update = () => {
        if (!ifUpdate) {
            return (              
                <div className="col-md-4">                  
                    <div className="form-group">
                        <label>Password
                            <span className="required-fields"> * </span>
                        </label>
                        <div className="input-group">
                            <Input name="password" className="form-control" placeholder={t("EnterPassword")}
                                type={passwordType} maxLength={50} onChange={validation.handleChange}
                                onBlur={validation.handleBlur} value={validation.values.password}
                                invalid={
                                    validation.touched.password && validation.errors.password ? true : false}
                            />
                            <div class="input-group-append">
                                <span class="input-group-text user_icon" id="basic-addon2">
                                    <div onClick={togglePassword}>
                                        {passwordType === "password" ? <VisibilityOffIcon style={{ color: 'gray' }} /> : <VisibilityIcon style={{ color: 'gray' }} />}
                                    </div>
                                </span>
                            </div>
                            {validation.touched.password && validation.errors.password ? (
                                <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}
                        </div>
                    </div>
                </div>
            )
        }

        else {

            <div className="col-md-4">
                <div className="form-group">
                    <label>{t("Password")}

                    </label>
                    <div className="input-group">
                        <Input name="password" className="form-control" placeholder={t("EnterPassword")}
                            type="password" maxLength={50} onChange={validation.handleChange}
                            value={validation.values.password}
                            disabled={!ifUpdate ? '' : 'disabled'}

                        />


                    </div>
                </div>
            </div>

        }
    }


    return (
        <div>
            <Title title={`${isEdit ? "Edit" : "Add"} User`} />
            {comRoleName == null ? <Loader /> : comRoleName && comRoleName != "SuperAdmin" && comRoleName && comRoleName != "Developer"
                && comRoleName && comRoleName != "Admin" ? <NotAuthorised></NotAuthorised> :
                <Layout>
                    <section className="User_section common ">
                        <Toaster />
                        {loading ? <Loader /> : ''}
                        <div className="container">
                            <div className="mt-4 w-100 position-relative wk-select">
                                <h4 className="m-0">
                                    <Link className="backBTN" to="/User">
                                        <img src={Arrow_leftbrown}></img>
                                       {t("Back")}
                                    </Link>
                                </h4>

                            </div>
                            <div className="content-area">
                                <Form className="form-horizontal"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        if (!phnumber || !(phnumber && phnumber.length > 10)) {
                                            setPhoneError(true);
                                        }
                                        if (!roleId) {
                                            setRolesError(true);
                                        }


                                        if (!companyId) {
                                            setCompanyError(true);
                                        }

                                        return false;
                                    }}>
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t("FirstName")} <span className="required-fields"> * </span></label>
                                                    <div className="input-group">
                                                        <Input name="name" className="form-control" placeholder={t("EnterName")}
                                                            type="text" maxLength={50} onChange={(e) => { validation.handleChange(e); setName(e.target.value.replace(/\s{2}/g, '')) }}
                                                            onBlur={validation.handleBlur} value={validation.values.name}
                                                            invalid={validation.touched.name && validation.errors.name ? true : false}
                                                        />
                                                        {validation.touched.name && validation.errors.name ? (
                                                            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t("LastName")} <span className="required-fields"> * </span></label>
                                                    <div className="input-group">
                                                        <Input name="lname" className="form-control" placeholder={t("EnterLastName")}
                                                            type="text" maxLength={50} onChange={(e) => { validation.handleChange(e); setLname(e.target.value.replace(/\s{2}/g, '')) }}
                                                            onBlur={validation.handleBlur} value={validation.values.lname}
                                                            invalid={validation.touched.lname && validation.errors.lname ? true : false}
                                                        />
                                                        {validation.touched.lname && validation.errors.lname ? (
                                                            <FormFeedback type="invalid">{validation.errors.lname}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            {!ifUpdate ?
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>{t("Email")}<span className="required-fields"> * </span></label>
                                                        <div className="input-group">
                                                            <Input name="email" className="form-control" placeholder="Enter Email"
                                                                type="text" maxLength={50} onChange={(e) => { validation.handleChange(e); setEmail(e.target.value) }}
                                                                onBlur={validation.handleBlur} value={validation.values.email}
                                                                invalid={
                                                                    validation.touched.email && validation.errors.email ? true : false}
                                                            />
                                                            {validation.touched.email && validation.errors.email ? (
                                                                <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div> :

                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>{t("Email")} <span className="required-fields"> * </span></label>
                                                        <div className="input-group">
                                                            <Input name="email" className="form-control" placeholder={t("EnterEmail")}
                                                                type="text" maxLength={50} disabled onChange={(e) => { validation.handleChange(e); setEmail(e.target.value) }}

                                                                onBlur={validation.handleBlur} value={validation.values.email}
                                                                invalid={
                                                                    validation.touched.email && validation.errors.email ? true : false}
                                                            />
                                                            {validation.touched.email && validation.errors.email ? (
                                                                <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>

                                            }
                                          
                                            {!ifUpdate ?
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>{t("Password")}
                                                            <span className="required-fields"> * </span>
                                                        </label>
                                                        <div className="input-group">
                                                            <Input name="password" className="form-control" placeholder={t("EnterPassword")}
                                                                type={passwordType} maxLength={50} onChange={(e) => { validation.handleChange(e); setPassword(e.target.value) }}
                                                                onBlur={validation.handleBlur} value={validation.values.password}
                                                                invalid={
                                                                    validation.touched.password && validation.errors.password ? true : false}
                                                            />
                                                            <div class="input-group-append">
                                                                <span class="input-group-text user_icon" id="basic-addon2">
                                                                    <div onClick={togglePassword}>
                                                                        {passwordType === "password" ? <VisibilityOffIcon style={{ color: 'gray' }} /> : <VisibilityIcon style={{ color: 'gray' }} />}
                                                                    </div>
                                                                </span>
                                                            </div>
                                                            {validation.touched.password && validation.errors.password ? (
                                                                <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div> : ""
                                            }
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{t("PhoneNumber")} <span className="required-fields"> * </span></label>
                                                    <div className="input-group phnumber">
                                                        <PhoneInput
                                                            className="w-5"
                                                            country={"us"}
                                                            enableSearch={true}
                                                            value={phnumber}
                                                            onChange={(phone, country) => {
                                                                setSlectedCountryCode((prevState) => {
                                                                    if (prevState !== country.countryCode) {
                                                                        setPhnumber(country.dialCode);
                                                                    }
                                                                    else {
                                                                        setPhnumber(phone);
                                                                    }
                                                                    return country.countryCode;
                                                                })
                                                                if (phone && phone.length > 10 && !phone.replace(country.dialCode, '').startsWith('0')) {
                                                                    setPhoneError(false);
                                                                }
                                                                else {
                                                                    setPhoneError(true);
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    {< small className="text-danger d-block mt-2">{!phoneError ? ' ' : t('PleaseEnterValidPhoneNumber')}</small>}
                                                </div>
                                            </div>

                                            {comRoleName == "SuperAdmin" ?
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>{t("MasterCompany")} <span className="required-fields"> * </span></label>
                                                        <select className="form-select mb-0 flex-grow-1 filter-select wk-select" name="roles" aria-label=".form-select-lg example"
                                                            value={companyId}
                                                            key={companyId}

                                                            onChange={(e) => {
                                                                setCompanyId(e.target.value)
                                                                if (e.target.value == "Select Company") {
                                                                    setCompanyError(true)
                                                                }
                                                                else {
                                                                    setCompanyError(false)
                                                                }


                                                            }}
                                                        >
                                                            <option selected="">{t("SelectCompany")}</option>
                                                            {
                                                                allCompaniesData && allCompaniesData.map(company =>
                                                                    <option key={company.companyId} value={company.companyId}>
                                                                        {company.companyName} </option>
                                                                )
                                                            }
                                                        </select>
                                                        {
                                                            < small className="text-danger d-block mt-2">{!companyError ? ' ' : t('PleaseSelectCompany')}</small>
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>{t("MasterCompany")} <span className="required-fields"> * </span></label>
                                                        <select className="form-select mb-0 flex-grow-1 filter-select wk-select" name="roles" aria-label=".form-select-lg example"
                                                            value={companyId}
                                                            key={companyId}
                                                            disabled
                                                            onChange={(e) => {
                                                                setCompanyId(e.target.value)

                                                            }}
                                                        >
                                                            <option selected="">{t("SelectCompany")}</option>
                                                            {
                                                                allCompaniesData && allCompaniesData.map(company =>
                                                                    <option key={company.companyId} value={company.companyId}>
                                                                        {company.companyName} </option>
                                                                )
                                                            }

                                                        </select>

                                                    </div>
                                                </div>
                                            }
                                            {comRoleName == "SuperAdmin" ?
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>{t("Roles")} <span className="required-fields"> * </span></label>
                                                        <select className="form-select mb-0 flex-grow-1 filter-select wk-select" name="roles" aria-label=".form-select-lg example"
                                                            value={roleId}
                                                            // placeholder="Select role"
                                                            key={roleId}
                                                            onChange={(e) => {
                                                                setRoleId(e.target.value)
                                                                setRolesError(false)

                                                            }}
                                                        >
                                                            <option selected="">{t("SelectRoles")}</option>
                                                            {
                                                                allRoles && allRoles.map(roles =>
                                                                    <option key={roles.roleId} value={roles.roleId}>
                                                                        {roles.roleName} </option>
                                                                )
                                                            }
                                                        </select>
                                                        {
                                                            < small className="text-danger d-block mt-2">{!rolesError ? ' ' : t('PleaseSelectRoles')}</small>
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>{t("Roles")} <span className="required-fields"> * </span></label>
                                                        <select className="form-select mb-0 flex-grow-1 filter-select wk-select" name="roles" aria-label=".form-select-lg example"
                                                            value={roleId}
                                                            // placeholder="Select role"
                                                            key={roleId}

                                                            onChange={(e) => {
                                                                setRoleId(e.target.value)
                                                                setRolesError(false)

                                                            }}
                                                        >
                                                            <option selected="">{t("SelectRoles")}</option>
                                                            {
                                                                allRoles && allRoles.map(roles =>
                                                                    roles.roleId != 7 ?
                                                                        <option key={roles.roleId} value={roles.roleId}>
                                                                            {roles.roleName} </option>
                                                                        : ""


                                                                )
                                                            }
                                                        </select>
                                                        {
                                                            < small className="text-danger d-block mt-2">{!rolesError ? ' ' : t('PleaseSelectRoles')}</small>
                                                        }
                                                    </div>
                                                </div>
                                            }

                                            <div className="col-md-3 select-Role comdropdown mb-3">
                                                <label>{t("SelectTimeZone")} </label>
                                                <select className="form-select mb-0 flex-grow-1 filter-select" name="timeZones" aria-label=".form-select-lg example"
                                                    value={timeZoneId}
                                                    // placeholder="Select role"
                                                    key={timeZoneId}
                                                    onChange={(e) => {
                                                        handleTimeZoneDropDownChange(e);
                                                        // setTimeZoneId(e.target.value);
                                                    }}
                                                >
                                                    <option value="0" selected="0">{t("SelectTimeZone")}</option>
                                                    {
                                                        timeZones && timeZones.map(timeZone =>
                                                            <option key={timeZone.timeZoneId} value={timeZone.timeZoneId}>
                                                                {timeZone.name} </option>
                                                        )
                                                    }
                                                </select>

                                            </div>

                                            {timeZoneId && timeZoneId > 0 ?
                                                (<div className="col-md-3 select-Role comdropdown mt-5">
                                                    <label>  <input type="checkbox" className="check mr-1" name="isDST" checked={isDST} onChange={() => handleChangeIsDST()} ></input>
                                                        {t("ObservesDaylightSavingsTime")}</label>
                                                </div>
                                                ) : ""}
                                            <div className="col-md-12">
                                                <div className="alarm-toggle--wrap d-inline-block">

                                                    <Switch
                                                        id="allowNotifications"
                                                        className="ms-2"
                                                        checked={allowSupportCall}
                                                        onChange={handleToggle}
                                                    />

                                                    <label className="alarm-toggle-label ml-2">{t("AllowSupportCall")}</label>
                                                </div>
                                            </div>
                                           
                                            <div className="col-md-12 mb-3">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        {ifUpdate ?

                                                            <div className="form-checkbox mt-3 mb-2">
                                                                <input type="checkbox" className="check mr-1" name="ispassword"
                                                                 
                                                                    onChange={() => handleLinkClick()}
                                                                />
                                                            <label>
                                                                 {t("CreateaNewPassword")}
                                                                </label>
                                                               {/* <Link className="password-btn" onClick={handleLinkClick}>Create a New Password</Link>*/}
                                                            </div> : ""
                                                        }
                                                        {isTextBoxVisible && (

                                                            <div className="form-group">
                                                                <label>{t("EnterPassword")}
                                                                    <span className="required-fields"> * </span>
                                                                </label>
                                                                <div className="input-group">
                                                                    <Input name="editpassword" className="form-control" placeholder={t("EnterPassword")}
                                                                        type={passwordTypeCon} maxLength={50} onChange={(e) => {
                                                                            validation.handleChange(e);
                                                                            setEditPassword(e.target.value)
                                                                           setDisable(false)
                                                                        }}
                                                                        onBlur={validation.handleBlur} value={validation.values.editpassword}
                                                                        invalid={
                                                                            validation.touched.editpassword && validation.errors.editpassword ? true : false}
                                                                    />

                                                                    <div className="input-group-append">
                                                                        <span className="input-group-text user_icon" id="basic-addon2">
                                                                            <div onClick={togglePasswordCon}>
                                                                                {passwordTypeCon === "password" ? <VisibilityOffIcon style={{ color: 'gray' }} /> : <VisibilityIcon style={{ color: 'gray' }} />}
                                                                            </div>
                                                                        </span>
                                                                    </div>
                                                                    {validation.touched.editpassword && validation.errors.editpassword ? (
                                                                        <FormFeedback type="invalid">{validation.errors.editpassword}</FormFeedback>
                                                                    ) : null}
                                                                </div>
                                                            </div>

                                                        )}
                                                    </div>

                                                   
                                                </div>      
                                            </div>

                                            <div className="col-md-12 mb-4">
                                                <div className="form-checkbox">
                                                    {loading && currentId === undefined || currentId === null || currentId === isNaN ?
                                                        <label>
                                                            <input type="checkbox" className="check mr-1" defaultChecked={checked} name="isActive" checked={isActive} onChange={() => handleChange()}></input> {t("Active")}</label> :

                                                        <label>  <input type="checkbox" className="check mr-1" name="isActive" checked={isActive} onChange={() => handleChange()} ></input>
                                                            {t("Active")}</label>}
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                {
                                                    <button type="submit" className="btn btn-primary" disabled={disable || (validation.values.editpassword && validation.values.editpassword.length < 8)}>{pageButton}</button>
                                                }
                                                <Link className="btn btn-bordered ml-2" to="/User">{t("Cancel")}</Link>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </section>
                </Layout>
            }
        </div>
    )
}

export default connect()(AddUser);
