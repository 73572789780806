import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import Layout from "../Layout";
import Table from "antd/lib/table";
import { Tooltip } from 'antd';
import Loader from "../loader";
import { fetchAllSupportLogs, fetchCompanySupportLogs } from "../../Redux/Api/fetch.api";
import { getsupportlogsdata, getcompanysupportdata } from '../../Redux/Actions/SupportActions';
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import { useTranslation } from "react-i18next";
import Title from "../Title";

const SupportLogs = () => {
    const { t } = useTranslation('supportLogs');
    const { REACT_APP_DEFAULT_PAGESIZE } = process.env; 
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [table, setTable] = useState([]);   
    const [siteName, setSiteName] = useState("");
    const [companyLogo, setCompanyLogo] = useState("");
    const [resellerLogo, setResellerLogo] = useState("");
    const [appName, setAppName] = useState("");   
    const loggedUserId = localStorage.getItem('UserId')
    const [pageSize, setPagesize] = useState(REACT_APP_DEFAULT_PAGESIZE)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(5)
    const showPagination = totalCount > REACT_APP_DEFAULT_PAGESIZE;
   

    useEffect(() => {        
        setLoading(true)
        const params = new URLSearchParams(location.search);
        const siteId = params.get("siteId");
        const companyId = params.get("companyId");
        const appId = params.get("appId");       
        fetchAllSupportLogs({
            PageIndex: currentPage,
            PageSize: pageSize > REACT_APP_DEFAULT_PAGESIZE ? pageSize : REACT_APP_DEFAULT_PAGESIZE,
            UserId: loggedUserId,
            SiteId: siteId,
            CompanyId: companyId,
            AppId: appId
        })
            .then(response => {                    
                getCompanySupportLogs(siteId, companyId, appId)
                dispatch(getsupportlogsdata(response.data))
                setTable(response.data.data)
                setTotalCount(response.data.data[0].totalCount);              
                setLoading(false)                
            })

            .catch((err) => {
                console.log("Err: ", err);
            });
    }, [currentPage,pageSize])

  

    const getCompanySupportLogs = (siteId, companyId, appId) => {        
        setLoading(true)
        fetchCompanySupportLogs({                      
            SiteId: siteId,
            CompanyId: companyId,   
            AppId: appId
        })
            .then(response => {      
                
                dispatch(getcompanysupportdata(response.data))
                setSiteName(response.data.data[0].siteName)
                setAppName(response.data.data[0].appName)
                setCompanyLogo(response.data.data[0].companyLogo)
                setResellerLogo(response.data.data[0].resellerCompanyLogo)
                setLoading(false)
            })
            .catch((err) => {
                console.log("Err: ", err);
            });               
    }

    const handlePageChange = (page, newPageSize) => {       
        setCurrentPage(page);
        setPagesize(newPageSize);        
    };

    const columns = [

        {
            title: t("SrNo"),
            dataIndex: "",
            render: (value, item, index) => (currentPage - 1) * pageSize + index + 1,
        },

        {
            title: t("RequestedBy"),
            dataIndex: "requestedBy",           
        },

        {
            title: t("Description"),
            dataIndex: "description",           
        },

        {
            title: t("RequestDate"),
            dataIndex: "requestDate",            
        },

        {
            title: t("AssignedDate"),
            dataIndex: "assignDateLog",
        },

        {
            title: t("Message"),
            dataIndex: "statusMessageLog",
        },
    ]

    return (
        <div>           
            <>
                <Title title="Support Logs"></Title>
                <Layout>
                    <section className="Company_section common ">
                        <Toaster />
                        <div className="container site-container">
                            <div className="mt-4 w-100 position-relative wk-select">
                                <h4 className="m-0">{t("SupportLogs")}</h4>
                            </div>               
                          
                            <div>
                                <div className="support-logs">
                                    <div className="row align-items-end">
                                        <div className="col-md-4">
                                            <div className="support-logos">
                                                <img class="100%" src={companyLogo}
                                                ></img>
                                                <h5 className="py-2 mb-0 text-center">{t("Company")}</h5>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="support-logos">
                                                <img class="100%" src={resellerLogo}
                                                ></img>
                                                <h5 className="py-2 mb-0 text-center">{t("ResellerCompany")}</h5>
                                            </div>
                                        </div><div className="col-md-4">
                                            <div className="support-logos">
                                                <label className="w-100 text-center py-3 bg-light">{siteName}</label>
                                                <h5 className="py-2 mb-0 text-center">{t("Site")}</h5>
                                            </div>
                                        </div>
                                        {/*<div className="col-md-3">*/}
                                        {/*    <div className="support-logos">*/}
                                        {/*        <label className="w-100 text-center py-3 bg-light">{appName}</label>*/}
                                        {/*        <h5 className="py-2 mb-0 text-center">Apps</h5>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="Viewer-table index--table position-relative common-table mb-4">

                                <Table
                                    className='table-responsive antd--detail-table1'
                                    columns={columns}
                                    dataSource={table}
                                    pagination={showPagination ? {
                                        total: totalCount,
                                        pageSize: pageSize,
                                        current: currentPage,
                                        showSizeChanger: true,
                                        onChange: handlePageChange,
                                        pageSizeOptions: ["50", "100"],
                                    } : false}                                  
                                />
                            </div>
                        </div>
                    </section>
                </Layout>
            </>
        </div>
    )


}

export default connect()(SupportLogs);
