import React, { useEffect, useState, useRef } from "react";
import Layout from "../Layout";
import { Scheduler } from 'smart-webcomponents-react/scheduler';
import '../../../src/calendar.css';
import { getAllSupportEvents } from "../../Redux/Api/fetch.api";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import toast, { Toaster } from 'react-hot-toast';
import TechnicianIcon from "../../assets/images/customer-support.png";
import Loader from "../loader";
import { useTranslation } from "react-i18next";

const MyCalendar = () => {

    // #region Global variables
    const { t } = useTranslation('myCalendar'); 
    const schedulerRef = useRef(); // Reference for the Scheduler component
    const [events, setEvents] = useState([]); // State to store the list of events
    const [resourceTechnicians, setResourceTechnicians] = useState([]); // State to store the list of technicians (resources)
    const [selectedTechnicians, setSelectedTechnicians] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [isloading, setIsLoading] = useState(false);

    const currentTimeIndicator = true;
    const shadeUntilCurrentTime = true;
    const scrollButtonsPosition = 'far';
    const groups = ['userId'];

    const loggedInUserId = localStorage.getItem('UserId');
    // #endregion

    // #region Handle TimeZone
    dayjs.extend(utc);
    dayjs.extend(timezone);
    // #endregion

    // #region useEffect Hooks
    useEffect(() => {
        initializeEventScheduler(loggedInUserId);
    }, []);

    useEffect(() => {
        filterEvents();
    }, [selectedTechnicians, events]);

    useEffect(() => {
        const groupTemplate = document.createElement('template');
        groupTemplate.id = 'groupTemplate';
        groupTemplate.innerHTML = `
        <div class="support-custom-group-content">
            <img src="{{image}}"></img>
            <div class="support-details">
                <div class="name">{{label}}</div>
                <div class="speciality">Group : {{supportGroup}}</div>
                <div class="speciality">Order Id : {{orderId}}</div>
            </div>
        </div>`;

        document.body.appendChild(groupTemplate);
        schedulerRef.current.groupTemplate = groupTemplate.id;
    }, []);

    // #endregion

    // #region Functions
    const timelineHeaderFormatFunction = (date, orientation, isHeaderDetails, dateValue) => {
        if (orientation === 'horizontal') {
            const segments = dateValue.split(' ');
            if (segments.length === 2) {
                return `<div>${segments[1]}</div><div class="header-day-number">${segments[0]}</div>`;
            }
        }
        return dateValue;
    };

    const resources = [
        {
            label: 'Technician',
            value: 'userId',
            dataSource: resourceTechnicians
        }
    ];

    // Function to fetch event and technician data from the API and set the state
    const initializeEventScheduler = async (userId) => {
        try {
            setIsLoading(true);
            const response = await getAllSupportEvents(userId); // Fetch events from API
            schedulerRef.current.dataSource = new window.Smart.DataAdapter({
                dataSource: response.data.data,
                dataSourceType: 'array',
                dataFields: getSchedulerDataFields()
            });

            const technicians_ = response.data.data.map(resource => ({
                label: resource.userName,
                id: resource.clientId,
                supportGroup: resource.supportGroup,
                orderId: resource.orderId,
                image: TechnicianIcon
            }));

            setResourceTechnicians(technicians_);
            setEvents(response.data.data); // Set the event data in state
            await customCalendarSettings(); // Customize calendar view if necessary
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching support events:", error); // Log any errors encountered
            setIsLoading(false);
        }
    };

    // Define data fields for the scheduler
    function getSchedulerDataFields() {
        return [
            { name: 'id', map: 'eventId', dataType: 'number' },
            { name: 'label', map: 'label', dataType: 'string' },
            { name: 'dateStart', map: 'startDate', dataType: 'date' }, // Change to 'date'
            { name: 'dateEnd', map: 'endDate', dataType: 'date' },     // Change to 'date'
            { name: 'description', map: 'description', dataType: 'string' },
            { name: 'allDay', map: 'isAllDay', dataType: 'bool' },
            { name: 'backgroundColor', map: 'backGroundColor', dataType: 'string' },
            { name: 'isDrag', map: 'isDrag', dataType: 'bool' },
            { name: 'userId', map: 'clientId', dataType: 'number' },
            { name: 'orderId', map: 'orderId', dataType: 'number' },
            { name: 'groupName', map: 'supportGroup', dataType: 'string' },
            { name: 'conference', map: 'conference', dataType: 'string' },
            { name: 'status', map: 'status', dataType: 'string' }
        ];
    }

    // Filter events based on selected technicians
    const filterEvents = () => {
        const filteredEvents = events.filter(event =>
            selectedTechnicians.length === 0 || selectedTechnicians.some(selected => selected.id === event.clientId)
        );

        schedulerRef.current.dataSource = new window.Smart.DataAdapter({
            dataSource: filteredEvents,
            dataSourceType: 'array',
            dataFields: getSchedulerDataFields()
        });
    };

    // Apply event template for each event
    const eventTemplate = function (eventContent, eventObj) {
        let eventContentWrapper = eventContent.querySelector('.event-content-wrapper');
        if (!eventContentWrapper) {
            eventContentWrapper = document.createElement('div');
            eventContentWrapper.classList.add('event-content-wrapper');
        }

        let label = eventContent.querySelector('label'),
            time = eventContent.querySelector('div.time'),
            orderNoContainer = eventContent.querySelector('.support-order-no-container'),
            descLabel = eventContent.querySelector('.desc'),
            orderIdCircle = eventContent.querySelector('.support-Orderid-circle'),
            groupName = eventContent.querySelector('.support-GroupName');

        if (!label) {
            label = document.createElement('label');
            label.classList.add('time', 'support-technician-name');
            eventContentWrapper.appendChild(label);
        }

        if (!time) {
            time = document.createElement('div');
            time.classList.add('time', 'support-small-time');
            eventContentWrapper.appendChild(time);
        }

        if (!descLabel) {
            descLabel = document.createElement('div');
            descLabel.classList.add('desc', 'support-small-time');
            eventContentWrapper.appendChild(descLabel);
        }

        if (!groupName) {
            groupName = document.createElement('div');
            groupName.classList.add('support-GroupName');
            eventContentWrapper.appendChild(groupName);
        }

        if (!orderNoContainer) {
            orderNoContainer = document.createElement('div');
            orderNoContainer.classList.add('support-order-no-container');
            eventContentWrapper.appendChild(orderNoContainer);
        }

        if (!orderIdCircle) {
            orderIdCircle = document.createElement('div');
            orderIdCircle.classList.add('support-Orderid-circle');
            orderNoContainer.appendChild(orderIdCircle);
        }

        label.textContent = eventObj.label;

        const startFrom = new Intl.DateTimeFormat(this.locale, {
            hour: this.hourFormat,
            minute: this.minuteFormat
        }).format(eventObj.dateStart);

        const endTo = new Intl.DateTimeFormat(this.locale, {
            hour: this.hourFormat,
            minute: this.minuteFormat
        }).format(eventObj.dateEnd);

        time.textContent = `${startFrom} - ${endTo}`;
        orderIdCircle.style.backgroundColor = eventObj.orderIdColor || '#FF5722';
        orderIdCircle.textContent = eventObj.orderId;

        let orderIdLabel = orderNoContainer.querySelector('.support-order-id-label');
        if (!orderIdLabel) {
            orderIdLabel = document.createElement('span');
            orderIdLabel.classList.add('support-order-id-label');
            orderIdLabel.textContent = "Order Id : ";
            orderNoContainer.insertBefore(orderIdLabel, orderIdCircle);
        }

        descLabel.textContent = "Description : " + (eventObj.description || "N/A");
        groupName.textContent = "Group : " + (eventObj.groupName || "N/A");

        if (!eventContentWrapper.parentElement) {
            eventContent.appendChild(eventContentWrapper);
        }

        return eventContent;
    }

    // Custom Calendar Settings
    const customCalendarSettings = () => {
        const scheduler = document.querySelector('smart-scheduler');
        //let hasFetchedSubCompanies = false; // Flag to track if the data has been fetched
        //let subCompanies = []; // Store sub-companies here

        scheduler.addEventListener('editDialogOpen', async function (event) {
            const editors = event.detail.editors;

            if (!editors) {
                return;
            }

            const schedulerEvent = event.detail.item,
                allDayEditor = editors.allDay,
                repeatEditor = editors.repeat,
                statusEditor = editors.status,
                labelEditor = editors.label,
                descriptionEditor = editors.description,
                notificationEditor = editors.notifications,
                disableLabel = editors.label,
                disableStartDate = editors.dateStart,
                disableEndDate = editors.dateEnd,
                disableBackgroundColor = editors.backgroundColor,
                resourceDataEditor = editors.resourceData,
                resourcesEditor = editors.resources,
                conferenceEditor = editors.conference;

            // Hidden all properties 
            repeatEditor.classList.add('smart-hidden');
            conferenceEditor.classList.add('smart-hidden');
            allDayEditor.classList.add('smart-hidden');
            statusEditor.classList.add('smart-hidden');
            notificationEditor.classList.add('smart-hidden');
            resourceDataEditor.classList.add('smart-hidden');
            resourcesEditor.classList.add('smart-hidden');

            labelEditor.querySelector('.smart-element').placeholder = t("EnterSupportNote");
            descriptionEditor.querySelector('.smart-element').placeholder = t('EnterSupportDescription');
            statusEditor.querySelector('#schedulerstatusLabel').textContent = t("SubCompanies")

            // Hide Ok footer 
            event.detail.target.footerPosition = 'none';
            disableLabel.classList.add('smart-disabled');
            descriptionEditor.classList.add('smart-disabled');
            disableStartDate.classList.add('smart-disabled');
            disableEndDate.classList.add('smart-disabled');
            disableBackgroundColor.classList.add('smart-disabled');
        });
    }
    // #endregion

    // #region User Interface
    return (
        <div>
            <Layout>
                <section className="Company_section common calender-group">
                    <Toaster />
                    {isloading ? <Loader /> : ''}
                    <div className="container banner-container">
                        <h5 className="support-group-heading">{t("MyCalendar")}</h5>
                        <Scheduler
                            ref={schedulerRef}  // Reference to the Scheduler component
                            view="week"  // Set the view to 'week'
                            id="scheduler"
                            currentTimeIndicator={currentTimeIndicator}
                            scrollButtonsPosition={scrollButtonsPosition}
                            eventTemplate={eventTemplate}
                            groups={groups}
                            timelineHeaderFormatFunction={timelineHeaderFormatFunction}
                            resources={resources}
                            statuses={statuses.length ? statuses : [{ label: "Loading...", value: "loading" }]}
                        />
                    </div>
                </section>
            </Layout>
        </div>
    );
    // #endregion
};

export default MyCalendar;
