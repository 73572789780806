import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import '../../../src/custom.css'
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import Arrow_leftbrown from "../../assets/images/Arrow_leftbrown.svg";
import { Input, Form, FormFeedback } from "reactstrap"
import { Formik, Field, useFormik } from 'formik';
import * as Yup from 'yup';
import * as url from "../../api/uri_helper";
import axios from "axios";
import "react-phone-input-2/lib/bootstrap.css";
import { fetchUserById } from "../../Redux/Api/fetch.api";
import Loader from "../loader";
import Layout from '../Layout';
import NotAuthorised from "../Authentication/NotAuthorised";
import LoginImage from "../../assets/images/login-image.png";
import PhoneInput from 'react-phone-input-2'
import UploadImage from "../UploadImages/UploadImage";
import { useTranslation } from "react-i18next";

const MyProfile = () => {
    const { t } = useTranslation('myProfile');
    const loggedUserId = localStorage.getItem('UserId')
    const [name, setName] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [lname, setLname] = useState("");
    const [country, setCountry] = useState("")
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [postalCode, setPostalCode] = useState("");
    let history = useHistory();  
    const [phoneError, setPhoneError] = useState(false);
    const [selectedCountryCode, setSlectedCountryCode] = useState()
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();   
    const [disable, setDisable] = useState(false);      
    const [projectError, setProjectError] = useState(false);
    const loggedInUserId = localStorage.getItem('UserId')
    const allLoginTypes = useSelector((state) => state.allLogindata.login);
    // console.log("data", allLoginTypes.userId)
    const comingid = allLoginTypes.userId;
    const [selectedfile, SetSelectedFile] = useState([]);
    const [profilePicture, setProfilePicture] = useState({
        upload: {
            pictures: [],
            // maxFileSize: 5242880,
            imgExtension: [".jpg", ".png", ".jpeg"],
            defaultImages: [
            ]
        }
    })
    const [logoName, setLogoName] = useState("");
    const [mediaTypeSelected, setMediaTypeSelected] = useState(false)
    const [mediaFiles, setMediaFiles] = useState();
    const [imagesErr, SetImagesErr] = useState(false)
    const [imagesInvalidErr, setImagesInvalidErr] = useState(false)

    useEffect(() => {
        setLoading(true)
        fetchUserById(loggedUserId).then(
            response => {
                setName(response.data.data.firstName);
                setLname(response.data.data.lastName);
                setEmail(response.data.data.emailId)
                setPhone(response.data.data.phoneNumber)
                setCity(response.data.data.city)
                setState(response.data.data.state)
                setPhone(response.data.data.phoneNumber)
                setAddress(response.data.data.address)
                setPostalCode(response.data.data.postalCode)
                setCountry(response.data.data.country)
                setMediaFiles(response.data.data.profilePicture)

            })
        setLoading(false)
    }, [])

    function b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;
        var byteCharacters = atob(b64Data);
        var byteArrays = [];
        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);
            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    const UpdateUserInformation = async (e) => {
        setLoading(true)
        const formData = new FormData();
        if (profilePicture.upload.pictures.length > 0 || mediaFiles != null) {
            setLoading(true)
            var blob;
            //image
            {
                profilePicture.upload.pictures.length > 0 ?
                    profilePicture.upload.pictures.forEach((mFile) => {
                        var imageUrl = mFile.split(";");
                        var contentType = imageUrl[0].split(":")[1];
                        var realData = imageUrl[2].split(",")[1];
                        // Convert to blob
                        blob = b64toBlob(realData, contentType);
                        const _fileData = new File([blob], imageUrl[1].substring(5)); // passing name 
                        formData.append("File", _fileData);
                        //isMedia = true;
                    })
                    :
                    blob = fetch(mediaFiles).then(r => r.blob());
                const _fileData = new File([blob], mediaFiles); // passing name 
                formData.append("ProfilePicture", _fileData.name);
                setLogoName(_fileData.name)
            }
        }
        formData.append("UserId", loggedInUserId)
        formData.append("EmailId", email.toLowerCase());
        formData.append("PhoneNumber", phone || '');
        formData.append("City", city || '');
        formData.append("State", state || '');
        formData.append("Country", country || '');
        formData.append("Address", address || '');
        formData.append("PostalCode", postalCode || '');

        const response_ = await axios({
            method: 'put',
            url: url.UPDATE_MYPROFILE,
            data: formData,
        }).then(response => {
            if (response.data.success == false) {

                toast.error(t("ErrorUpdatingUser"), {
                    className: "toastSuccess",
                });
            }
            else {
                toast.success(t("Userupdatedsuccessfully"), {
                    className: "toastSuccess",
                });
                history.push('/Dashboard')
                localStorage.setItem('profilePicture',response.data.data)
            }
        })
            .catch((err) => {
                console.log("Err: ", err);
            });
        setLoading(false)

    }

    const isImage = (file) => {
        const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/jpg']; // Define accepted image MIME types
        return file && acceptedImageTypes.includes(file.type); // Check if the file type is in the accepted list
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file && isImage(file)) {
            const reader = new FileReader();
            reader.onload = () => {
                setProfilePicture(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: name ? name : '',
            lname: lname ? lname : '',
            email: email ? email : '',
            city: city ? city : '',
            state: state ? state : '',
            country: country ? country : '',
            address: address ? address : '',
            postalCode: postalCode ? postalCode : '',
            phone: phone ? phone : '',
        },

        validationSchema: Yup.object({
            postalCode: Yup.string().matches(
                /^\d{5,6}$/,
                "Pleaseentervalidcode"
            )
        }),

        onSubmit: (values) => {
            if (!phoneError && ((selectedFiles.length > 0 && mediaTypeSelected == true) || (selectedFiles.length == 0 && mediaTypeSelected == false)) ) {
                setDisable(true)
                UpdateUserInformation(values)

            }
        }
    })
  const [selectedFiles, setSelectedFiles] = useState([])
    const handleImagesChange = (files) => {
        setSelectedFiles(files)
        setProfilePicture(
            {
                ...profilePicture,
                upload: {
                    ...profilePicture.upload,
                    pictures: [...files]
                },
            },
            () => {
                console.warn("It was added!");
            }
        );
        SetImagesErr("");
    };

    const handleRemoveImgClick = () => {
        setMediaFiles("")
        SetImagesErr(true)
    };

    const handleImageError = () => {
        setImagesInvalidErr(true);
    };

    return (
        <div>
            <Layout>
                <section className="profile-box py-4">
                    <Toaster />
                    {loading ? <Loader /> : ''}

                    <div className="container">
                        <div className="head pb-4">
                            <h4 className="m-0">{t("MyProfile")}</h4>
                        </div>
                        <div className="profile-content-box">
                            <div className="card-box">
                                <div className="head border-bottom">
                                    <h5 className="m-0">{t("EditProfile")}</h5>
                                </div>
                                <Form onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    if (!phone || !(phone && phone.length > 10)) {
                                        setPhoneError(true);
                                    }
                                    return false;
                                }}>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-lg-8 col-12">
                                                <div className="py-3">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-12">
                                                            <div className="form-group">
                                                                <label>{t("FirstName")}</label>
                                                                <Input name="name" className="form-control"
                                                                    type="text"
                                                                    value={validation.values.name}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-12">
                                                            <div className="form-group">
                                                                <label>{t("LastName")}</label>
                                                                <Input type="text" placeholder="" className="form-control" value={validation.values.lname} disabled />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-12">
                                                            <div className="form-group">
                                                                <label>{t("Email")}</label>

                                                                <Input type="text" placeholder="" className="form-control" value={validation.values.email} disabled />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-12">
                                                            <div className="form-group">
                                                                <label>{t("City")}</label>
                                                                <Input type="text" placeholder="" className="form-control" onChange={(e) => { validation.handleChange(e); setCity(e.target.value) }}
                                                                    onBlur={validation.handleBlur} value={validation.values.city} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-12">
                                                            <div className="form-group">
                                                                <label>{t("State")}</label>
                                                                <Input type="text" placeholder="" className="form-control" onChange={(e) => { validation.handleChange(e); setState(e.target.value) }}
                                                                    onBlur={validation.handleBlur} value={validation.values.state} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-12">
                                                            <div className="form-group">
                                                                <label>{t("PostalCode")}</label>
                                                                <Input type="text" placeholder="" name="postalCode" className="form-control" value={validation.values.postalCode} onChange={(e) => { validation.handleChange(e); setPostalCode(e.target.value.replace(/\s{2}/g, '')) }}
                                                                    onBlur={validation.handleBlur}
                                                                    invalid={
                                                                        validation.touched.postalCode && validation.errors.postalCode ? true : false} />
                                                                        {validation.touched.postalCode && validation.errors.postalCode ? (
                                                                    <FormFeedback type="invalid">{validation.errors.postalCode}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-12">
                                                            <div className="form-group">
                                                                <label>{t("Country")}</label>
                                                                <Input type="text" placeholder="" className="form-control" value={validation.values.country} onChange={(e) => { validation.handleChange(e); setCountry(e.target.value) }}
                                                                    onBlur={validation.handleBlur} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-12">
                                                            <div className="form-group">
                                                                <label>{t("PhoneNumber")} <span className="required-fields"> * </span></label>
                                                                {/* <Input type="text" placeholder="" className="form-control" value={validation.values.phone} onChange={(e) => { validation.handleChange(e); setPhone(e.target.value) }}
                                                            onBlur={validation.handleBlur} /> */}

                                                                <PhoneInput
                                                                    className="w-5"
                                                                    country={"us"}
                                                                    enableSearch={true}
                                                                    value={phone}
                                                                    onChange={(phone, country) => {
                                                                        setSlectedCountryCode((prevState) => {
                                                                            if (prevState !== country.countryCode) {
                                                                                setPhone(country.dialCode);
                                                                            }
                                                                            else {
                                                                                setPhone(phone);
                                                                            }
                                                                            return country.countryCode;
                                                                        })
                                                                        if (phone && phone.length > 10 && !phone.replace(country.dialCode, '').startsWith('0')) {
                                                                            setPhoneError(false);
                                                                        }
                                                                        else {
                                                                            setPhoneError(true);
                                                                        }
                                                                    }}
                                                                />
                                                                {< small className="text-danger d-block mt-2">{!phoneError ? ' ' : t("PleaseEnterValidPhoneNumber")}</small>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <div className="form-group">
                                                                <label>{t("Address")}</label>
                                                                <textarea placeholder={t("Typehere")} className="form-control" value={validation.values.address} onChange={(e) => { validation.handleChange(e); setAddress(e.target.value) }}
                                                                    onBlur={validation.handleBlur}></textarea>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="d-flex">
                                                                <button type="submit" className="btn btn-primary text-nowrap me-2">{t("Save")}</button>
                                                                <Link className="btn btn-bordered ms-2" to="/Apps">{t("Cancel")}</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-12">
                                                <div className="py-3 update-profile text-center">
                                                        {/* <img src={profilePicture !=""? profilePicture: LoginImage} className="img-responsive" /> */}
                                                    <div className="company--logo-wrap">
                                                        <div className="company--logo">
                                                            <div className="upload--btn">
                                                            {imagesInvalidErr && <p>{t("Error")}</p>}
                                                                <UploadImage
                                                                    {...profilePicture.upload ? profilePicture.upload : ""}
                                                                    handleChange={handleImagesChange}
                                                                    mediaTypeSelected={mediaTypeSelected}
                                                                    setMediaTypeSelected={setMediaTypeSelected}
                                                                    buttonText={t("AddProfilePicture")}
                                                                    images={true}
                                                                    imgExtension={profilePicture.imgExtension}
                                                                    handleImageError={handleImageError}
                                                                />
                                                            </div>
                                                            <div className="uploaded_images-wrap">
                                                                {
                                                                    mediaFiles ?
                                                                        <div className="img--box position-relative">
                                                                            <span
                                                                                className="position-absolute"
                                                                                onClick={(e) => handleRemoveImgClick(e)}
                                                                            >
                                                                                X
                                                                            </span>
                                                                            <img src={mediaFiles} />
                                                                        </div> : ''
                                                                }
                                                            </div>
                                                            {/* {< small className="text-danger d-block mt-2 ms-5 text-center">{!imagesErr ? ' ' : 'Please Add image'}</small>} */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </div>
    )
}

export default connect()(MyProfile);
