import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from "react-redux";
import Loader from "../loader";
import {Link, useHistory } from 'react-router-dom';
import { Icon } from '@iconify/react';
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import { Input, Form, FormFeedback } from "reactstrap"
import socialIcon1 from '../../assets/images/social1.svg';
import socialIcon2 from '../../assets/images/social2.svg';
import socialIcon3 from '../../assets/images/social3.svg';
import socialIcon4 from '../../assets/images/social4.svg';
import LocationIcon from '../../assets/images/icon1.svg';
import PhoneIcon from '../../assets/images/icon2.svg';
import SupportIcon from '../../assets/images/icon3.svg';
import '../../../src/custom.css'
import Footer from '../PageLayout/Footer';
import * as Yup from 'yup';
import axios from "axios";
import * as url from "../../api/uri_helper";
import { addloginData } from '../../Redux/Actions/Login';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import linkedinIcon from '@iconify-icons/uil/linkedin';
import youtubeIcon from '@iconify-icons/mdi/youtube';
import Logo from '../../assets/images/authenteklogo.png';
import Header from '../PageLayout/Header';
import GetClientIPAddress from "../../api/GetClientIP";


const TOAST_LIMIT = 1;
const Login = () => {
    const { toasts } = useToasterStore();
    useEffect(() => {
        toasts
            .filter((t) => t.visible) // Only consider visible toasts
            .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit
            .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) removal without animation
    }, [toasts]);
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    const allLoginTypes = useSelector((state) => state.allLogindata.login);
    //const comingemail = allLoginTypes.emailId;
    const [loading, setLoading] = useState(false);
    const [emailid, setEmailId] = useState('');   
    const [password, setPassword] = useState('');
    const [token, setToken] = useState('');
    const [isChecked, setisActive] = useState(false)
    const [userData, setUserData] = useState()
    const [disable, setDisable] = useState(false);
    const [passwordType, setPasswordType] = useState("password");
    const dispatch = useDispatch();
    const [appAccessData, setAppAccessData] = useState([])   
    const history = useHistory();
   

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
           // emailid: comingemail,  
            emailid: localStorage.getItem('emailid') ? localStorage.getItem('emailid') : '',           
            password: localStorage.getItem('password') ? localStorage.getItem('password') : '',                      
        },
        validationSchema: Yup.object({
            emailid: Yup.string().required('Please Enter Email').matches(
                /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
               
               /* /^\w+([\.-]?\w+)*@\w+([\.web]?\w+)*(\.\w{2,3})+$/,*/
                "Please enter a valid email"
            ),
            password: Yup.string().required("Please Enter Password").matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number, and One Special Case Character"
            ),
        }),
        onSubmit: (values) => {
            setDisable(true);
            Authenticate(values);
        }
    });

    const handleChange = () => {
        setisActive(isChecked => !isChecked);
    }

    const Authenticate = async (params) => {  
        setLoading(true)
        const ip = await GetClientIPAddress();
        const response = await axios
            .post(url.POST_LOGIN, {
                emailid: params.emailid,
                password: params.password,
                isChecked: isChecked,
                IPAddress: ip
            })
            .then(response => {  
               
                dispatch(addloginData(response.data.data))                  
                if (response.data.success) {                
                    localStorage.setItem('emailid', params.emailid);
                    localStorage.setItem('UserId', response.data.data.userId);                   
                    localStorage.removeItem('isChecked')
                    localStorage.removeItem('tekaVerseToken');
                    if (isChecked) {                       
                        localStorage.setItem('UserId', response.data.data.userId);                                               
                        localStorage.setItem('tekaVerseToken', response.data.data.token);
                       // localStorage.setItem('password', isChecked ? params.password : '');
                       // localStorage.setItem('userName', response.data.data.userName);                       
                        
                        history.push({
                            pathname: "/Dashboard"  ,                                                      
                            data: response.data.message
                        });
                    } else {                       
                        localStorage.setItem('tekaVerseToken', response.data.data.token);                       
                       // localStorage.setItem('userName', response.data.data.userName);   
                        localStorage.setItem('UserId', response.data.data.userId);
                       
                        history.push({
                            pathname: "/Dashboard" ,                                                    
                            data: response.data.message
                        });
                    }
                }

                else {
                   
                    toast.error(response.data.message, {
                        className: "toastSuccess",
                    },);
                }
            })
            .catch((err) => {
                
                toast.error(err.response.data.message, {
                    className: "toastSuccess",
                },);
                setDisable(false);
            });
        setLoading(false)
    }

    useEffect(() => {
        
        const rememberMe = localStorage.getItem('isChecked');
        const alreadyLoggedIn = localStorage.getItem('tekaVerseToken')

        if (alreadyLoggedIn) {
            history.push("/Dashboard");
        }

        if (rememberMe) {
            const userId = rememberMe ? localStorage.getItem('emailid') : '';
            const password = rememberMe ? localStorage.getItem('password') : '';
            setisActive(true);
            setEmailId(userId);
            setPassword(password);
        }
    },[])

        return (
            <div>              
                <div>
                   
                    <div className="brand-logo text-center">
                        <h1 className="logo_str"><b>TEK</b>a<span>Verse</span></h1>
                    </div>
                </div>
                <section className="contact_section login-page">
                    <Toaster />
                    {loading ? <Loader /> : ''}
                    <div className="container">
                        <div className="contact_inner">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="left_form">
                                        <div className="headings_2 text-center">
                                            <h6 className="mb-3 primary-clr">Login to your Account</h6>
                                        </div>
                                       <Form 
                                            className="form-horizontal" onSubmit={(e) => {
                                                e.preventDefault(); validation.handleSubmit(); 
                                           return false;
                                            }}>  
                                            <div className="form-group mb-3">
                                                <Input name="emailid" className="form-control" placeholder="Enter Email" type="text" maxLength={50} onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.emailid} invalid={validation.touched.emailid && validation.errors.emailid ? true : false} />
                                                {validation.touched.emailid && validation.errors.emailid ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.emailid}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                            <div className="form-group mb-3">
                                                <Input name="password" className="form-control" placeholder="Enter Password" type={passwordType} maxLength={50} onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.password} invalid={validation.touched.password && validation.errors.password ? true : false} />
                                                <div className="input-group-append">
                                                    <span className="input-group-text" id="basic-addon2">
                                                        <div onClick={togglePassword}>
                                                            {passwordType === "password" ? <VisibilityOffIcon style={{ color: 'gray' }} /> : <VisibilityIcon style={{ color: 'gray' }} />}
                                                        </div>
                                                    </span>
                                                </div>
                                                {validation.touched.password && validation.errors.password ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.password}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                            <div className="check_wrap d-flex justify-content-between align-items-center mb-3 ">
                                                <div className="form-check">
                                                    <input type="checkbox" className="me-2" name="isChecked" checked={isChecked} onChange={handleChange} />{' '}
                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                        Remember Me
                                                    </label>
                                                </div>
                                                <Link to={'/EmailVerification'} title="Click to reset password" className="link"> Forgot Password ?</Link>
                                            </div>
                                            <button id="logbtn" className="btn btn-primary" type="submit">Sign In</button>                                        
                                        </Form>
                                    </div>
                                </div>
                                                                                             
                            </div>
                        </div>
                    </div>
                </section>
                <div className="footer-section pb-3 pt-3 w-100"
                   
                >
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6 center-text">&#169; AuthenTEK ({new Date().getFullYear()}) | TEKaVerse &#8482;</div>
                            <div className="col-sm-6 center-text text-right">
                                <div className="footer--logo">Powered by <a href="https://authentek.io/" target="_blank"><img src={Logo}></img> </a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

export default  connect()(Login);
