import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import {
  Table,
  Button,
  Input,
  Form,
  DatePicker,
  Switch,
  InputNumber,
  Upload,
  Select,
  Row,
  Col,
  message,
  Dropdown,
  Menu,
  Pagination,
} from "antd";
import { useLocation } from "react-router-dom";
import MapIcon from "@mui/icons-material/Map";
import '../../../src/parts.css';
import { useHistory } from "react-router-dom";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Modal as Modalle } from "antd";
import { useMemo } from "react";
import * as urls from "../../api/uri_helper";
import * as dayjs from "dayjs";
import { FormFeedback } from "reactstrap";
import axios from "axios";
import moment from "moment";
import { Formik, Field, useFormik } from "formik";
import * as Yup from "yup";
import Layout from "../Layout";
import Loader from "../loader";
import '../../../src/parts.css';
import UploadImage from "../UploadImages/UploadImage";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  fetchAllActivePartsData,
  fetchAllPartsDataById,
} from "../../Redux/Api/fetch.api";
import Title from "../Title";
import NotAuthorised from "../Authentication/NotAuthorised";
import searchIcon from "../../assets/images/SearchIcon.svg";
import { Tooltip } from "antd";
import Edit_light from "../../assets/images/Edit_light.svg";
import Trash_light from "../../assets/images/Trash_light.svg";
import Add from "../../assets/images/Add.svg";
import { v4 as uuidv4 } from "uuid";
import TextArea from "antd/es/input/TextArea";
import { CloseOutlined, ImageOutlined } from "@mui/icons-material";
import { render } from "react-dom";
import { data } from "jquery";
import {
  GET_DATABYPARTID,
  DELETE_PARTDATABYID,
  POST_ALLDATAPARTS,
} from "../../api/uri_helper";
import { useTranslation } from "react-i18next";

const Parts = () => {
  const { t } = useTranslation("parts");
  const [dueDate, setDueDate] = useState("");
  const [parts, setParts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingPart, setEditingPart] = useState(null);
  const [modalMode, setModalMode] = useState("add");
  const [selectedPart, setSelectedPart] = useState(null);
  const [form] = Form.useForm();
  const [roleName, setRoleName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mediaFiles, setMediaFiles] = useState();
  const [imagesErr, SetImagesErr] = useState(false);
  const [partName, setPartName] = useState("");
  const [resetDisplay, setResetDisplay] = useState(false);
  const [description, setDescription] = useState("");
  const [count, setCount] = useState(0);
  const history = useHistory();
  const [handleReset, setHandleReset] = useState(false);
  const [mediaTypeSelected, setMediaTypeSelected] = useState(false);
  const [partsImage, setPartsImage] = useState({
    upload: {
      pictures: [],
      // maxFileSize: 5242880,
      imgExtension: [".jpg", ".png", ".jpeg"],
      defaultImages: [],
    },
  });
  const newPartNumber = useMemo(() => uuidv4(), [isModalOpen]);
  const isMounted = useRef(true);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
  });
  const [enableSerach, setEnableSearch] = useState(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      partId: selectedPart ? selectedPart.partId : "",
      partNumber: selectedPart ? selectedPart.partNumber : newPartNumber,
      category: selectedPart ? selectedPart.category : "",
      partName: selectedPart ? selectedPart.partName : "",
      description: selectedPart ? selectedPart.description : "",
      warrantyDate:
        selectedPart && selectedPart.warrantyDate
          ? dayjs(selectedPart.warrantyDate)
          : null,
      image: selectedPart ? selectedPart.image : "",
      isActive: selectedPart ? selectedPart.isActive : true,
      quantity: selectedPart ? selectedPart.quantity : 0,
      price: selectedPart ? selectedPart.price : 0,
      lastUpdated: "2024-11-20T00:00:00",
      estimatedDeliveryDate: "2024-11-20T00:00:00",
      isDeleted: false,
    },
    validationSchema: Yup.object({
      partNumber: Yup.string().required("Part Number is required"),
      partName: Yup.string().required("Part Name is Required"),
      description: Yup.string().required("Description is required"),
      warrantyDate: Yup.date().nullable(),
      isActive: Yup.boolean(),
      quantity: Yup.number()
        .required("Quantity is required")
        .min(0, "Quantity cannot be negative"),
      price: Yup.number()
        .required("Price is required")
        .min(0, "Price cannot be negative"),
    }),
    onSubmit: async (values) => {
      try {
        handleSubmit(values);
        fetchParts();
        closeModal();
      } catch (error) {
        console.error("Error saving part:", error);
      }
    },
  });

  const handleDateChange = (date, dateString) => {
    if (date) {
      setDueDate(date.toISOString());
      validation.setFieldValue("warrantyDate", date.toISOString());
    } else {
      setDueDate(null);
      validation.setFieldValue("warrantyDate", null);
    }
  };

  const fetchParts = async (page = pagination.current, pageSize = pagination.pageSize) => {
    try {
      setLoading(true);

      // Define the parameters to be sent in the GET request
      let payload = {
        PageSize: pageSize,
        PageIndex: page,
        PartName: enableSerach ? partName : "", // Optional: Add PartName if required
      };

      // Send the GET request with query parameters
      const response = await axios.post(
       urls.GET_ALLACTIVEPARTSDATA ,
        payload
      );

      // Assuming the response has 'data' and 'count' as expected
      setParts(response.data.data);
      console.log("Fetched parts:", response.data.data);

      // Update pagination
      setPagination({
        ...pagination,
        total: response.data.count,
      });
    } catch (error) {
      console.error("Error fetching parts:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchPartsDataById = async (partId) => {
    debugger;
    try {
      setLoading(true);
      const response = await axios.post(`${GET_DATABYPARTID}/${partId}`);
      const partsData = response.data;
      console.log(partsData);
      openModal(partsData.data);
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error fetchin parts", error);
      message.error("An error occurred while fetching part data.");
    } finally {
      setLoading(false);
    }
  };

  //handle Pagination change
  const handlePaginationChange = (page, pageSize) => {
    setPagination(prev => ({
      ...prev,
      current: page,
      pageSize: pageSize
    }));
  };

  const handleEditClick = (partId) => {
    debugger;
    fetchPartsDataById(partId);
  };

  const handleResetButton = () => {
    setPartName("");
    setHandleReset(true);
    setResetDisplay(false);
    fetchParts();
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      SearchControl();
    }
  };

  const handleMappingClick = (record) => {
    const partId = encodeURIComponent(record.partId);
    const partName = encodeURIComponent(record.partName);
    window.open(`/PartsDeviceMapping?partId=${partId}&partName=${partName}`);
  };
  const SearchControl = async () => {
    debugger;
    if (partName.trim() !== "") {
      setResetDisplay(true);
      setEnableSearch(true);
      await fetchParts();
    }
  };


  const handleSubmit = async (formValues) => {
    debugger;
    try {
      setLoading(true);
  
      // Create FormData instance
      const formData = new FormData();
  
      // Append the form values to FormData
      formData.append("partNumber", formValues.partNumber);
      formData.append("partName", formValues.partName);
      formData.append("description", formValues.description);
      formData.append("warrantyDate", formValues.warrantyDate || new Date().toISOString().slice(0, 19));
      formData.append("isActive", formValues.isActive);
      formData.append("quantity", formValues.quantity);
      formData.append("price", formValues.price);
      formData.append("category", formValues.category || "Test");
      formData.append("lastUpdated", formValues.lastUpdated);
      formData.append("estimatedDeliveryDate", formValues.estimatedDeliveryDate);
      formData.append("isDeleted", formValues.isDeleted);
  
      // Handle image uploads
      if (partsImage.upload.pictures.length > 0) {
        const imageFile = partsImage.upload.pictures[0];  // Taking only the first image
        const imageUrl = imageFile.split(";");
        const contentType = imageUrl[0].split(":")[1];
        const realData = imageUrl[2].split(",")[1];
        const blob = b64toBlob(realData, contentType);
        const _fileData = new File([blob], imageUrl[1].substring(5)); // passing name
  
        formData.append("file", _fileData || null);  // Append the file to FormData
      }
  
      console.log("Form data:", formData);
  
      // Determine URL and method based on modal mode
      let url = "";
      let method = "";
  
      if (modalMode === "edit") {
        url = urls.UPDATE_ALLDATAPARTS;
        method = "put";
      } else {
        url = urls.POST_ALLDATAPARTS;
        method = "post";
      }
  
      // Send the request using axios with FormData
      const response = await axios({
        method: method,
        url: url,
        data: formData, // Pass FormData
        headers: {          
        },
      });
  
      if (isMounted.current) {
        fetchParts();
        closeModal();
        console.log("response", response);
        message.success(response.data);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  };
  

  const openModal = (part = null) => {

    try {
      if (part) {
        setModalMode("edit");
        setSelectedPart(part);
      } else {
        setModalMode("add");
        setSelectedPart(null);
      }

      setIsModalOpen(true);
    } catch (error) {
      console.error("Error while opening the modal", error);
    }
  };

  const closeModal = () => {
    setEditingPart(null);
    form.resetFields();
    setDueDate(null);
    validation.resetForm();
    setIsModalOpen(false);
  };

  const deletePart = async (data) => {
    try {
      const payload = {
        PartID: data.partId,
        PartNumber: data.partNumber,
      };
      const response = await axios.delete(`${DELETE_PARTDATABYID}`, {
        data: payload,
      });
      if (response.status === 200) {
        message.success("Part deleted successfully!");
        fetchParts();
      } else {
        message.error(response.data || "Failed to delete the part.");
      }
    } catch (error) {
      console.error("Error deleting part:", error);
    }
  };

  const { confirm } = Modalle;
  const handleDelete = (data) => {
    debugger;
    confirm({
      title: t("Areyousureyouwanttodeletethisrecord"),
      okText: t("Yes"),
      okType: "danger",
      cancelText: t("No"),
      onOk() {
        deletePart(data);
      },
      onCancel() {},
    });
  };

  const handleImagesChange = (files) => {
    setPartsImage({
      ...partsImage,
      upload: {
        ...partsImage.upload,
        pictures: [...files],
      },
    });
    SetImagesErr(false);
  };

  const handleRemoveImgClick = (mode) => {
    if (mode === "edit") {
      setPartsImage({
        upload: {
          pictures: [],
          // maxFileSize: 5242880,
          imgExtension: [".jpg", ".png", ".jpeg"],
          defaultImages: [],
        },
      });
      setMediaFiles(null);
      if (selectedPart) {
        selectedPart.image = ""; // Clear the image property from the part
      }
    } else if (mode === "new") {
      // Clear the mediaFiles for new images
      setMediaFiles(null);
      setPartsImage({
        ...partsImage,
        upload: {
          ...partsImage.upload,
          pictures: [],
        },
      });
    }
    SetImagesErr(true);
  };

  useEffect(() => {
    fetchParts();
  }, [pagination.current, pagination.pageSize, handleReset]);

  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;
    var byteCharacters = atob(b64Data);
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  const columns = [
    // {
    //   title: "Sr.No.",
    //   key: "serialNumber",
    //   render: (text, record, index) => index + 1,
    // },
    { title:  t("PartNumber"), dataIndex: "partNumber", key: "partNumber" },
    {
      title: t("Image"),
      dataIndex: "image",
      key: "image",
      render: (imageUrl) =>
        imageUrl ? (
          <img
            src={imageUrl}
            alt="Part"
            className="parts_table_image"
          />
        ) : (
          <ImageOutlined style={{ fontSize: "40px", color: "gray" }} />
        ),
    },
    { title: t("PartName"), dataIndex: "partName", key: "partNumber" },
    { title: t("Description"), dataIndex: "description", key: "description" },
    {
      title: t("Price"),
      dataIndex: "price",
      key: "price",
      render: (price) => `${price.toFixed(2)}`,
    },
    { title: t("Quantity"), dataIndex: "quantity", key: "quantity" },

    {
      title: t("DeviceMapping"),
      key: "index",
      render: (record, index) => (
        <>
          <Tooltip title={t("ClicktogotoMappingpage")}>
            <button className="table-btn" id={index + 1}>
              <MapIcon
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => handleMappingClick(record)}
              />
            </button>
          </Tooltip>
        </>
      ),
    },

    {
      title: t("Action"),
      key: "actions",
      dataIndex: "",

      render: (data) => (
        <Dropdown
          className="action-dropdown"
          overlay={
            <Menu>
              <Menu.Item
                key="edit"
                onClick={() => handleEditClick(data.partId)}
              >
                <img src={Edit_light} alt="Edit" className="edit_icon mr-2"  />{" "}
                {t("Edit")}
              </Menu.Item>
              <Menu.Item key="delete" onClick={() => handleDelete(data)}>
                <img src={Trash_light} alt="Edit" className="delete_icon mr-2"  />{" "}
                {t("Delete")}
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
          placement="bottomRight"
        >
          <a className="ant-dropdown-link " onClick={(e) => e.preventDefault()}>
            <span>{t("Actions")}</span>

          </a>
        </Dropdown>
      ),
    },
  ];

  return (
    <div>
      <Title title="Parts"></Title>
      <>
        {loading ? <Loader /> : ""}
        <Layout>
          <section className="Parts_section common">
            <div className=" container parts-container">
              <div className="mt-4 w-100 position-relative wk-select">
                <h4 className="m-0">{t("Parts")}</h4>
                <div className="ml-auto d-flex flex-wrap right-section">
                  <div className="d-flex position-relative searchBox">
                    <input
                      type="text"
                      className="form-control searchQR"
                      value={partName}
                      placeholder={t("SearchByPartsName")}
                      onChange={(e) => {
                        debugger;
                        setPartName(e.target.value.trimLeft());
                      }}
                      onKeyDown={(e) => handleKeyPress(e)}
                    ></input>
                    <button
                      onClick={() => SearchControl()}
                      className="searchButton  mt-sm-0"
                      disabled={partName === ""}
                    >
                      {" "}
                      <img src={searchIcon}></img>
                    </button>
                  </div>
                  {partName == "" && !resetDisplay ? (
                    ""
                  ) : (
                    <button
                      className="btn btn-primary d-flex align-items-center btn-new"
                      onClick={() => handleResetButton()}
                    >
                      Reset
                    </button>
                  )}
                  <button
                    className="btn btn-primary d-flex align-items-center btn-new"
                    onClick={() => openModal()}
                  >
                    <img src={Add}></img> {t("AddParts")}
                  </button>
                </div>
              </div>

              <div className="Viewer-table index--table position-relative common-table mb-4">
                <Table
                  className="antd--detail-table1"
                  dataSource={parts}
                  columns={columns}
                  rowKey="partId"
                  pagination={false}
                />

                <Pagination
                  className="parts-pagination"
                  current={pagination.current}
                  pageSize={pagination.pageSize}
                  total={pagination.total}
                  onChange={handlePaginationChange}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30", "50"]}
                  // showTotal={(total) => `Total ${total} items`}
                  locale = {{ items_per_page: t("customPage")}}
                />
              </div>
              <Modal
                show={isModalOpen}
                onHide={closeModal}
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-dialog-video modal-dialog modal-dialog-centered"
              >
                <Modal.Header>
                  <h4 className="text-black">
                    {modalMode === "edit" ? t("UpdateParts") : t("AddParts")}
                  </h4>

                  <CloseOutlined onClick={closeModal}></CloseOutlined>
                </Modal.Header>
                <Modal.Body>
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                    }}
                  >
                    <div className="add-part-body">
                      <div className="row">
                        {/* Part Number */}
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                            {t("PartNumber")}
                              <span className="required-fields"> * </span>
                            </label>
                            <Input
                              name="partNumber"
                              className={`form-control ${
                                validation.touched.partNumber &&
                                validation.errors.partNumber
                                  ? "is-invalid"
                                  : ""
                              }`}
                              placeholder="EnterPartNumber"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.partNumber}
                              readOnly
                            />
                            {validation.touched.partNumber &&
                              validation.errors.partNumber && (
                                <div className="invalid-feedback">
                                  {validation.errors.partNumber}
                                </div>
                              )}
                          </div>
                        </div>

                        {/* Part Name */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                            {t("PartName")}
                              <span className="required-fields"> * </span>
                            </label>
                            <Input
                              name="partName"
                              className={`form-control ${
                                validation.touched.partName &&
                                validation.errors.partName
                                  ? "is-invalid"
                                  : ""
                              }`}
                              placeholder={t("EnterPartName")}
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.partName}
                            />
                            {validation.touched.partName &&
                              validation.errors.partName && (
                                <div className="invalid-feedback">
                                  {validation.errors.partName}
                                </div>
                              )}
                          </div>
                        </div>
                        {/* Category */}
                        <div className="col-md-6">
                          <div>
                            <label className="text-black mr-2 mb-2 d-block">
                            {t("Category")}
                            </label>
                            <select
                              className="form-select-parts w-100"
                              name="category"
                              value={validation.values.category}
                              onChange={(e) =>
                                validation.setFieldValue(
                                  "category",
                                  e.target.value
                                )
                              }
                            >
                              <option value="" defaultChecked>
                              {t("SelectCategory")}
                              </option>
                              <option value="Electrical">Electrical</option>
                              <option value="Mechanical">Mechanical</option>
                              <option value="Test">Test</option>
                            </select>
                          </div>
                        </div>
                        {/* Description */}
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                            {t("Description")}
                              <span className="required-fields"> * </span>
                            </label>
                            <div className="input-group">
                              <TextArea
                                name="description"
                                className={`form-control site-description ${
                                  validation.touched.description &&
                                  validation.errors.description
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder={t("EnterDescriptionHere")}
                                type="textarea"
                                maxLength={250}
                                onChange={(e) => {
                                  validation.handleChange(e);
                                  setDescription(
                                    e.target.value.replace(/\s+/g, " ")
                                  );
                                  setCount(e.target.value.length);
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.description}
                                invalid={
                                  validation.touched.description &&
                                  validation.errors.description
                                    ? true
                                    : false
                                }
                              />

                              {/* <small className="count text-left">
                                {count}/250
                              </small> */}
                              {validation.touched.description &&
                                validation.errors.description && (
                                  <div className="invalid-feedback">
                                    {validation.errors.description}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>

                        {/* Quantity */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                            {t("Quantity")}
                              <span className="required-fields"> * </span>
                            </label>
                            <InputNumber
                              name="quantity"
                              min={0}
                              className={`form-control ${
                                validation.touched.quantity &&
                                validation.errors.quantity
                                  ? "is-invalid"
                                  : ""
                              }`}
                              placeholder={t("EnterQuantity")}
                              onChange={(value) => {
                                validation.setFieldValue("quantity", value);
                              }}
                              onBlur={validation.handleBlur}
                              value={validation.values.quantity}
                            />
                            {validation.touched.quantity &&
                              validation.errors.quantity && (
                                <div className="invalid-feedback">
                                  {validation.errors.quantity}
                                </div>
                              )}
                          </div>
                        </div>

                        {/* Price */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                            {t("Price")}
                            <span className="required-fields"> * </span>
                            </label>
                            <InputNumber
                              name="price"
                              min={0}
                              className={`form-control ${
                                validation.touched.price &&
                                validation.errors.price
                                  ? "is-invalid"
                                  : ""
                              }`}
                              placeholder="Enter Price"
                              onChange={(value) => {
                                validation.setFieldValue("price", value);
                              }}
                              onBlur={validation.handleBlur}
                              value={validation.values.price}
                            />
                            {validation.touched.price &&
                              validation.errors.price && (
                                <div className="invalid-feedback">
                                  {validation.errors.price}
                                </div>
                              )}
                          </div>
                        </div>

                        {/* Waranty Date */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label> {t("WarrantyDate")}</label>
                            <DatePicker
                              showTime
                              size={"small"}
                              name="warrantyDate"
                              className="form-control"
                              onChange={handleDateChange}
                              placeholder={t("SelectDate")}
                              value={
                                dueDate
                                  ? dayjs(dueDate)
                                  : validation.values.warrantyDate
                                  ? dayjs(validation.values.warrantyDate)
                                  : null
                              }
                            />
                          </div>
                        </div>
                        {/* Image Upload */}
                        <div className="col-md-12">
                          <label> {t("PartsImage")}</label>
                          <div className="company--logo-wrap">
                            <div className="company--logo">
                              <div className="upload--btn">
                                <UploadImage
                                  {...(partsImage.upload
                                    ? partsImage.upload
                                    : "")}
                                  handleChange={handleImagesChange}
                                  mediaTypeSelected={mediaTypeSelected}
                                  setMediaTypeSelected={setMediaTypeSelected}
                                  buttonText={t("UploadImage")}
                                  images={true}
                                />
                              </div>

                              <div className="uploaded_images-wrap">
                                {selectedPart?.image && !mediaFiles ? (
                                  <div className="img--box position-relative">
                                    <span
                                      className="position-absolute"
                                      onClick={() =>
                                        handleRemoveImgClick("edit")
                                      }
                                    >
                                      X
                                    </span>
                                    <img
                                      src={selectedPart.image}
                                      alt="Part"
                                      className="selectedpart_image"
                                    />
                                  </div>
                                ) : null}

                                {mediaFiles ? (
                                  <div className="img--box position-relative">
                                    <span
                                      className="position-absolute"
                                      onClick={() =>
                                        handleRemoveImgClick("new")
                                      }
                                    >
                                      X
                                    </span>
                                    <img
                                    className="selectedpart_image"
                                      src={mediaFiles}
                                      alt="Uploaded"
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <small className="text-danger d-block mt-2 ms-5 text-center">
                                {!imagesErr ? " " : t("PleaseAddanImage")}
                              </small>
                            </div>
                          </div>
                        </div>

                        {/* Active Checkbox */}
                        <div className="col-md-6">
                          <div className="form-checkbox">
                            <label>
                              <input
                                type="checkbox"
                                className="check mr-2"
                                name="isActive"
                                checked={validation.values.isActive}
                                onChange={validation.handleChange}
                              />
                              {t("Active")}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Action Buttons */}
                    <div className="text-right">
                      <button
                        type="submit"
                        className="btn btn-primary mr-3"
                        onClick={() => validation.submitForm()}
                      >
                        {modalMode === "edit" ? t("Update") : t("Save")}
                      </button>
                      <button
                        className="btn btn-primary mr-3"
                        type="button"
                        onClick={closeModal}
                      >
                        {t("Cancel")}
                      </button>
                    </div>
                  </Form>
                </Modal.Body>
              </Modal>
            </div>
          </section>
        </Layout>
      </>
    </div>
  );
};

export default Parts;
