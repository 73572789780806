import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import '../../../src/custom.css'
import Header from '../PageLayout/Header';
import Footer from '../PageLayout/Footer';
import Edit_light from "../../assets/images/Edit_light.svg";
import { Select } from 'antd';
import Loader from "../loader";
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import { fetchAllCompaniesdata, fetchAllCompanieswithsites, fetchUserMenudata, fetchUserRoledata } from "../../Redux/Api/fetch.api";
import { getallcompanies, deletecompanydata, getallcompanieswithsites } from '../../Redux/Actions/CompanyActions';
import axios from "axios";
import * as url from "../../api/uri_helper";
import Table, { TableProps } from "antd/lib/table";
import Trash_light from "../../assets/images/Trash_light.svg";
import { Modal } from 'antd';
import Add from "../../assets/images/Add.svg";
import Layout from '../Layout';
import { Tooltip } from 'antd';
import NotAuthorised from "../Authentication/NotAuthorised";
import { getuserroleandmenu } from '../../Redux/Actions/UsersActions';
import searchIcon from "../../assets/images/SearchIcon.svg";
import { useTranslation } from "react-i18next";
import Title from "../Title";

const CompanyIndex = () => {
    const { t } = useTranslation('company');
    const { REACT_APP_DEFAULT_PAGESIZE } = process.env;
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [table, setTable] = useState([]);
    const [pageSize, setPageSize] = useState(REACT_APP_DEFAULT_PAGESIZE);
    const [options, setOptions] = useState({
        pagination: {
            current: 1,
            pageSize: pageSize,
            pageSizeOptions: ['50', '100'],
            showSizeChanger: true,
        },
    });
    // const [options, setOptions] = useState({ pagination: { current: 1 }, pageSize: pageSize });
    const [totalIndex, setCurrentIndex] = useState()
    const [pageData, setPageData] = useState({});
    const [total, setTotal] = useState()
    let history = useHistory();
    const loggedUserId = localStorage.getItem('UserId')
    const [roleName, setRoleName] = useState(null);
    const [companyName, setCompanyName] = useState("")
    const [userRoleData, setUserRoleData] = useState([]);
    const [resetDisplay, setResetDisplay] = useState(false);

    useEffect(() => {
        //setTimeout(() => {
        //    setLoading(false)
        //}, 2000)
        getRoleDetails();
        getcompanieswithsites();

    }, [])

    const getcompanieswithsites = (isReset = false) => {
        setLoading(true)
        fetchAllCompanieswithsites({
            CompanyName: isReset ? "" : companyName,
        })
            .then(response => {
                dispatch(getallcompanieswithsites(response.data))
                setTable(response.data.data)
                setOptions({
                    ...options,
                    pagination: {
                        ...options.pagination,
                        total: response.data.count,
                    },
                });
                setLoading(false)
            })
            .catch((err) => {
                console.log("Err: ", err);
            });



    }

    const getRoleDetails = () => {
        if (loggedUserId == null) {
            history.push("/");
        }
        fetchUserRoledata(loggedUserId)
            .then(response => {
                dispatch(getuserroleandmenu(response.data))
                setUserRoleData(response.data.data.userRoleDetails);
                if (response.data.data.userRoleDetails[0].roleName)
                    setRoleName(response.data.data.userRoleDetails[0].roleName);
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }

    const deleteCompanyRecord = async (companyid) => {

        setLoading(true)
        await axios.delete(url.DELETE_COMPANYTYPE + '/' + companyid, {
        }).then(response => {

            dispatch(deletecompanydata(response.data))
            setTimeout(() => {
                setLoading(false)
            }, 1000)
            toast.success(t("CompanyDeletedSuccessfully"), {
                className: "toastSuccess",
            },);
            getcompanieswithsites()

        }).catch((err) => {
            console.log("Err: ", err);
        });
        setLoading(false)



    }
    const handleChange = (pagination, sorter, current) => {
        const updatedOptions = { ...options, pagination };
        setOptions(updatedOptions);
        setPageData(updatedOptions)
    };

    const handleEdit = (companyId) => {
        history.push('/Company/edit/' + companyId)
    }

    const { confirm } = Modal;
    const handleDelete = (companyId) => {
        confirm({
            title: t("Areyousureyouwanttodeletethisrecord"),
            okText: t("Yes"),
            okType: 'danger',
            cancelText: t("No"),
            onOk() {
                deleteCompanyRecord(companyId)
            },
            onCancel() {
            },
        });
    }

    const handleResetButton = () => {
        setCompanyName("")
        getcompanieswithsites(true);
        setResetDisplay(false);
    }

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            SearchControl();
        }
    }

    const SearchControl = async () => {
        if (companyName !== '') {
            setResetDisplay(true);
        }

        else if (companyName == '' && resetDisplay) {
            setResetDisplay(false);
        }
        getcompanieswithsites();
    }

    const columns = [
        {
            title: t("SrNo"),
            dataIndex: "",

            render: (text, record, index) => { setCurrentIndex(`${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * REACT_APP_DEFAULT_PAGESIZE + index + 1}`); return `${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * REACT_APP_DEFAULT_PAGESIZE + index + 1}` },

        },

        {
            title: t("CompanyId"),
            dataIndex: "companyId",

        },

        {
            title: t("CompanyName"),
            dataIndex: "companyName",

        },

        {
            title: t("Address"),
            dataIndex: "address",
            className: "address",
            render: (address) => <Tooltip title={address}>{address}</Tooltip>,
        },



        {
            title: t("Status"),
            dataIndex: "isActive",
            render: (data) => (data ? "Active" : "Inactive"),

        },

        {
            title: t("Action"),
            className: "sitebuttons",
            dataIndex: "",
            render: (data, companyId) => (<>
                <ul className="list-inline">
                    <li className="list-inline-item mr-0">
                        <Tooltip title={t("Edit")}>
                            <button className="table-btn" >
                                <img src={Edit_light} onClick={() => handleEdit(data.companyId)}></img>
                            </button></Tooltip>
                    </li>
                    <li className="list-inline-item">
                        <Tooltip title={t("Delete")}>
                            <button className="table-btn" >
                                <img src={Trash_light} onClick={() => handleDelete(data.companyId)}></img>
                            </button></Tooltip>
                    </li>
                </ul>
            </>
            )
        },
    ]

    return (
        <div>
            <Title title="Company"></Title>
            {roleName == null ? <Loader /> : roleName && roleName != "SuperAdmin" && roleName && roleName != "Developer" ? <NotAuthorised></NotAuthorised> :
                <>
                    {loading ? <Loader /> : ''}
                    <Layout>
                        <section className="Company_section common ">
                            <Toaster />
                            <div className="container company-container">
                                <div className="mt-4 w-100 position-relative wk-select">
                                    <h4 className="m-0">{t("Company")}</h4>
                                    <div className="ml-auto d-flex flex-wrap right-section">
                                        <div className='d-flex position-relative searchBox' >
                                            <input type="text" className='form-control searchQR' value={companyName} placeholder={t('SearchByCompanyName')}
                                                onChange={(e) => {
                                                    setCompanyName(e.target.value.trimLeft());

                                                }}
                                                onKeyDown={(e) => handleKeyPress(e)}
                                            >

                                            </input>
                                            {companyName == "" ?
                                                <button onClick={() => SearchControl()} disabled className="searchButton mt-sm-0"> <img src={searchIcon} ></img></button> :
                                                <button onClick={() => SearchControl()} className="searchButton  mt-sm-0"> <img src={searchIcon} ></img></button>
                                            }
                                        </div>
                                        {companyName == "" && !resetDisplay ? "" :
                                            <button className='btn btn-primary d-flex align-items-center btn-new' onClick={() => handleResetButton()} >{t("Reset")}</button>
                                        }
                                        <Link className="btn btn-primary d-flex align-items-center btn-new" to="/Add/Company"><img src={Add}></img>{t("AddComapny")}</Link>
                                    </div>
                                </div>

                                <div className="Viewer-table index--table position-relative common-table mb-4">

                                    <Table
                                        className='antd--detail-table1'
                                        columns={columns}
                                        dataSource={table}
                                        pagination={options.pagination.total > REACT_APP_DEFAULT_PAGESIZE ? options.pagination : false}
                                        onChange={handleChange}
                                        // scroll={{ x: "450px" }}
                                        paginationTotalRows={total}

                                    />
                                </div>
                            </div>
                        </section>
                    </Layout>
                </>
            }

        </div>

    )
}
export default connect()(CompanyIndex);
