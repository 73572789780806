import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import '../../../src/custom.css'
import Header from '../PageLayout/Header';
import Footer from '../PageLayout/Footer';
import Edit_light from "../../assets/images/Edit_light.svg";
import { Select } from 'antd';
import Loader from "../loader";
import { fetchSubCompanieswithsites, fetchUserMenudata, fetchUserRoledata } from "../../Redux/Api/fetch.api";
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import { getcompanysitedata, deletecompanysitedata } from '../../Redux/Actions/CompanySiteAction';
import axios from "axios";
import * as url from "../../api/uri_helper";
import Table, { TableProps } from "antd/lib/table";
import Trash_light from "../../assets/images/Trash_light.svg";
import { Modal } from 'antd';
import Layout from '../Layout';
import Add from "../../assets/images/Add.svg";
import { Tooltip } from 'antd';
import NotAuthorised from "../Authentication/NotAuthorised";
import { getuserroleandmenu } from '../../Redux/Actions/UsersActions';
import { useTranslation } from "react-i18next";
import Title from "../Title";

const SubCompanyIndex = () => {   
    const { t} = useTranslation('subCompany');
    const { REACT_APP_DEFAULT_PAGESIZE } = process.env;
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [table, setTable] = useState([]);
    const [pageSize, setPageSize] = useState(REACT_APP_DEFAULT_PAGESIZE);
    const [options, setOptions] = useState({
        pagination: {
            current: 1,
            pageSize: pageSize,
            pageSizeOptions: [ '50', '100'],
            showSizeChanger: true,
        },
    });
   
    const [totalIndex, setCurrentIndex] = useState()
    const [pageData, setPageData] = useState({});
    const [total, setTotal] = useState()
    let history = useHistory();
    const loggedUserId = localStorage.getItem('UserId')
    const [roleName, setRoleName] = useState(null);
    const [userRoleData, setUserRoleData] = useState([]);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2000)
        getRoleDetails();
        getallSubCompanyRelations();
    }, [])

    const getallSubCompanyRelations = () => {       
        setLoading(true)
        fetchSubCompanieswithsites({
            PageIndex: options.pagination.current,
            PazeSize: 5,
        })
            .then(response => {                
                dispatch(getcompanysitedata(response.data))
                setTable(response.data.data)               
                setOptions({
                    ...options,
                    pagination: {
                        ...options.pagination,
                        total: response.data.count,
                    },
                });
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    }

    const getRoleDetails = () => {
        if (loggedUserId == null) {
            history.push("/");
        }
        fetchUserRoledata(loggedUserId)
            .then(response => {
                dispatch(getuserroleandmenu(response.data))
                setUserRoleData(response.data.data.userRoleDetails);
                if (response.data.data.userRoleDetails[0].roleName)
                    setRoleName(response.data.data.userRoleDetails[0].roleName);
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }

    const bindSite = (site) => {
        return site.map(x => x.siteName).join(", ");
    }

    const handleEdit = (Id) => {
        history.push('/CompanyMapping/Edit/' + Id)
    }

    const handleChange = (pagination, sorter, current) => {
        const updatedOptions = { ...options, pagination };
        setOptions(updatedOptions);
        setPageData(updatedOptions)
    };

    const deleteCompanySiteRecord = async (companyid) => {        
        setLoading(true)
        await axios.delete(url.DELETE_COMPANYSITES + '/' + companyid, {
        }).then(response => {           
            dispatch(deletecompanysitedata(response.data))
            setTimeout(() => {
                setLoading(false)
            }, 1000)
            toast.success(t("CompanySiteDeletedSuccessfully"), {
                className: "toastSuccess",
            },);
            getallSubCompanyRelations()

        }).catch((err) => {
            console.log("Err: ", err);
        });
        setLoading(false)
    }

    const { confirm } = Modal;
    const handleDelete = (companyId) => {
        confirm({
            title: t("Areyousureyouwanttodeletethisrecord"),
            okText: t("Yes"),
            okType: 'danger',
            cancelText: t("No"),
            onOk() {
                deleteCompanySiteRecord(companyId)
            },
            onCancel() {
            },
        });
    }
    const columns = [
        {
            title: t("SrNo"),
            dataIndex: "",
            render: (text, record, index) => { setCurrentIndex(`${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * REACT_APP_DEFAULT_PAGESIZE + index + 1}`); return `${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * REACT_APP_DEFAULT_PAGESIZE + index + 1}` },

        },

        {
            title: t("SubCompany"),
            dataIndex: "companyName",            
        },

        {
            title: t("Sites"),
            dataIndex: "sites",
            render: (data, user) => (
                user.sites ?
                    <div className="ellips-dec">
                        <Tooltip title={bindSite(user.sites)}>{bindSite(user.sites)}</Tooltip></div>
                    : "NA"
            ),
        },
       
        {
            title: t("Action"),
            className: "sitebuttons",
            dataIndex: "",
            render: (data, companyId) => (<>
                <ul className="list-inline">
                    <li className="list-inline-item mr-0">
                <button className="table-btn" >
                <img src={Edit_light} onClick={() => handleEdit(data.companyId)}></img>
                        </button>
                    </li>
                    <li className="list-inline-item mr-0">
                <button className="table-btn" >
                    <img src={Trash_light} onClick={() => handleDelete(data.companyId)}></img>
                        </button>
                    </li>
                    </ul>
                </>)
        },
    ]

    return (
        <div>
            <Title title="Sub Company Mapping"></Title>

            {roleName == null ? <Loader /> : roleName && roleName != "SuperAdmin" && roleName && roleName != "Developer" ? <NotAuthorised></NotAuthorised> :
                <>
                    {loading ? <Loader /> : ''}
                <Layout>
                    <section className="Company_section common ">
                        <Toaster />
                        <div className="container">
                            <div className="mt-4 w-100 position-relative wk-select">
                                <h4 className="m-0">{t("SubCompanyMapping")}</h4>
                                <div className="ml-auto">
                                    <Link className="btn btn-primary d-flex align-items-center btn-new" to="/CompanyMapping/Add"><img src={Add}></img>{t("AddMapping")}</Link>
                                </div>
                            </div>
                            <div className="Viewer-table index--table position-relative common-table mb-4">
                               
                                <Table
                                    className='table-responsive antd--detail-table1'
                                    columns={columns}
                                        dataSource={table}
                                       // pagination={options.pagination}  
                                        pagination={options.pagination.total > REACT_APP_DEFAULT_PAGESIZE ? options.pagination : false}
                                    onChange={handleChange}                                  
                                    paginationTotalRows={total}
                                />
                            </div>
                        </div>
                    </section>
                    </Layout>
                </>
            }
            </div>
    )

}
export default connect()(SubCompanyIndex);
