import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import '../../../src/custom.css'
import axios from "axios";
import * as url from "../../api/uri_helper";
import { addloginData } from '../../Redux/Actions/Login';
import { addUserData } from '../../Redux/Actions/UserDataAction';
import { addDashboardData } from '../../Redux/Actions/Dashboard';
import Layout from '../Layout';
import Loader from "../loader";
import { fetchAllAppsdata, fetchsitebyusercompany, fetchSubCompaniesByUserId, fetchSiteAppsdata,fetchUserMenudata, fetchAllSubCompaniesdata, fetchSiteById } from "../../Redux/Api/fetch.api";
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import { getsubcompanybyuserid } from '../../Redux/Actions/UserCompanyAction';
import { getuserroleandmenu } from '../../Redux/Actions/UsersActions';
import { getallsubcompanydata } from '../../Redux/Actions/CompanyRelationAction';
import { getusersitecompanydata } from '../../Redux/Actions/UserCompanySiteAction';
import AlarmIcon from "../../assets/images/bell-icon.svg";
import DocumentIcon from "../../assets/images/Document.svg";
import RedirectIcon from "../../assets/images/Redirect-icon.png";
import { Tooltip } from 'antd';
import NewWindow from 'react-new-window'
const TOAST_LIMIT = 1;
import GetClientIPAddress from "../../api/GetClientIP";
import Title from "../Title";

const Dashboard = () => {    
    const { t } = useTranslation('app'); 
    const dispatch = useDispatch();  
    const [token, setToken] = useState();
    const [loading, setLoading] = useState(false);   
    const [loginData, setLoginData] = useState({})
    const [dashboardUrl, setDashboardUrl] = useState()
    const [domainUrl, setDomainUrl] = useState()
    const [appAccessData, setAppAccessData] = useState([])
    const [userRoleData, setUserRoleData] = useState([])
    const [allSubCompanies, setAllSubCompanies] = useState([]);
    const [companySiteData, setCompanySiteData] = useState([]);
    const [subCompanies, setSubCompanies] = useState([]);
    const [alarmscount, setAlarmsCount] = useState();
    const [documentcount, setDocumentCount] = useState();
   // const [headerId, setHeaderId] = useState("");
    const history = useHistory();
    const userid = window.localStorage.getItem('UserId')
    const location = useLocation();
    const [companyId, setCompanyId] = useState(0);
   // const [footerId, setFooterId] = useState("");
    const [siteId, setSiteId] = useState(0);
    const [siteName, setSiteName] = useState();
    const loggedUserId = localStorage.getItem('UserId')
    const [bodyId, setBodyId] = useState("");
    const allUserTypes = useSelector((state) => state.allUserdata.users);
    const colorcode = allUserTypes.data && allUserTypes.data.colorCodeDetails;
    const headerId = colorcode && colorcode.headerColorCode;
    const footerId = colorcode && colorcode.footerColorCode;
    const userRole = allUserTypes.data && allUserTypes.data.userRoleDetails;
    const roleName = userRole && userRole[0].roleName;
    const roleId = userRole && userRole[0].roleId;
    
    const [IPClientSide, setIPClientSide] = useState("");
    const [IPAuthorized, setIPAuthorized] = useState();
   // const [roleId,setUserRoleId] = useState();
    

 
   

    useEffect(() => {
       // getUserDetails();
        getIPAddress();
       
        getSubCompaniesDetails();
       // getSubCompanyData();
    }, [userid]);

    useEffect(() => {
        getsitesbyuserandcompany();        
    }, [companyId]);

    useEffect(() => {
        if (siteId >= 0)
        getsiteappdata();
    }, [siteId]);

    const getIPAddress = async () => {
        const ip = await GetClientIPAddress();
        setIPClientSide(ip);
       
    }


   
    //const getSubCompanyData = () => {
        
    //    fetchAllSubCompaniesdata()
    //        .then(response => {                
    //            dispatch(getallsubcompanydata(response.data))
    //            setSubCompanies(response.data.data)               
    //        })
    //        .catch((err) => {
    //            console.log("Err: ", err);
    //        });
    //}

   

    const getsiteappdata = () => {
        setLoading(true)
        fetchSiteAppsdata(siteId)
            .then(response => {
                
                dispatch(addUserData(response.data.data))
                setLoginData(response.data.data)
                setAlarmsCount(response.data.data.alarmsCount)
                setDocumentCount(response.data.data.documentsCount)
                setSiteName(response.data.data.siteName)
                setIPAuthorized(response.data.data.ipAddress);
                if (response.data.data.appAccesslist && response.data.data.appAccesslist.length > 0)
                    setAppAccessData(response.data.data.appAccesslist)
                else {
                    setAppAccessData([])
                    appAccessData.length = 0
                   // setAppAccessData([])
                }
                
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
       
            setLoading(false)
     
    }

    const getsitesbyuserandcompany = () => {
        
        setLoading(true)
        fetchsitebyusercompany({
            UserId: loggedUserId,
            CompanyId: companyId
        })                   
            .then(response => {
               
                dispatch(getusersitecompanydata(response.data.data))
               
                setCompanySiteData(response.data.data);
                if (response.data.data.length == 1) {
                    setSiteId(response.data.data[0].siteId)
                   // handleSiteChange(response.data.data[0].siteId);
                }

                else if (response.data.data.length == 0) { 
                    setSiteId(0)
                }
              
               
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
        setLoading(false)
    }

    const getSubCompaniesDetails = () => {       
        fetchSubCompaniesByUserId(userid)
            .then(response => {    
                
                dispatch(getsubcompanybyuserid(response.data))
                setSubCompanies(response.data.data.companydata) 
                setAllSubCompanies(response.data.data.companyUserdata)
                if (response.data.data.companyUserdata.length <= 1)
                    setCompanyId(response.data.data.companyUserdata[0].subCompanyId)
            })
            .catch((err) => {
                console.log("Err: ", err);
            })
    }

   

    const openDashboardUrl = (url, token) => { 
        
        if (url != null) {
            const secretKey = '12345';
            const encodeddata = btoa(token)
            const encodedcompany = btoa(companyId)
            const encodedsite = btoa(siteId)
            var encryptedUrl = url + "Access/" + encodeddata + "/" + encodedcompany + "/" + encodedsite;
            var win = window.open(encryptedUrl, '_blank');
            win.focus();
        }
                                                            
    }
        
    const getDashboardUrl = async (appid, userid) => {            
        setLoading(true)
        const ip = await GetClientIPAddress();
        await axios.post(url.GETDASHBOARD_URL, {
            AppId: appid,
            UserId: userid,
            CompanyId: companyId,
            SiteId: siteId,
            IPAddress: ip
        })
            .then(response => {     
               
                dispatch(addDashboardData(response.data.data))                
                 if (response.data.success) {
                    setDashboardUrl(response.data.data.dashBoardUrl)
                    setToken(response.data.data.accessToken)
                     setDomainUrl(response.data.data.domainUrl)                                          
                         openDashboardUrl(response.data.data.domainUrl, response.data.data.accessToken);                    
                 }
                    else {
                    toast.error(t("InvalidUsernameandPassword."), {
                        className: "toastSuccess",
                    },);
                    
                 }
                setLoading(false)
                
           })
            .catch((err) => {
                toast.error(err.response.data.message, {
                    className: "toastSuccess",
                },);
            });    
        setLoading(false)
    }

    const handleSiteChange =(siteId) =>{        
        fetchSiteById(siteId).then(
            response => {                              
            //    setIP(response.data.data.ipAddress);
           setIPAuthorized(response.data.data.ipAddress);
            })
    }

    return (
        <Layout>            
            <div>            
                <Title title="Apps"></Title>
                <section className="Software_Modules_section custom-dashboard-height appsdata">  
                    <Toaster />
                    {loading ? <Loader /> : ''}
                    <div className="container">                       
                        <div className="Software_Modules_inner">
                            <div className="headings_3">
                                <div className="row">
                                {allSubCompanies && allSubCompanies.length > 1 && subCompanies && subCompanies.length > 1 ?
                                    <div className=" col-lg-5 col-12 mx-auto">
                                            <div className="form-group wk-select1 d-flex align-items-center filter-wrap"> <span className="user-span">{t("SelectCompany")} :</span>
                                            <select className="form-select mb-0 flex-grow-1 filter-select" name="users" aria-label=".form-select-lg example"
                                                value={companyId}
                                                key={companyId}
                                                    onChange={(e) => {
                                                        setSiteId(0)
                                                    setCompanyId(e.target.value)
                                                    setIPAuthorized();
                                                }}
                                            >

                                                <option value="0">{t("SelectCompany")}</option>

                                                {roleName == "User" ?
                                                    allSubCompanies && allSubCompanies.map(sub =>
                                                        <option key={sub.subCompanyId} value={sub.subCompanyId}>
                                                            {sub.subCompanyName} </option>
                                                    ) :
                                                    subCompanies && subCompanies.map(sub =>
                                                        <option key={sub.subCompanyId} value={sub.subCompanyId}>
                                                            {sub.subCompanyName} </option>
                                                    )
                                                }


                                            </select>
                                            {/*  <h2><span className="logo_wh"><b>TEK</b>aSoftware</span> Modules</h2>*/}
                                        </div>
                                    </div> :

                                        <div className=" col-lg-5 col-12 mx-auto">
                                            <div className="form-group wk-select1 d-flex align-items-center filter-wrap"> <span className="user-span">{t("SelectCompany")} :</span>
                                            <select className="form-select mb-0 flex-grow-1 filter-select" name="users" aria-label=".form-select-lg example"
                                                value={companyId}
                                                key={companyId}
                                                disabled
                                                onChange={(e) => {
                                                    setCompanyId(e.target.value)
                                                }}
                                            >
                                                <option value="0">{t("SelectCompany")}</option>
                                                {roleName == "User" ?
                                                    allSubCompanies && allSubCompanies.map(sub =>
                                                        <option key={sub.subCompanyId} value={sub.subCompanyId}>
                                                            {sub.subCompanyName} </option>
                                                    ) :
                                                    subCompanies && subCompanies.map(sub =>
                                                        <option key={sub.subCompanyId} value={sub.subCompanyId}>
                                                            {sub.subCompanyName} </option>
                                                    )
                                                }
                                            </select>                                           
                                        </div>
                                    </div>
                                    }
                                   
                                    {companyId && companyId != "0" && companySiteData && companySiteData.length >1 ?
                                        <div className=" col-lg-5 col-12 mx-auto">
                                            <div className="form-group wk-select1 d-flex align-items-center filter-wrap"> <span className="user-span">{t("SelectSite")} :</span>
                                                <select className="form-select mb-0 flex-grow-1 filter-select" name="users" aria-label=".form-select-lg example"
                                                    value={siteId}
                                                    key={siteId}
                                                    onChange={(e) => {
                                                       // handleSiteChange(e.target.value)
                                                        setSiteId(e.target.value)
                                                       
                                                    }}
                                                >
                                                    <option value="">{t("SelectSite")}</option>
                                                    {
                                                        companySiteData && companySiteData.map(sub =>                                                          
                                                            <option key={sub.siteId} value={sub.siteId}>
                                                                {sub.siteName} </option> 
                                                        )
                                                    }
                                                </select>                                               
                                            </div>
                                        </div>
                                        :
                                        <div className=" col-lg-5 col-12 mx-auto">
                                            <div className="form-group wk-select1 d-flex align-items-center filter-wrap"> <span className="user-span">{t("SelectSite")} :</span>
                                                <select className="form-select mb-0 flex-grow-1 filter-select" name="users" aria-label=".form-select-lg example"
                                                    value={siteId}
                                                    key={siteId}
                                                    disabled
                                                    onChange={(e) => {
                                                        setSiteId(e.target.value)
                                                       
                                                    }}
                                                >
                                                    <option value="">{t("SelectSite")}</option>
                                                    {
                                                        companySiteData && companySiteData.map(sub =>                                                           
                                                            <option key={sub.siteId} value={sub.siteId}>
                                                                {sub.siteName} </option> 
                                                        )
                                                    }
                                                </select>                                                
                                            </div>
                                        </div>                                   
                                    }
                                </div>
                              
                                {
                                    roleId == 1 ?
                                    IPAuthorized && IPAuthorized != "" && IPAuthorized != IPClientSide ? (
                                        <span className="no-access">* {t("Youarenoteligibleforthissite")}</span>
                                    ) : ("")
                                    : ''
                                }
                                
                               
                                {companyId && companyId != "Select Company" && siteId && appAccessData && appAccessData.length >= 1 ?

                                    <div className="d-flex box_outer">                                       
                                            <div className="row w-100">
                                            {appAccessData && appAccessData.map((item) =>
                                                    <div className="col-md-6  mb-4" key={item.appId}>
                                                        <div className="box_inner" style={{
                                                            backgroundColor: headerId,
                                                    }}>
                                                        <div className='d-flex align-items-center w-100'>
                                                            {roleId == 1 ?
                                                                (IPAuthorized == null || IPAuthorized == "") || (IPAuthorized == IPClientSide) ? (
                                                                    <button
                                                                        className="logo_str" style={{
                                                                            Color: footerId,
                                                                        }} onClick={() => getDashboardUrl(item.appId, userid)}>{item.name}
                                                                    </button>
                                                                ) : (
                                                                    <button
                                                                        className="logo_str"
                                                                        style={{
                                                                            color: footerId,
                                                                        }}
                                                                        disabled
                                                                    >
                                                                        {item.name}
                                                                    </button>
                                                                ) :
                                                                <button
                                                                    className="logo_str" style={{
                                                                        Color: footerId,
                                                                    }} onClick={() => getDashboardUrl(item.appId, userid)}>{item.name}
                                                                </button>
                                                            }
                                                            {(IPAuthorized == null || IPAuthorized == "") || (IPAuthorized == IPClientSide) || roleId != 1 ?
                                                                <div className='ml-auto'>
                                                                    <Tooltip title={t("ClicktoRedirect")}>  <img src={RedirectIcon} className="redirect-icon"
                                                                        onClick={() => getDashboardUrl(item.appId, userid)}></img></Tooltip>
                                                                </div> : ""
                                                            }
                                                        </div>
                                                        <p className="description">{item.description}</p>
                                                      

                                                        <div className="card-bottom">
                                                            <ul >
                                                                {alarmscount && item.appId == 5?
                                                                    <li title="Active Alarms" className='notification'>
                                                                        <img src={AlarmIcon} className="icon-24px"></img>
                                                                        {alarmscount}
                                                                   
                                                                    </li> : ""
                                                                }
                                                                {documentcount && item.appId == 5?
                                                                    <li title="Documents Uploaded">
                                                                        <img src={DocumentIcon} className="icon-24px"></img>
                                                                        {documentcount}</li> : ""
                                                                }

                                                               
                                                                <li title="SiteName" className="sitename">
                                                                     {siteName}
                                                                </li> 
                                                                
                                                            
                                                            </ul>
                                                        </div>

                                                        </div>
                                                    </div>
                                                )}

                                            </div> 
                                           
                                    </div> :
                                    companyId && companyId != "Select Company" && siteId && appAccessData && appAccessData.length == 0 ?
                                    <div className="no-data">                                 
                                <p> {t("ThereisnoAppassignedtothissite")}</p>
                                    </div> : ""
                                }
                        </div>
                        </div>
                    </div>
                </section>              
            </div>
        </Layout>
    );
}

export default connect()(Dashboard);