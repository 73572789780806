
import React, { useEffect, useState, useRef } from "react";
import { Tooltip, Button } from "antd"; // Import Tooltip and Button from Ant Design
//import { CheckBox } from 'smart-webcomponents-react/checkbox';
import '../../../src/supportTechnicianList.css';
import { useTranslation } from "react-i18next";
import { updatecallorderdata } from '../../Redux/Actions/CallOrderActions';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as url from "../../api/uri_helper";
import axios from "axios";
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';

const TechnicianGroupList = ({
    technicians,
    //loading,
    selectedTechnicians,
    handleTechnicianSelection,
    refreshOrderStatus,
    handleDragStart,
    handleAddTechnician, // Function to handle adding a technician
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const { t } = useTranslation('supportTechnician');
    //  const [draggedUserId, setDraggedUserId] = useState();
    //  const [draggedOrderId, setDraggedOrderId] = useState();
    // const [newcallorderId, setNewCallOrderId] = useState();
    // const [newuserId, setNewUserId] = useState();
    //  const [draggedGroupId, setDraggedGroupId] = useState();
    const loggedUserId = localStorage.getItem('UserId')
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    //useEffect(() => {
    //    if (newcallorderId > 0 && newuserId !== draggedUserId)
    //        updateCallOrderData();
    //}, [newcallorderId]);

    // Function to group and sort technicians
    const groupAndSortTechnicians = (technicians) => {

        if (!Array.isArray(technicians) || technicians.length === 0) {
            return {};
        }

        const grouped = technicians.reduce((acc, item) => {
            if (!item || !item.supportGroup) {
                return acc; // Skip invalid items
            }

            const group = item.supportGroup;
            if (!acc[group]) {
                acc[group] = []; // Create a new array for this supportGroup
            }
            acc[group].push(item); // Add the item to the group

            return acc;
        }, {});

        // Sort each group by orderId in ascending order
        for (const group in grouped) {
            grouped[group].sort((a, b) => Number(a.orderId) - Number(b.orderId));
        }

        return grouped;
    };

    //const updateCallOrderData = async () => {
    //    setLoading(true)
    //    const formData = new FormData();
    //    formData.append("oldUserId", draggedUserId);
    //    formData.append("newUserId", newuserId);
    //    formData.append("oldCallOrderId", draggedOrderId);
    //    formData.append("newCallOrderId", newcallorderId);
    //    formData.append("GroupId", draggedGroupId);
    //    formData.append("LoggedId", loggedUserId);
    //    const response_ = await axios({
    //        method: 'put',
    //        url: url.UPDATE_CALLORDERDATA,
    //        data: formData,
    //    }).then(response => {

    //        if (response.data.success == false) {
    //            toast.error(t("CallOrderorUserAlreadyExists"), {
    //                className: "toastSuccess",
    //            });
    //        }
    //        else {
    //            dispatch(updatecallorderdata(response.data))
    //            toast.success(t("CallOrderupdatedsuccessfully"), {
    //                className: "toastSuccess",
    //            });
    //            refreshOrderStatus();
    //            setDraggedUserId();
    //            setDraggedOrderId();
    //            setNewCallOrderId();
    //            setNewUserId();
    //        }
    //        setLoading(false)

    //    })
    //}

    const onDragOver = (e) => {
        e.preventDefault(); // Allow dropping
    };

    const handleDrop = (e, id, orderid) => {
        e.preventDefault();
        // setNewCallOrderId(orderid)
        // setNewUserId(id)
    };

    const groupedTechnicians = groupAndSortTechnicians(technicians);

    const colorAssignments = new Map(); // Map to store user-color assignments
    let colorIndex = 0;
    const getUniqueColor = (id) => {
        if (!colorAssignments.has(id)) {
            // Assign the next available color
            colorAssignments.set(id, colors[colorIndex]);
            colorIndex = (colorIndex + 1) % colors.length; // Cycle through colors
        }
        return colorAssignments.get(id);
    };

    // #region Random calendar colors 
    // Array of random colors to apply to the technician task items
    const colors = ['#007bff', '#28a745', '#dc3545', '#ffc107', '#17a2b8', '#343a40', '#6f42c1', '#20c997', '#e83e8c'];
    // #endregion

    return (
        <>
            {loading ? (
                <p>{t("LoadingTechnicians")}</p>
            ) : Object.keys(groupedTechnicians).length === 0 ? (
                <p>{t("Notechniciansfound")}</p>
            ) : (
                Object.entries(groupedTechnicians).map(([group, technicians]) => (
                    <div className="support-technicians-task-list" key={group}>
                        <div className="support-header-container">
                            <h2 className="support-header-title">
                                <Diversity3Icon style={{ marginRight: '8px' }} />{group}
                            </h2>

                            <Tooltip title={t("Clicktoaddsupporttechnician")}>
                                <Button
                                    type="primary"
                                    className="support-add-button"
                                    shape="circle"
                                    icon={<span>+</span>} // Use a plus sign for the button  <i>&#x21bb;</i>
                                    onClick={() => window.open(`/CallOrder?group=${encodeURIComponent(group)}`, '_blank')} // Open URL in a new tab
                                />
                            </Tooltip>
                        </div>

                        {technicians.map((technician) => (
                            <div className="support-technicians" key={technician.id}> 
                                <Tooltip title={t("DragTechnicianandAssign")}>
                                    <div
                                        className="support-technicians-task"
                                        draggable="true"  
                                        onDragStart={(e) => {
                                            handleDragStart(
                                                e,
                                                technician.label,
                                                getUniqueColor(technician.id),
                                                technician.id,
                                                technician.supportGroup,
                                                technician.orderId
                                            );
                                        }}  // Handle drag start with unique color
                                        style={{
                                            backgroundColor: getUniqueColor(technician.id), // Assign unique color
                                            flex: 1,  // Make the div take up the remaining space
                                            display: 'flex', // Use flexbox for alignment
                                            alignItems: 'center', // Center items vertically
                                        }}
                                        onDragOver={onDragOver}
                                        onDrop={(e) => handleDrop(e, technician.id, technician.orderId)}
                                    >
                                        <div className="support-technician-container">
                                            <div className="technician-label">
                                                {technician.label}
                                            </div>
                                            <div className="technician-time-zone">
                                                {technician.timeZone}
                                            </div>
                                        </div>
                                    </div>
                                </Tooltip>
                            </div>
                        ))}

                    </div>
                ))
            )}
        </>
    );
};

export default TechnicianGroupList;

