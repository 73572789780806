export const CallOrderDetails = {
    GET_CALLORDERDETAILS: "GET_CALLORDERDETAILS",
    POST_CALLORDERDETAILS: "POST_CALLORDERDETAILS",
    UPDATE_CALLORDERDETAILS: "UPDATE_CALLORDERDETAILS",
    DELETE_CALLORDERDETAILS: "DELETE_CALLORDERDETAILS",
    UPDATE_USERNAME: "UPDATE_USERNAME",
    GET_SUPPORTGROUPDETAILS: "GET_SUPPORTGROUPDETAILS",
    UPDATE_GROUPNAME: "UPDATE_GROUPNAME",
    POST_GROUPANDUSERS: "POST_GROUPANDUSERS",
    GET_ALLGROUPS: "GET_ALLGROUPS",
    DELETE_GROUP: "DELETE_GROUP",
    GETUSERS_FORSUPPORTGROUP: "GETUSERS_FORSUPPORTGROUP",
}
