import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import '../../../src/custom.css'
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import Arrow_leftbrown from "../../assets/images/Arrow_leftbrown.svg";
import { Input, Form, FormFeedback } from "reactstrap"
import { Formik, Field, useFormik } from 'formik';
import * as Yup from 'yup';
import * as url from "../../api/uri_helper";
import axios from "axios";
import { fetchAllCompaniesdata, fetchUserCompanyById, fetchSubCompaniesByUserId, fetchUserMenudata , fetchUsersWithRoles , fetchAllSubCompaniesdata } from "../../Redux/Api/fetch.api";
import { getallusers, getusersandroles, getuserroleandmenu } from '../../Redux/Actions/UsersActions';
import { getsubcompanybyuserid, postusercompanydata, updateusercompanydata } from '../../Redux/Actions/UserCompanyAction';
import Layout from '../Layout';
import Loader from "../loader";
import { getallsubcompanydata } from '../../Redux/Actions/CompanyRelationAction';
import { getallcompanies } from '../../Redux/Actions/CompanyActions';
import { Checkbox, Select } from "antd";
import NotAuthorised from "../Authentication/NotAuthorised";
import { useTranslation } from "react-i18next";
const Option = Select.Option;
import Title from "../Title";


const AddUserCompany = () => {
    const { t} = useTranslation('addUserCompany');
    const [loading, setLoading] = useState(false);
    let history = useHistory();
    const dispatch = useDispatch(); 
    const [allUsers, setAllUsers] = useState([]);
   // const [allAdminUsers, setAllAdminUsers] = useState([]);
    const [allSubCompanies, setAllSubCompanies] = useState([]);
    const [userId, setUserId] = useState();
    const [selectSubCompany, setSelectSubCompany] = useState([]);
    const [subcompany, setSubcompany] = useState([]);
    const [checked, setChecked] = useState(true);
    const [isActive, setisActive] = useState(true)
    const [currentId, setCurrentId] = useState(null);
    const [subCompanies, setSubCompanies] = useState([]);
    const loggedUserId = localStorage.getItem('UserId')
    const [comRoleName, setComRoleName] = useState(null);
    const [companyName, setCompanyName] = useState("");
    const [companyId, setCompanyId] = useState();
    const [allCompaniesData, setAllCompaniesData] = useState([]);
    const [isEdit, setIsEdit] = useState(false);


    useEffect(() => {       
       // getusers();    
        getUserDetails();
        getalluserswithroles();       
        getallcompaniesdata();
    }, [])

    //const handleChange = () => {
    //    setisActive(!isActive)
    //}

    const getalluserswithroles = () => {       
        setLoading(true)
        fetchUsersWithRoles({
            UserId: loggedUserId
        })
            .then(response => {               
                dispatch(getusersandroles(response.data))
                setAllUsers(response.data.data)
               
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
        setTimeout(() => {
            setLoading(false)
        }, 4000)
    }

    const getallcompaniesdata = () => {
        setLoading(true)
        fetchAllCompaniesdata()
            .then(response => {
                dispatch(getallcompanies(response.data))
                setAllCompaniesData(response.data.data)
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
        setLoading(false)
    }

    const getUserDetails = () => {    
        if (loggedUserId == null) {
            history.push("/");
        }
        fetchUserMenudata(loggedUserId)
            .then(response => {                
                dispatch(getuserroleandmenu(response.data))              
                setComRoleName(response.data.data.userRoleDetails[0].roleName)              
                if (response.data.data.userRoleDetails[0].roleName == "Admin")
                    setSelectSubCompany(response.data.data.userCompanyDetails[0].companyId)
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }

    const getSubCompanyData = () => {      
        fetchAllSubCompaniesdata()
            .then(response => {              
                dispatch(getallsubcompanydata(response.data))
                setSubCompanies(response.data.data)
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }

    useEffect(() => {
       // getSubCompaniesDetails();
        getSubCompanyData();
    }, [userId]);

    useEffect(() => {
        setLoading(true)
        let urlData = location.pathname.split('/');
        setCurrentId(urlData[3])
        if (currentId) {
            setIsEdit(true);
            setLoading(true)
            fetchUserCompanyById(urlData[3]).then(
                response => {
                    
                    setUserId(response.data.data.userId)
                    { response.data.data.companies ? setSelectSubCompany(getCompany(response.data.data.companies)) : "" }
                    setisActive(response.data.data.isActive)                   
                    setLoading(false)
                })
            setTimeout(() => {
                setLoading(false)
            }, 2000)
        }
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }, [currentId])

    let pageButton = t("Update")

    if (!currentId) {
        pageButton = t("Save")
    }
    
    const getCompany = (usercompany) => {
        let subcompany = [];
        usercompany.forEach(element => {
            let selectedSub = {
                disabled: undefined, key: element.companyId, label: element.companyName,
                value: element.companyId
            };
            subcompany.push(selectedSub);
        });
        return subcompany;
    }
    
    const getSubCompanyIds = (selectedCompanies) => {       
        let subcompany = [];
        selectedCompanies.map(companyItem => {
            return subcompany.push(companyItem.subCompanyId ? companyItem.subCompanyId : companyItem.value)
        })
        return subcompany;
    }

    const addUserCompanyData = async () => {         
        setLoading(true)
        const formData = new FormData();
        formData.append("UserId", userId);
        if (comRoleName == "SuperAdmin") {
            getSubCompanyIds(selectSubCompany).forEach((value) => {
                formData.append('subcompany[]', value);
            });
        }
        else {
            formData.append('subcompany[]', selectSubCompany);
        }
        const response_ = await axios({
            method: 'post',
            url: url.POST_ADDUSERCOMPANY,
            data: formData,
        }).then(response => {           
            if (response.data.success == false) {
                toast.error(t("CompanyAlreadyCreated"), {
                    className: "toastSuccess",
                });
            }
            else {
                dispatch(postusercompanydata(response.data))
                toast.success(t("UserCompanyRelationcreatedsuccessfully"), {
                    className: "toastSuccess",
                });
                history.push('/UserCompany');
                SetImagesErr('')
            }
            setLoading(false)
        })       
    }

    const updateUserCompanyData = async () => {         
        setLoading(true)
        const subIdsToUpdate = getSubCompanyIds(selectSubCompany);       
        const formData = new FormData();
        formData.append("UserId", userId);
        formData.append("IsActive", isActive);
        subIdsToUpdate.forEach((value) => {
            formData.append('subcompany[]', value);
        });
        const response_ = await axios({
            method: 'put',
            url: url.UPDATE_USER_COMPANY,
            data: formData,
        }).then(response => {           
            if (response.data.success == false) {
                toast.error(t("CompanyAlreadyExists"), {
                    className: "toastSuccess",
                });
            }
            else {
                dispatch(updateusercompanydata(response.data))
                toast.success(t("UserCompanysuccessfullyupdated"), {
                    className: "toastSuccess",
                });
                history.push('/UserCompany');
                SetImagesErr('')
            }
            setLoading(false)
        })       
    }

    const handleChangeSubCompany = (selected) => {
       
        const arr1 = []
        selected.map((item, i) => {
            subCompanies.filter(datas => datas.subCompanyName === selected[i].label).map(filter_data => {
                arr1.push(filter_data)
            })
        })
        setSubcompany(arr1)
        setSelectSubCompany(selected)
       
    }

    return (
        <div>
            <Title title={`${isEdit ? "Edit" : "Add"} User Company`} />
            {comRoleName == null ? <Loader /> : comRoleName && comRoleName != "SuperAdmin" && comRoleName && comRoleName != "Developer"
                && comRoleName && comRoleName != "Admin" ? <NotAuthorised></NotAuthorised> :
                <Layout>
                    <section className="User_section common ">
                        <Toaster />
                        {loading ? <Loader /> : ''}
                        <div className="container">
                            <div className="mt-4 w-100 position-relative wk-select">
                                <h4 className="m-0">
                                    <Link className="backBTN" to="/UserCompany">
                                        <img src={Arrow_leftbrown}></img>
                                        {t("Back")}
                                    </Link>
                                </h4>

                            </div>
                            <div className="content-area">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-md-5">
                                            <label>{t("User")} <span className="required-fields"> * </span></label>
                                            <div className="form-group wk-select filter-wrap">
                                               
                                                <select className="form-select mb-0 flex-grow-1 filter-select" name="users" aria-label=".form-select-lg example"
                                                    value={userId}
                                                    key={userId}
                                                    onChange={(e) => setUserId(e.target.value)}
                                                >
                                                    <option value="0">{t("SelectUser")}</option>
                                                    {
                                                        allUsers && allUsers.map(user =>
                                                            <option key={user.userId} value={user.userId}>
                                                                {user.lastName + " " + user.firstName} </option>
                                                        )
                                                    }
                                                </select>

                                            </div>
                                        </div>
                                        {comRoleName == "SuperAdmin" ?
                                            <div className="col-md-7 select-Company comdropdown filter-wrap form-group">
                                                <label>{t("SubCompany")} <span className="required-fields"> * </span></label>
                                                <Select className="company-drop"
                                                    placeholder={t("SelectSubcompany")}
                                                    mode="multiple"
                                                    labelInValue
                                                    tokenSeparators={[" ", ","]}
                                                    value={selectSubCompany}
                                                    onChange={handleChangeSubCompany}
                                                    style={{ width: '100%' }}
                                                >
                                                    {/* <option disabled value="0">Select Roles</option> */}
                                                    {subCompanies && subCompanies.map(sub =>
                                                        <Option key={sub.subCompanyId} value={sub.subCompanyId} >
                                                            {sub.subCompanyName}
                                                        </Option>
                                                    )}
                                                </Select>

                                            </div>
                                            :
                                            <div className="col-md-4">
                                                <div className="form-group wk-select filter-wrap">
                                                    <label>{t("SubCompany")} <span className="required-fields"> * </span></label>
                                                    <select className="form-select mb-0 flex-grow-1 filter-select" name="roles" aria-label=".form-select-lg example"
                                                        value={selectSubCompany}
                                                        key={selectSubCompany}
                                                        disabled
                                                    // onChange={handleChangeSubCompany}

                                                    >
                                                        <option selected="">{t("SelectCompany")}</option>
                                                        {
                                                            allCompaniesData && allCompaniesData.map(company =>
                                                                <option key={company.companyId} value={company.companyId}>
                                                                    {company.companyName} </option>
                                                            )
                                                        }

                                                    </select>

                                                </div>
                                            </div>
                                        }


                                        <div className="col-md-12 mt-4">
                                            {comRoleName == "SuperAdmin" ?
                                                userId && userId != "Select User" && selectSubCompany && selectSubCompany.length > 0 ? <button className="btn btn-primary"
                                                    onClick={currentId == undefined || currentId == null || currentId == isNaN ? addUserCompanyData : updateUserCompanyData}>{pageButton}</button>
                                                    : <button className="btn btn-primary" disabled
                                                        onClick={currentId == undefined || currentId == null || currentId == isNaN ? addUserCompanyData : updateUserCompanyData}>{pageButton}</button> :
                                                userId && userId != "Select User" && selectSubCompany ? <button className="btn btn-primary"
                                                    onClick={currentId == undefined || currentId == null || currentId == isNaN ? addUserCompanyData : updateUserCompanyData}>{pageButton}</button>
                                                    : <button className="btn btn-primary" disabled
                                                        onClick={currentId == undefined || currentId == null || currentId == isNaN ? addUserCompanyData : updateUserCompanyData}>{pageButton}</button>
                                            }
                                            <Link className="btn btn-bordered ml-2" to="/UserCompany">{t("Cancel")}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Layout>
            }
        </div>
    )

}
export default connect()(AddUserCompany);
