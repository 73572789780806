import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { connect } from "react-redux";
import { Table } from "antd"; // Import the Table component from Ant Design
import Layout from '../Layout';
import '../../../src/custom.css';
import { getUserAccessLevels } from "../../Redux/Api/fetch.api";
import CloseIcon from '@mui/icons-material/Close';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useTranslation } from "react-i18next";
import Title from "../Title";

const UserAccessLevel = () => {

    const { t } = useTranslation('accessLevel');
    const { userId } = useParams();
    const [userAccessLevels, setUserAccessLevels] = useState({});
    const [userDetails, setUserDetails] = useState({});
    const [availableRoles, setAvailableRoles] = useState([]); // Store available roles dynamically

    useEffect(() => {
        if (userId) {
            GetUserAccessLevels(userId);
        }
    }, [userId]);

    const GetUserAccessLevels = async (userId) => {
        try {
            const response = await getUserAccessLevels(userId);

            if (response?.data?.data) {
                const flattenedData = response.data.data.menuAccessView.map((item, index) => ({
                    key: index.toString(),
                    appName: item.appName,
                    menuTitle: item.menuTitle,
                    ...Object.fromEntries(
                        Object.entries(item.accessRights).map(([role, value]) => [
                            role,
                            <div className={`user-access-level-icon-circle ${value === "Tick" ? 'user-access-level-icon-tick' : 'user-access-level-icon-cross'}`}>
                                {value === "Tick" ? <DoneAllIcon /> : <CloseIcon />}
                            </div>
                        ])
                    )
                }));

                setUserDetails(response?.data?.data?.accessedUserDetails);

                // Identify all unique roles that are present in the data
                const allRoles = Array.from(new Set(flattenedData.flatMap(item => Object.keys(item).filter(key => key !== 'key' && key !== 'appName' && key !== 'menuTitle'))));
                setAvailableRoles(allRoles);

                const groupedData = flattenedData.reduce((acc, curr) => {
                    if (!acc[curr.appName]) acc[curr.appName] = [];
                    acc[curr.appName].push(curr);
                    return acc;
                }, {});

                setUserAccessLevels(groupedData);
            }
        } catch (error) {
            console.error("Error fetching user access levels:", error);
        }
    };

    // Dynamically build columns based on available roles
    const columns = [
        { title: t('MenuTitle'), dataIndex: 'menuTitle', key: 'menuTitle', align: 'center' },
        ...availableRoles.map(role => ({
            title: t(role),
            dataIndex: role,
            key: role,
            align: 'center'
        }))
    ];

    return (
        <div>
            <Title title="User Access Level"></Title>
            <Layout>
                <div className="p-3">
                    <section className="Company_section common common-table py-3">
                        <div className="container-fluid">
                            <div className="row">
                                {userDetails && userDetails.userName && userDetails.roleName && (
                                    <>
                                        <div className="col-md-4 support_log_head">
                                            <h4 className="m-0">{t("UserAccessLevel")}</h4>
                                        </div>
                                        <div className="col-md-4">
                                            <h6 className="m-0 d-flex align-items-center justify-content-center user-access-level-userdetails">
                                                <AccountCircleIcon style={{ marginRight: '8px' }} />
                                                {userDetails.userName} ({userDetails.roleName})
                                            </h6>
                                        </div>
                                        <div className="col-md-4">
                                            <p className="ml-md-auto mb-0"></p>
                                        </div>
                                    </>
                                )}
                            </div>

                            <div className="container">
                                <div className="row mt-5">
                         
                                {Object.keys(userAccessLevels).map((appName, index) => (
                                    <div key={index} className="mb-5 col-md-6 col-sm-12">
                                        <h3 className="text-left">{appName}</h3>
                                        <div className="Viewer-table index--table position-relative support-row-tb common-table mb-2">
                                            <Table
                                                className='table-responsive antd--detail-table1 support assgin-table'
                                                columns={columns}
                                                dataSource={userAccessLevels[appName]}
                                                pagination={false}
                                                bordered
                                            />
                                        </div>
                                    </div>
                                ))}
                                    
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Layout>
        </div>
    );
}

export default connect()(UserAccessLevel);
