import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import '../../../src/custom.css'
import Header from '../PageLayout/Header';
import Footer from '../PageLayout/Footer';
import Edit_light from "../../assets/images/Edit_light.svg";
import { Select } from 'antd';
import Loader from "../loader";
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import { fetchAllSites, fetchAllTEKaController, fetchAllSiteData } from "../../Redux/Api/fetch.api";
import axios from "axios";
import * as url from "../../api/uri_helper";
import Table, { TableProps } from "antd/lib/table";
import Trash_light from "../../assets/images/Trash_light.svg";
import { Modal } from 'antd';
import Add from "../../assets/images/Add.svg";
import Layout from '../Layout';
import { Tooltip } from 'antd';
import { getallsites, deletesitesdetails } from '../../Redux/Actions/SiteActions';
import NotAuthorised from "../Authentication/NotAuthorised";
import { fetchUserMenudata } from "../../Redux/Api/fetch.api";
import { getuserroleandmenu } from '../../Redux/Actions/UsersActions';
import { useTranslation } from "react-i18next";
import Title from "../Title";

const TekacontrollerIndex = () => {
    const { t } = useTranslation('tekaController');
    //const alldataTypes = useSelector((state) => state.allUserdata.users);
    //const roleName = alldataTypes && alldataTypes.data && alldataTypes.data.userRoleDetails[0].roleName;

    const { REACT_APP_DEFAULT_PAGESIZE } = process.env;
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [table, setTable] = useState([]);
    const [pageSize, setPageSize] = useState(REACT_APP_DEFAULT_PAGESIZE)
    const [options, setOptions] = useState({ pagination: { current: 1 }, pageSize: pageSize });
    const [totalIndex, setCurrentIndex] = useState()
    const [pageData, setPageData] = useState({});
    const [total, setTotal] = useState()
    let history = useHistory();
    const loggedUserId = localStorage.getItem('UserId')
    const [roleName, setRoleName] = useState(null);
    const [userRoleData, setUserRoleData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(5)
    const showPagination = totalCount > REACT_APP_DEFAULT_PAGESIZE;
    const [dropdownChange, setDropdownChange] = useState(false);
    const [description, setDescription] = useState("")
    const [siteId, setSiteId] = useState(null)
    const [allSites, setAllSitesData] = useState([])
    const [refreshData, setRefreshData] = useState(false);

    //useEffect(() => {
    //    debugger;
    //    getUserDetails();
    //}, []);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2000)
        getUserDetails();
       // getAllTEKaControllers();
        getAllSitesData();
    }, []);

    useEffect(() => {
        getAllTEKaControllers();
    }, [refreshData]);



    const getAllTEKaControllers = (pageindex = 1, pagesize = REACT_APP_DEFAULT_PAGESIZE) => {

        setLoading(true)
        fetchAllTEKaController({
            PageIndex: pageindex,
            PageSize: pageSize > REACT_APP_DEFAULT_PAGESIZE ? pageSize : pagesize,
            Description: description,
            SiteId: siteId ? siteId : 0
        })
            .then(response => {
                //    dispatch(getallworkitems(response.data))
                console.log(response)
                setTable(response.data.data)
                setTotalCount(response.data.count);
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
        handleCancel();
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    }

    const getUserDetails = () => {
        fetchUserMenudata(loggedUserId)
            .then(response => {
                dispatch(getuserroleandmenu(response.data.data))
                setUserRoleData(response.data.data.userRoleDetails);
                if (response.data.data.userRoleDetails[0].roleName)
                    setRoleName(response.data.data.userRoleDetails[0].roleName);
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }

    const getAllSitesData = () => {
        setLoading(true)
        fetchAllSiteData()
            .then(response => {
                //dispatch(getallcompanies(response.data))
                setAllSitesData(response.data.data)
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
        setLoading(false)
    }




    const { confirm } = Modal;
    const handleDelete = (tekaID) => {
        confirm({
            title: "Areyousureyouwanttodeletethisrecord",
            okText: "Yes",
            okType: 'danger',
            cancelText: "No",
            onOk() {
                deleteTEKaControllerRecord(tekaID)
            },
            onCancel() {
            },
        });
    }

    const handlePageChange = (page, newPageSize) => {
        setCurrentPage(page);
        setPageSize(newPageSize);
        getAllTEKaControllers(page, newPageSize)
    };

    const SearchControl = async () => {
       
        getAllTEKaControllers();
    }

    const handleCancel = () => {
        setDropdownChange(true);
        setTimeout(() => {
            setDropdownChange(false);
        }, 0);
    };

    const handleSearchClear = () => {
        
        setSiteId(null);
        setDescription('');
        setRefreshData(prev => !prev);
        handleCancel();
    };

    const deleteTEKaControllerRecord = async (tekaID) => {
        
        setLoading(true)
        await axios.delete(url.DELETE_TEKACONTROLLER + '/' + tekaID, {
        }).then(response => {
            
            //dispatch(deletesitesdetails(response.data))
            setTimeout(() => {
                setLoading(false)
            }, 1000)
            toast.success(t("TEKaControllerDeletedSuccessfully"), {
                className: "toastSuccess",
            },);
            getAllTEKaControllers();

        }).catch((err) => {
            console.log("Err: ", err);
        });
        setLoading(false)
    }
    const handleEdit = (tekaId) => {
        history.push('/Tekacontroller/edit/' + tekaId)
    }

    const handleChange = (pagination, sorter, current) => {
        const updatedOptions = { ...options, pagination };
        setOptions(updatedOptions);
        setPageData(updatedOptions)
    };

    const bindProject = (userRoles) => {
        return userRoles.map(x => x.name).join(", ");
    }

    const getFullDate = (date) => {
        const newDate = new Date(date);
        const returnDate = ((newDate.getMonth() > 8) ? (newDate.getMonth() + 1) : ('0' + (newDate.getMonth() + 1))) + '-' + ((newDate.getDate() > 9) ? newDate.getDate() : ('0' + newDate.getDate())) + '-' + newDate.getFullYear();
        const dateAndTime = date.split("T");
        const time = dateAndTime[1].split(":");
        const newtime = time[0] + " : " + time[1]
        const times = time[0] > 12 ? time[0] % 12 : time[0];
        const timeslatest = times < 9 ? ('0' + times) : times;
        const newtime1 = timeslatest + " : " + time[1]
        if (time[0] && time[0] >= 12) {
            return (
                //dateAndTime[0].split("-").reverse().join("-") + ", " + newtime1 + " PM "
                returnDate + ", " + newtime1 + " PM "
            );
        } else {
            return (
                //dateAndTime[0].split("-").reverse().join("-") + ", " + newtime + " AM "
                returnDate + ", " + newtime + " AM "
            );
        }
    };

    const columns = [
        {
            title: t("SrNo"),
            dataIndex: "",
            render: (value, item, index) => (currentPage - 1) * pageSize + index + 1,
        },

        {
            title: t("TEKaID"),
            dataIndex: "teKaID",
        },

        {
            title: t("SiteName"),
            dataIndex: "siteName",
        },

        {
            title: t("Description"),
            dataIndex: "description",
            className: "description",
            render: (description) => <Tooltip title={description}>{description}</Tooltip>,
        },

        {
            title: t("Status"),
            dataIndex: "isActive",
            render: (data) => (data ? <span> Active</span> : <span>Inactive </span>),

        },

        {
            title: t("CreatedDate"),
            dataIndex: "created",
            render: (created) => created ? getFullDate(created) : '',
        },

        {
            title: t("UpdatedDate"),
            dataIndex: "updated",
            render: (updated) => updated ? getFullDate(updated) : '',
        },

        {
            title: t("Action"),
            className: "sitebuttons",
            dataIndex: "",
            render: (data, tekaID) => (<>
                <ul className="list-inline">
                    <li className="list-inline-item mr-0">
                <button className="table-btn" >
                <img src={Edit_light} onClick={() => handleEdit(data.teKaID)}></img>
                        </button>
                    </li>
                    <li className="list-inline-item mr-0">
                <button className="table-btn" >
                    <img src={Trash_light} onClick={() => handleDelete(data.teKaID)}></img>
                        </button>
                    </li>
                    </ul>
                </>)
        },
    ]
    return (
        <div>
            <Title title="TekaController"></Title>
            {roleName == null ? <Loader /> : roleName && roleName != "SuperAdmin" && roleName && roleName != "Developer" ? <NotAuthorised></NotAuthorised> :
                <>
                    {loading ? <Loader /> : ''}
                    <Layout>
                        <section className="Company_section common ">
                            <Toaster />
                            <div className="container custom-container">
                                <div className="mt-4 w-100 position-relative tekacontroller-filter wk-select">
                                    <div className="tekacontroller-head d-flex">
                                        <h4 className="m-0">{t("TEKaController")}</h4>
                                        <div className="dropdown flex1">
                                            <button
                                                className={[
                                                    "filter-btn dropdown-toggle btn d-flex align-items-center",
                                                    dropdownChange === true ? "hide" : "",
                                                ].join(" ")}
                                                type="button"
                                                id="dropdownFilterMenuButton1"
                                                data-bs-toggle="dropdown"
                                                data-bs-auto-close="false"
                                                aria-expanded="false"
                                            >
                                                <span>
                                                    <img src={'https://tekalogbeta.azurewebsites.net/static/media/search-ico-lite.c9c235ab7706d6c030741d0f56f56cc4.svg'} ></img> {t("Filter")}
                                                </span>
                                            </button>
                                            <ul
                                                className={[
                                                    "dropdown-menu entries-dropdown-search",
                                                    dropdownChange === true ? "hide" : "",
                                                ].join(" ")}
                                                aria-labelledby="dropdownMenuButton1"
                                            >
                                                <div className="row g-3">
                                                    <div className="col-md-6">
                                                        <div className="search--tracking-col">
                                                            <label class="form-label">{t("Description")}</label>
                                                            <input
                                                                className="form-control"
                                                                placeholder={t("EnterDescription")}
                                                                type="text"
                                                                value={description}
                                                                onChange={(e) => {
                                                                    const inputText = e.target.value;
                                                                    setDescription(inputText);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label> {t("Site")} </label>
                                                            <select className="form-select mb-0 flex-grow-1 filter-select wk-select" name="roles" aria-label=".form-select-lg example"
                                                                value={siteId}
                                                                key={siteId}

                                                                onChange={(e) => {
                                                                    // setAppId(e.target.value)
                                                                    if (e.target.value == "Select Site") {
                                                                        setSiteId(null)

                                                                    }
                                                                    else {
                                                                        setSiteId(e.target.value)
                                                                    }

                                                                }}
                                                            >
                                                                <option selected="">{t("SelectSite")}</option>
                                                                {
                                                                    allSites && allSites.map(site =>
                                                                        <option key={site.siteId} value={site.siteId}>
                                                                            {site.siteName} </option>
                                                                    )
                                                                }
                                                            </select>

                                                        </div>
                                                    </div>






                                                    <div className="col-md-12 d-flex justify-content-end">
                                                        {description != "" || siteId != null ?
                                                            <button
                                                                className="btn btn-primary ms-auto "
                                                                onClick={SearchControl}
                                                            >
                                                                {t("Search")}
                                                            </button> :

                                                            <button
                                                                className="btn btn-primary ms-auto "
                                                                onClick={SearchControl}
                                                                disabled
                                                            >
                                                                {t("Search")}
                                                            </button>
                                                        }
                                                        <button
                                                            className="btn btn-bordered pe-3 ps-3 ms-2"
                                                            onClick={handleCancel}
                                                        >
                                                            {t("Cancel")}
                                                        </button>
                                                        <button
                                                            className="btn btn-bordered pe-3 ps-3 ms-2"
                                                            onClick={handleSearchClear}
                                                        >
                                                            {t("Reset")}
                                                        </button>
                                                    </div>
                                                </div>
                                            </ul>
                                        </div>
                                    </div>                                    
                                    <div className="ml-auto">
                                        <Link className="btn btn-primary d-flex align-items-center btn-new" to="/Tekacontroller/Add"><img src={Add}></img><span>{t("AddTEKaController")}</span></Link>
                                    </div>
                                </div>
                                <div className="Viewer-table index--table position-relative common-table mb-4">

                                    <Table
                                        className='table-responsive antd--detail-table1'
                                        columns={columns}
                                        dataSource={table}
                                        pagination={showPagination ? {
                                            total: totalCount,
                                            pageSize: pageSize,
                                            current: currentPage,
                                            showSizeChanger: true,
                                            onChange: handlePageChange,
                                            pageSizeOptions: ["50", "100"],
                                        } : false}

                                    />
                                </div>
                            </div>
                        </section>
                    </Layout >
                </>


            }

        </div>


    )
}
export default connect()(TekacontrollerIndex);

