import React, { useState } from 'react';
import '../../../src/Subscription.css';
import Layout from "../Layout";

const Subscription = () => {
    return (
        <>
            <Layout>
                <div className="subscription-page">
                    <h1 className="title">Support Subscription</h1>
                    <p className="subtitle"> A New Lease on Support : Solutions Tailored to Your Needs</p>
                    <p className="description">
                        A Support Subscription Is Required to Access Private Support.

                        Gain priority access to our expert team, dedicated to providing personalized assistance and ensuring your complete satisfaction!
                    </p>

                    <div className="plans">
                        <div className="plan best-package">
                            <h3>BEST PACKAGE</h3>
                            <h2>PREMIUM</h2>
                            {/*  <p className="price">SAVE 72%***</p>*/}
                            {/* <p className="monthly-cost">$9.99 <span>monthly</span></p>*/}
                            {/*<p className="billed">billed at $59.94**</p>*/}
                            <p className="term">6 MONTHS minimum</p>
                            <p className="access">FULL SUPPORT* on request </p>
                            <ul>
                                <li>Provide Support Technician </li>
                                <li>Quick Response</li>
                                <li>SMS Notification </li>
                                <li>Email Notification </li>
                                <li>Call Support </li>
                            </ul>
                            <button className="select-button">Select</button>
                        </div>

                        <div className="plan">
                            <h2>STANDARD</h2>
                            {/* <p className="price">SAVE 43%***</p>*/}
                            {/* <p className="monthly-cost">$19.99 <span>monthly</span></p>*/}
                            {/* <p className="billed">billed at $39.98**</p>*/}
                            <p className="term">2 MONTHS minimum</p>
                            <p className="access">FULL SUPPORT* on request </p>
                            <ul>
                                <li>Provide Support Technician </li>
                                <li>Quick Response</li>
                                <li>SMS Notification </li>
                                <li>Email Notification </li>
                            </ul>
                            <button className="select-button">Select</button>
                        </div>

                        <div className="plan">
                            <h2>BASIC</h2>
                            {/* <p className="monthly-cost">$34.99 <span>monthly</span></p>*/}
                            {/*<p className="billed">billed at $34.99**</p>*/}
                            <p className="term">1 MONTH minimum</p>
                            <p className="access">FULL ACCESS* on request</p>
                            <ul>
                                <li>Provide Support Technician </li>
                                <li>SMS Notification </li>
                                <li>Email Notification </li>
                            </ul>
                            <button className="select-button">Select</button>
                        </div>

                        <div className="plan selected-plan">
                            <h2>BROWSER</h2>
                            <p className="monthly-cost">Free</p>
                            <p className="access">LIMITED SUPPORT* on request</p>
                            <ul>
                                <li>Request Only</li>
                            </ul>
                            <button className="select-button">Select</button>
                        </div>
                    </div>

                    <div className="footer">
                        <p>Subscriptions and Renewals are NON-REFUNDABLE</p>
                        <p>To Subscribe by Phone: <span className="phone-number">1 (800) 770-0207</span> | Phone Hours: Monday to Friday 9 am - 6 pm EST</p>
                    </div>
                </div>
            </Layout>
        </>

    );
}
export default Subscription;

