
import React, { useEffect, useState } from 'react';
import Footerbanner from '../../assets/images/banner.jpeg';
import Contact from "../Dashboard/Contact";
import { connect, useDispatch, useSelector } from 'react-redux';
import '../../../src/custom.css'
import { Link, NavLink } from 'react-router-dom';
import Logo from '../../assets/images/authenteklogo.png';
import { useTranslation } from "react-i18next";

const Footer = () => {
    const { t } = useTranslation('footer');
    const allUserTypes = useSelector((state) => state.allUserdata.users);
    const colorcode = allUserTypes.data && allUserTypes.data.colorCodeDetails;
    const resellercompanydetails = allUserTypes.data && allUserTypes.data.resellerCompanyDetails;
    const resellercompany = resellercompanydetails && resellercompanydetails.resellerCompanyLogo;   
    const footercolor = colorcode && colorcode.footerColorCode;   
    const loggedInUserId = localStorage.getItem('UserId')

	
    return (
        <footer>
            <div className="footer-section pb-3 pt-3 w-100"
                style={{
                    backgroundColor: footercolor,
                }}
            >
				<div className="container-fluid">
                    <div className="row">
                        {resellercompanydetails != null ?
                            <div className="col-sm-4 text-left">
                                <div className="footer--logo">{t("Providedby")} <img src={resellercompany}></img> </div>
                            </div> : ""
                        }
                        <div className={resellercompanydetails == null ? "col-sm-6 text-left" : "col-sm-4 text-center"}>
                            &#169; {t("AuthenTEK")} ({new Date().getFullYear()}) | {t("TEKaVerse")} &#8482;
                        </div>
                        <div className={resellercompanydetails == null ? "col-sm-6 text-right" : "col-sm-4 text-right"}>
                            <div className="footer--logo">
                                {t("Poweredby")} <a href="https://authentek.io/" target="_blank">
                                    <img src={Logo} alt="AuthenTEK Logo"></img>
                                </a>
                            </div>
                        </div>					
					</div>
				</div>
			</div>
        </footer>
    );
};

export default Footer;
