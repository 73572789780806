import { useEffect } from "react";
import { Helmet } from "react-helmet-async";


const Title = ({ title }) => {

    return (
        <div>
            <Helmet>
                <title>TEKaVerse | {`${title}`}</title>
            </Helmet>
        </div>
    );

}
export default Title;