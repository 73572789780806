import React, { useState, useEffect } from 'react';

const Clock = (props) => {
   
    const { timeZoneName, currentUtcOffset, isDST } = props;
    const offsetTimeZone = timeZoneName ? timeZoneName : "Eastern";

    const parseUtcOffset = (offset) => {
        if (!offset) {
            return -5; // Default offset, adjust as needed
        }

        const [hours, minutes] = offset.split(':').map(Number);

        // If there are both hours and minutes, calculate the total offset
        if (!isNaN(hours) && !isNaN(minutes)) {
            const totalOffset = hours + minutes / 60;
            return totalOffset;
        }

        // If only hours are provided
        if (!isNaN(hours)) {
            return hours;
        }

        // If the offset cannot be parsed, return the default
        return -5; // Default offset, adjust as needed
    };


    const getFormattedTime = () => {
        const now = new Date();
        const offsetHours = parseUtcOffset(currentUtcOffset);

        if (isDST) {
            now.setUTCHours(now.getUTCHours() + Math.floor(offsetHours) + 1);
        } else {
            now.setUTCHours(now.getUTCHours() + Math.floor(offsetHours));
        }
        const remainingMinutes = (offsetHours % 1) * 60;
        now.setUTCMinutes(now.getUTCMinutes() + remainingMinutes);

        const formattedDate = now.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            timeZone: 'UTC'
        });

        const formattedTime = now.toLocaleTimeString('en-US', {
            hour12: true,
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: 'UTC'
        });

        return `${formattedDate} ${formattedTime}`;
    };
    const [currentTime, setCurrentTime] = useState(getFormattedTime());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(getFormattedTime());
        }, 1000);

        return () => clearInterval(intervalId);
    }, [currentUtcOffset]);

    return (
        <>EST: {currentTime}
            {offsetTimeZone ? <>({offsetTimeZone})
            </> : ''}
        </>
    );
};

export default Clock;
