import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import Layout from "../Layout";
import Table from "antd/lib/table";
//import Loader from "../loader";
//import CircleIcon from '@mui/icons-material/Circle';
//import { getallsubcompanydata } from '../../Redux/Actions/CompanyRelationAction';
//import { fetchAllWorkItemsdata, fetchAllWorkdata, fetchProjectDetails, fetchUserMenudata, fetchAllDevelopers, fetchAllSubCompaniesdata } from "../../Redux/Api/fetch.api";
import { getallprojects, getAllDevs } from "../../Redux/Actions/AccessibilityActions";
import Add from "../../assets/images/Add.svg";
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import SmsIcon from '@mui/icons-material/Sms';
import BugReportIcon from '@mui/icons-material/BugReport';
import TaskIcon from '@mui/icons-material/Task';
import FilterListIcon from '@mui/icons-material/FilterList';
import Edit_light from "../../assets/images/Edit_light.svg";
import Bookmark from "../../assets/images/Bookmark.png";
import Remove from "../../assets/images/Remove.png";
//import Reset from "../../assets/images/reset.png";
import { getallworkitems, removeworkitemsearch, getworkcategory } from '../../Redux/Actions/WorkItemAction';
import { fetchAllWorkItemsdata } from "../../Redux/Api/fetch.api";
import searchIcon from "../../assets/images/SearchIcon.svg";
import { Tooltip } from 'antd';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import * as url from "../../api/uri_helper"
import { Modal, Skeleton } from 'antd';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import UpdateIcon from '@mui/icons-material/Update';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { useTranslation } from "react-i18next";
import Title from "../Title";

const Assign = () => {
    const { t} = useTranslation('workItem');
    const { REACT_APP_DEFAULT_PAGESIZE } = process.env;
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [isSavedSearch, setisSavedSearch] = useState(false);
    const [isShowRemove, setIsShowRemove] = useState(false);
    const [isResetpage, setIsResetPage] = useState(false);
    const [table, setTable] = useState([]);
    const [allProjects, setAllProjects] = useState([])
    const [pageSize, setPagesize] = useState(REACT_APP_DEFAULT_PAGESIZE)
    const [subCompanies, setSubCompanies] = useState([]);
    const [categories, setCategories] = useState([]);
    const [searchdata, setSearchData] = useState([]);
    const [worktypedata, setWorkTypeData] = useState([]);
    const [workstatusdata, setWorkStatusData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState()
    const [workTitle, setWorkTitle] = useState("")
    const [workItem, setWorkItem] = useState("")
    const [isChecked, setIsChecked] = useState(false)
    const [workItemid, setWorkItemId] = useState(0)
    const [checkedListOfCategories, setCheckedListOfCategories] = useState([]);
    const [checkedListOfIds, setCheckedListOfIds] = useState([]);
    const [checkedListOfComp, setCheckedListOfComp] = useState([]);
    const [checkedListOfType, setCheckedListOfType] = useState([]);
    const [checkedListOfStatus, setCheckedListOfStatus] = useState([]);
    const [checkedListOfAssign, setCheckedListOfAssign] = useState([]);
    const [allDevs, setAllDevs] = useState([])
    let history = useHistory();
    const loggedInUserId = localStorage.getItem('UserId')
    const showPagination = totalCount > REACT_APP_DEFAULT_PAGESIZE;
    const { REACT_APP_WORLITEM_LINK } = process.env;
    const allUserTypes = useSelector((state) => state.allUserdata.users);
    const userRoledata = allUserTypes.data && allUserTypes.data.userRoleDetails;
    const userRoleId = userRoledata && userRoledata[0].roleId;
    const userRole = userRoledata && userRoledata[0].roleName;
    const [companyId, setCompanyId] = useState(0);
    const [searchCompany, setSearchCompany] = useState('');


    useEffect(() => {
        document.body.style.overflow = loading ? 'hidden' : 'auto';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [loading]);

    useEffect(() => {
        if (!isResetpage) {
            getAllWorkItemsData();
        }
    }, [checkedListOfIds, checkedListOfComp, checkedListOfType, checkedListOfStatus, checkedListOfAssign, checkedListOfCategories])

    const SaveWorkItemSearch = () => {

        const requestData = {
            WorkItem: workItem,
            //WorkItemId: workItemid == "" ? 0 : workItemid,
            //WorkTitle: workTitle == null ? "" : workTitle,
            ProjectId: checkedListOfIds,
            WorkType: checkedListOfType,
            Company: checkedListOfComp,
            WorkStatus: checkedListOfStatus,
            AssignIds: checkedListOfAssign,
            CategoryIds: checkedListOfCategories
        };

        var formData = new FormData();
        formData.append('UserId', loggedInUserId);
        formData.append('SearchJson', JSON.stringify(requestData));

        const response_ = axios({
            method: 'post',
            url: url.ADD_WORKITEMSEARCH,
            data: formData
        }).then(response => {
            setLoading(false);
            setisSavedSearch(true)
            toast.success(t("WorkItemSearchSaved"), {
                className: "toastSuccess",
            });
        })

    }

    const handleCompanyChange = (e) => {
        setSearchCompany(e.target.value);
    };

    const filteredCompany = subCompanies.filter(site =>
        site.subCompanyName.toLowerCase().includes(searchCompany.toLowerCase())
    );

    const DeleteWorkItemSearch = () => {
        setLoading(true)
        setIsResetPage(true)
        axios.delete(url.REMOVE_WORKITEMSEARCH + '/' + loggedInUserId, {
        }).then(response => {
            if (response.data.success == false) {
                toast.error(t("NoWorkItemSearchSaved"), {
                    className: "toastSuccess",
                });
            }
            else {
                setLoading(true)
                dispatch(removeworkitemsearch(response.data))
                getAllWorkItemsData(true);
                uncheckcheckboxprojects();
                uncheckcheckboxcompany();
                uncheckcheckbox();
                uncheckcheckboxAssign();
                uncheckcheckboxstatus();
                uncheckcategory();
                setWorkItem("");
                toast.success(t("WorkItemSearchRemoved"), {
                    className: "toastSuccess",
                });
            }

        }).catch((err) => {
            console.log("Err: ", err);
        });
        // setLoading(false);         
    }

    const getAllWorkItemsData = (isReset = false, pageindex = 1, pagesize = REACT_APP_DEFAULT_PAGESIZE, isOnPageLoad = true) => {

        setLoading(true)
        fetchAllWorkItemsdata({
            PageIndex: pageindex,
            PageSize: pageSize > REACT_APP_DEFAULT_PAGESIZE ? pageSize : pagesize,
            UserId: loggedInUserId,
            WorkItem: isReset ? "" : workItem,
            // WorkItemId: isReset || workItemid == "" ? 0 : workItemid,
            // WorkTitle: isReset ? "" : workTitle == null ? "" : workTitle,
            ProjectId: isReset ? [] : checkedListOfIds,
            WorkType: isReset ? [] : checkedListOfType,
            Company: isReset ? [] : checkedListOfComp,
            WorkStatus: isReset ? [] : checkedListOfStatus,
            AssignIds: isReset ? [] : checkedListOfAssign,
            CategoryIds: isReset ? [] : checkedListOfCategories,
            isOnPageLoad: isReset || isChecked || workItemid > 0 || workTitle != "" || workItem != "" || checkedListOfIds.length > 0 || checkedListOfType.length > 0
                || checkedListOfComp.length > 0 || checkedListOfStatus > 0 || checkedListOfAssign > 0 ? false : true
            //IsOnPageLoad: isOnPageLoad
        })
            .then(response => {

                dispatch(getallworkitems(response.data))
                setTable(response.data.data)
                setTotalCount(response.data.count);
                setAllProjects(response.data.searchItemDto.allApps);
                setSubCompanies(response.data.searchItemDto.allCompanies);
                setCategories(response.data.searchItemDto.allCategory)
                if (response.data.searchItemDto.company && response.data.searchItemDto.company.length > 0) {
                    setIsResetPage(true);
                    setCheckedListOfComp(response.data.searchItemDto.company)
                    setIsResetPage(false);
                }
                if (response.data.searchItemDto.categoryIds && response.data.searchItemDto.categoryIds.length > 0) {
                    setIsResetPage(true);
                    setCheckedListOfCategories(response.data.searchItemDto.categoryIds)
                    setIsResetPage(false);
                }
                if (response.data.searchItemDto.assignIds && response.data.searchItemDto.assignIds.length > 0) {
                    setIsResetPage(true);
                    setCheckedListOfAssign(response.data.searchItemDto.assignIds)
                    setIsResetPage(false);
                }

                if (response.data.searchItemDto.projectId && response.data.searchItemDto.projectId.length > 0) {
                    setIsResetPage(true);
                    setCheckedListOfIds(response.data.searchItemDto.projectId)
                    setIsResetPage(false);
                }


                setAllDevs(response.data.searchItemDto.allDevelopers);
                setisSavedSearch(response.data.searchItemDto.isSavedSearch);
                setIsShowRemove(response.data.searchItemDto.showRemove)
                setLoading(false)
                setIsResetPage(false);
                setWorkItem(response.data.searchItemDto.workItem);
                if (response.data.searchItemDto.workType && response.data.searchItemDto.workType.length > 0) {
                    setIsResetPage(true);
                    response.data.searchItemDto.workType.forEach(workType => {
                        Worktypedata.forEach(item => {
                            if (item.id === workType) {
                                item.ischecked = true;
                            }
                        });
                        setCheckedListOfType(response.data.searchItemDto.workType)
                        setIsResetPage(false);
                    });

                }
                setWorkTypeData([...Worktypedata]);

                if (response.data.searchItemDto.workStatus && response.data.searchItemDto.workStatus.length > 0) {
                    setIsResetPage(true);
                    response.data.searchItemDto.workStatus.forEach(workType => {
                        Workstatusdata.forEach(item => {
                            if (item.id === workType) {
                                item.ischecked = true;

                            }
                        });
                        setCheckedListOfStatus(response.data.searchItemDto.workStatus)
                        setIsResetPage(false);
                    });

                }
                setWorkStatusData([...Workstatusdata]);

            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }

    function getInitials(name) {
        if (name) {
            const nameParts = name.split(' ');
            const initials = nameParts.map(part => part.charAt(0));
            const result = initials.join('');
            return result;
        }
    }

    const Worktypedata = [

        {
            key: '1',
            id: 1,
            name: 'Feature Requests',
            ischecked: false

        },

        {
            key: '0',
            id: 0,
            name: 'Bugs',
            ischecked: false

        },
       

        //{
        //    key: '2',
        //    id: 2,
        //    name: 'QA',
        //    ischecked: false

        //},

        {
            key: '3',
            id: 3,
            name: 'Support Requests',
            ischecked: false

        },

    ];

    const Workstatusdata = [
        {
            key: '0',
            id: 0,
            name: 'New',
            ischecked: false
        },
        {
            key: '1',
            id: 1,
            name: 'Active',
            ischecked: false
        },
        {
            key: '2',
            id: 2,
            name: 'Resolved',
            ischecked: false
        },

        {
            key: '3',
            id: 3,
            name: 'ReOpen',
            ischecked: false
        },

        {
            key: '4',
            id: 4,
            name: 'QA',
            ischecked: false
        },

        {
            key: '5',
            id: 5,
            name: 'Closed',
            ischecked: false
        },

    ];

    const handlePageChange = (page, newPageSize) => {
        setCurrentPage(page);
        setPagesize(newPageSize);
        getAllWorkItemsData(false, page, newPageSize)
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            getAllWorkItemsData()
        }
    }

    const uncheckcheckbox = () => {
        setIsChecked(true)
        const checkboxes = document.querySelectorAll('input[name="checkname"][type="checkbox"]');
        checkboxes.forEach((checkbox) => {
            checkbox.checked = false;
        });
        setCheckedListOfType((prevCheckedList) => {
            const newCheckedList = [];
            return newCheckedList;
        });

    };

    const uncheckcheckboxcompany = () => {
        setIsChecked(true)
        const checkboxes = document.querySelectorAll('input[name="checkcompany"][type="checkbox"]');
        checkboxes.forEach((checkbox) => {
            checkbox.checked = false;
        });
        setCheckedListOfComp((prevCheckedList) => {
            const newCheckedList = [];
            return newCheckedList;
        });

    };

    const uncheckcheckboxprojects = () => {
        setIsChecked(true)
        const checkboxes = document.querySelectorAll('input[name="checkproject"][type="checkbox"]');
        checkboxes.forEach((checkbox) => {
            checkbox.checked = false;
        });
        setCheckedListOfIds((prevCheckedList) => {
            const newCheckedList = [];
            return newCheckedList;
        });

    }

    const uncheckcheckboxstatus = () => {
        setIsChecked(true)
        const checkboxes = document.querySelectorAll('input[name="checkstatus"][type="checkbox"]');
        checkboxes.forEach((checkbox) => {
            checkbox.checked = false;
        });
        setCheckedListOfStatus((prevCheckedList) => {
            const newCheckedList = [];
            return newCheckedList;
        });

    }

    const uncheckcategory = () => {
        setIsChecked(true)
        const checkboxes = document.querySelectorAll('input[name="checkcategory"][type="checkbox"]');
        checkboxes.forEach((checkbox) => {
            checkbox.checked = false;
        });
        setCheckedListOfCategories((prevCheckedList) => {
            const newCheckedList = [];
            return newCheckedList;
        });

    }

    const uncheckcheckboxAssign = () => {
        setIsChecked(true)
        const checkboxes = document.querySelectorAll('input[name="checkassign"][type="checkbox"]');
        checkboxes.forEach((checkbox) => {
            checkbox.checked = false;
        });
        setCheckedListOfAssign((prevCheckedList) => {
            const newCheckedList = [];
            return newCheckedList;
        });

    }

    const handleCheck = (id, event) => {
        setIsChecked(true)
        var updatedList = [...checkedListOfIds];
        if (event.target.checked) {
            updatedList = [...checkedListOfIds, id];
        } else {
            updatedList.splice(checkedListOfIds.indexOf(id), 1);
        }
        setCheckedListOfIds([...updatedList]);
    };

    const handleCompanyCheck = (id) => {
        setIsChecked(true)
        var updatedList = [...checkedListOfComp];
        if (event.target.checked) {
            updatedList = [...checkedListOfComp, id];
        } else {
            updatedList.splice(checkedListOfComp.indexOf(id), 1);
        }
        setCheckedListOfComp([...updatedList]);
    };

    const handleCategoryCheck = (id) => {
        setIsChecked(true)
        var updatedList = [...checkedListOfCategories];
        if (event.target.checked) {
            updatedList = [...checkedListOfCategories, id];
        } else {
            updatedList.splice(checkedListOfCategories.indexOf(id), 1);
        }
        setCheckedListOfCategories([...updatedList]);
    };

    const handleAssignCheck = (id) => {
        setIsChecked(true)
        var updatedList = [...checkedListOfAssign];
        if (event.target.checked) {
            updatedList = [...checkedListOfAssign, id];
        } else {
            updatedList.splice(checkedListOfAssign.indexOf(id), 1);
        }
        setCheckedListOfAssign([...updatedList]);
    };

    const handleWorkTypeCheck = (id) => {
        setIsChecked(true)
        var updatedList = [...checkedListOfType];
        if (event.target.checked) {
            updatedList = [...checkedListOfType, id];
        } else {
            updatedList.splice(checkedListOfType.indexOf(id), 1);
        }
        setCheckedListOfType([...updatedList]);
    };

    const handleWorkStatusCheck = (id) => {

        setIsChecked(true)
        var updatedList = [...checkedListOfStatus];
        if (event.target.checked) {
            updatedList = [...checkedListOfStatus, id];
        } else {
            updatedList.splice(checkedListOfStatus.indexOf(id), 1);
        }
        setCheckedListOfStatus([...updatedList]);
    };

    const handleResetButton = () => {
        setIsResetPage(true)
        //setWorkTitle("")
        //setWorkItemId(0);
        uncheckcheckbox();
        uncheckcheckboxAssign();
        uncheckcheckboxprojects();
        uncheckcheckboxstatus();
        uncheckcheckboxcompany();
        uncheckcategory();
        getAllWorkItemsData(true);
    }

    const SearchControl = async () => {
        getAllWorkItemsData();
    }

    const handleEdit = (Id) => {
        history.push('/Workitem/Edit/' + Id)
    }

    const openWorkitemEdit = (id) => {
        // var localurl = "https://localhost:44366/Workitem/Edit/"
        var url = REACT_APP_WORLITEM_LINK
        var win = window.open("/Workitem/Edit/" + id, '_blank');
        win.focus();
    }

    const { confirm } = Modal;
    const handleDelete = () => {
        confirm({
            title: t('Areyousure'),
            okText: t('Yes'),
            okType: 'danger',
            cancelText: t('No'),
            onOk() {
                DeleteWorkItemSearch()
            },
            onCancel() {
            },
        });
    }

    const handleInputChange = (e) => {

        const newValue = e.target.value;
        if (newValue.length == "" || newValue.length == 0) {
            setWorkItemId(0)
            setWorkTitle("")
        }
        const isId = !isNaN(newValue);
        if (isId) {
            setWorkItemId(newValue)
        } else {
            setWorkTitle(newValue);
        }
    };

    const scrollerStyle = {
        maxHeight: '300px', 
        overflowY: 'auto',
        overflowX: 'hidden',
    };

   

    const columns = [
        {
            title: t("ID"),
            dataIndex: "workItemId",
            width: '50px',
            render: (value, item, index) =>
            (<>
                <div className="d-flex align-items-center">
                    <Tooltip title="Open in new tab">
                        <Link className="openWorkItem" onClick={() => openWorkitemEdit(item.workItemId)}>{item.workItemId}</Link>
                    </Tooltip>

                    {item.hasVersionSummary ?
                        <Tooltip title="Version Summary Added">
                            <SmsIcon style={{ color: 'black', marginLeft: '5px' }} fontSize="small" />
                        </Tooltip> : ''
                    }

                </div>
            </>
            )
        },
        {
            title: t("Project"),
            dataIndex: "projectName",

        },
        {
            title: t("Company(Site)"),
            dataIndex: "companyName",
            render: (value, item, index) =>
            (<>
                {item.companyName && item.siteName ?
                    <div className="d-flex align-items-center">
                        <span className="mb-0">{item.companyName}  {'('}{item.siteName}{')'}</span>
                    </div> :
                    <div className="d-flex align-items-center">
                        <span className="mb-0">{item.companyName}</span>
                    </div>
                }

            </>)


        },
        {
            title: t("Title"),
            dataIndex: "workTitle",
            render: (value, item, index) =>
            (<>
                {item.workType == 0 ?
                    <div className="d-flex align-items-center">
                        <BugReportIcon style={{ color: 'darkred' }} fontSize="small" /><Tooltip title={item.workTitle}><p className="ml-2 report-title d-inline-block mb-0">{item.workTitle}</p></Tooltip>
                    </div> :
                    item.workType == 1 ?
                        <div className="d-flex align-items-center">
                            <TaskIcon style={{ color: '#fb9502' }} fontSize="small" /> <Tooltip title={item.workTitle}><p className="ml-2 report-title d-inline-block mb-0">{item.workTitle}</p></Tooltip>
                        </div> :
                        <div className="d-flex align-items-center">
                            <PlaylistAddCheckIcon style={{ color: '#3c98e7' }} fontSize="small" /> <Tooltip title={item.workTitle}><p className="ml-2 report-title d-inline-block mb-0">{item.workTitle}</p></Tooltip>
                        </div>
                }
            </>)
        },

        {
            title: t("AssignedTo"),
            dataIndex: "name",
            render: (value, item, index) =>
            (<>
                <div className="d-flex align-items-center">
                    {
                        item.assignedProPicture ?
                            <img src={item.assignedProPicture} alt="img" className="name-prefix-profile_picture mb-0 mr-2" /> :
                            item.assignedName ?
                                <p className="name-prefix assigned-name  d-inline-block mb-0 mr-2">{getInitials(item.assignedName)}</p> :
                                <p className="name-prefix assigned-name  d-inline-block mr-2 mb-0">UA</p>
                    }
                    <span className="mb-0">{item.assignedName ? item.assignedName : "UnAssigned"}</span>
                </div>
            </>)
        },

        ...(userRole && userRole == "User" ?
            [] :
            [
                {
                    title: t("CreatedBy"),
                    dataIndex: "createdName",
                    className: "",
                    render: (value, item, index) =>

                    (<>
                        <div className="d-flex align-items-center name-col-created">
                            {
                                item.createdProPicture ?
                                    <img src={item.createdProPicture} alt="img" className="name-prefix-profile_picture mb-0 mr-2" /> :
                                    item.createdName ?
                                        <p className="name-prefix assigned-name d-inline-block mb-0 mr-2">{getInitials(item.createdName)}</p> :
                                        <p className="name-prefix assigned-name d-inline-block mr-2 mb-0">UA</p>
                            }
                            <span className="mb-0"> {item.createdName ? `${item.createdName} ` : "UnAssigned"}</span>
                        </div>
                    </>)
                }
            ]
        ),

        {
            title: t("UserCompany"),
            dataIndex: "userCompanyName",
            render: (value, item, index) =>
            (<>
                {item.userCompanyName}
            </>)
        },
        {
            title: t("Priority"),
            dataIndex: "priority",
            render: (value, item, index) =>
            (<>
                {item.priority == 0 ?
                    <div>
                        <span>High</span>
                        <TrendingUpIcon style={{ color: '#90ee90' }} fontSize="small" className="blink workitemHigh" />
                    </div> :
                    item.priority == 1 ?
                        <div>
                            <span>Medium</span>
                        </div> :
                        <div>
                            <span>Low</span>
                        </div>
                }
            </>)
        },
        {
            title: t("CreatedDate"),
            dataIndex: "dateCreated",

            render: (value, item, index) => (
                <>
                    <div className="d-flex align-items-center">
                        {item.createdDate
                            ? new Date(item.createdDate).toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                            })
                            : ""}
                    </div>
                </>
            ),
        },
        {
            title: t("WorkState"),
            render: (value, item, index) =>
            (<>
                {item.status == 0 ?
                    <span>New</span> :
                    item.status == 1 ?
                        <span className="activeWorkItem">Active</span> :
                        item.status == 2 ?
                            <span className="resolvedWorkItem">Resolved</span>
                             :
                            item.status == 3 ?                             
                                <span>ReOpen</span> :
                                item.status == 4 ?
                                    <span>QA</span> :
                                    item.status === 5 ? (
                                        <>
                                            <span className="closedWorkItem">Closed</span>
                                        </>
                                    ) : null}
            </>)

        },
        {
            title: t("ClosedDate"),
            dataIndex: "dateClosed",
            render: (value, item, index) => (
                <>
                    <div className="d-flex align-items-center">
                        {
                            item.updatedDate && item.status == 5
                                ? new Date(item.updatedDate).toLocaleDateString("en-US", {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                })
                                : ""}
                    </div>
                </>
            ),
        },
        {
            title: t("Action"),
            dataIndex: "index",
            render: (data, companyId) => (<><button className="table-btn" >
                <Tooltip title={t("Clicktoedit")}>
                    <img src={Edit_light} onClick={() => handleEdit(companyId.workItemId)}></img>
                </Tooltip>
            </button>
            </>)
        },
    ]

    return (
        <>
            <Title title="Workitems"></Title>
            <Layout>
                <section className="Company_section common">
                    <Toaster />

                    <div className="container workitem-container">

                        <div>
                            <div className="workitem-heading d-flex justify-content-between align-items-center mb-2">
                                <div className="mt-4 w-auto wk-select">
                                    <h4 className="m-0 d-inline-block">{t("WorkItems")}</h4>
                                </div>

                                <div className="create-work-item">
                                    <div class="dropdown">
                                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            <PlaylistAddIcon style={{ color: '#ffffff' }} fontSize="medium" /> {t("CreateWorkItems")}
                                        </button>
                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <>
                                                <li>
                                                    <Link className="dropdown-item" to="/Workitem/Create/Bug">
                                                        <BugReportIcon style={{ color: 'red' }} fontSize="small" />
                                                        <p className="ml-2 mb-0 d-inline-block">{t("ReportBug")}</p>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="dropdown-item" to="/Workitem/Create/Task">
                                                        <TaskIcon style={{ color: '#fb9502' }} fontSize="small" />
                                                        <p className="ml-2 mb-0 d-inline-block">{t("FeatureRequest")}</p>
                                                    </Link>
                                                </li>
                                            </>
                                            {userRoleId && userRoleId >= 7 && (
                                                <li>
                                                    <Link className="dropdown-item" to="/Workitem/Create/TestCase">
                                                        <PlaylistAddCheckIcon style={{ color: '#3c98e7' }} fontSize="small" />
                                                        <p className="ml-2 mb-0 d-inline-block">{t("TestCase")}</p>
                                                    </Link>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="filteration-wrap mb-3">
                                <div className="row">
                                    <div className="col-md-5 ">
                                        <div className="filter-input d-flex align-items-center">
                                            <div className="item-filter position-relative m-3">
                                                <div className="filter-icon position-absolute"><FilterListIcon /></div>
                                                <input className="position-relative form-control" type="text"
                                                    value={workItem}
                                                    placeholder={t("FilterbyTitleorID")}
                                                    onChange={(e) => {
                                                        setWorkItem(e.target.value.trim());
                                                    }}
                                                    onKeyDown={(e) => handleKeyPress(e)}
                                                />

                                                {workTitle == "" && workItemid == 0 ?
                                                    <button onClick={() => SearchControl()} disabled className="searchButton mt-sm-0"> <img src={searchIcon} ></img></button> :
                                                    <button onClick={() => SearchControl()} className="searchButton mt-sm-0"> <img src={searchIcon} ></img></button>
                                                }
                                            </div>

                                            <div class="filter-btns d-flex align-items-center">
                                                {workItem != "" || isSavedSearch || isShowRemove ?
                                                    <div className="bookmark me-2">
                                                        <Tooltip title={t("Clicktoresetsearch")}>
                                                            <RestartAltIcon style={{ color: '#FCB45C' }} fontSize="large" onClick={() => handleResetButton()} />
                                                        </Tooltip>
                                                    </div>
                                                    : ""}

                                                <div className="bookmark me-2">
                                                    <Tooltip title={t("Clickto") + (isSavedSearch ? t("update") : t("save")) + t("search")}>
                                                        <UpdateIcon style={{ color: '#FCB45C' }} fontSize="large" onClick={() => SaveWorkItemSearch()} />
                                                    </Tooltip>
                                                </div>

                                                {isSavedSearch || isShowRemove ?
                                                    <div >
                                                        <Tooltip title={t("Clicktoremovesearch")}>
                                                            <DeleteForeverIcon style={{ color: '#FCB45C' }} fontSize="large" onClick={() => handleDelete()} />
                                                        </Tooltip>
                                                    </div> : ""
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-7">
                                        <div className="more-filter">
                                            <ul classN0ame="list-inline py-3">

                                                <li className="list-inline-item">
                                                    <div class="assigned-drop common-drop dropdown">
                                                        <button class="btn text-white dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                            {t("Category")}
                                                        </button>

                                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            {categories && categories.map((item) =>

                                                                <li className="px-3">

                                                                    <a class="dropdown-item">
                                                                        <input type="checkbox" class="form-check-input cursor-pointer" name="checkcategory"
                                                                            defaultChecked={item.isChecked}
                                                                            onChange={() => {
                                                                                handleCategoryCheck(item.workCategoryId)

                                                                            }}
                                                                        />
                                                                        <label class="form-check-label ml-2 non-clickable" for="exampleCheck1">{item.workDescription}</label>
                                                                    </a>

                                                                </li>
                                                            )}
                                                            <li className="pr-3 text-right" >
                                                                <button
                                                                    type="button"
                                                                    class="btn btn-bordered"
                                                                    onClick={uncheckcategory}
                                                                >
                                                                    {t("Clear")}
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>

                                                <li className="list-inline-item">
                                                    <div class="assigned-drop common-drop dropdown">
                                                        <button class="btn text-white dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                            {t("Company")}
                                                        </button>

                                                        <ul class="dropdown-menu ratio-filter-listing" aria-labelledby="dropdownMenuButton1" style={scrollerStyle}>
                                                            <li className="px-3">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t("SearchByCompany")}
                                                                    value={searchCompany}
                                                                    onChange={handleCompanyChange}
                                                                />
                                                            </li>
                                                            {filteredCompany.map((item) =>

                                                                <li className="px-3">

                                                                    <a class="dropdown-item">
                                                                        <input type="checkbox" class="form-check-input cursor-pointer" name="checkcompany"
                                                                           // defaultChecked={item.isChecked}
                                                                            onChange={() => {
                                                                                handleCompanyCheck(item.subCompanyId)
                                                                               // setCompanyId(item.subCompanyId)
                                                                            }}
                                                                        />
                                                                        <p class="form-check-label ml-2 non-clickable" for="exampleCheck1">{item.subCompanyName}</p>
                                                                    </a>

                                                                </li>
                                                            )}
                                                            <li className="pr-3 text-right">
                                                                <button
                                                                    type="button"
                                                                    class="btn btn-bordered"
                                                                    onClick={() => {

                                                                        uncheckcheckboxcompany()
                                                                        setSearchCompany('');
                                                                    }}
                                                                   // onClick={uncheckcheckboxcompany}
                                                                >
                                                                    {t("Clear")}
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>



                                                <li className="list-inline-item">
                                                    <div class="assigned-drop common-drop dropdown">
                                                        <button class="btn text-white dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                            {t("AssignTo")}
                                                        </button>

                                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            {allDevs && allDevs.map((item) =>
                                                                <li className="px-3">
                                                                    <a class="dropdown-item" >
                                                                        <input type="checkbox" className="form-check-input cursor-pointer" name="checkassign"
                                                                            id="exampleCheck1"
                                                                            defaultChecked={item.isChecked}
                                                                            onChange={() => { handleAssignCheck(item.userId) }}

                                                                        />
                                                                        <label class="form-check-label ml-2 non-clickable" for="exampleCheck1">{item.firstName}</label>
                                                                    </a>

                                                                </li>
                                                            )}
                                                            <li className="px-3">
                                                                <a class="dropdown-item" >
                                                                    <input type="checkbox" class="form-check-input" name="checkassign"
                                                                        //  id="exampleCheck2"
                                                                        onChange={() => { handleAssignCheck(0) }}

                                                                    />
                                                                    <label class="form-check-label ml-2 non-clickable" for="exampleCheck2">{t("UnAssigned")}</label>
                                                                </a>
                                                            </li>

                                                            <li className="pr-3 text-right">
                                                                <button
                                                                    type="button"
                                                                    class="btn btn-bordered"
                                                                    onClick={uncheckcheckboxAssign}
                                                                >
                                                                    {t("Clear")}
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                                <li className="list-inline-item">
                                                    <div class="assigned-drop common-drop dropdown">
                                                        <button class="btn text-white dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                            {t("Project")}
                                                        </button>

                                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            {allProjects && allProjects.map((item) =>

                                                                <li className="px-3">

                                                                    <a class="dropdown-item">
                                                                        <input type="checkbox" class="form-check-input cursor-pointer" name="checkproject"
                                                                            defaultChecked={item.isChecked}

                                                                            onChange={(event) => { handleCheck(item.appId, event) }}
                                                                        />
                                                                        <label className="form-check-label ml-2 non-clickable" for="exampleCheck1">{item.name}</label>
                                                                    </a>

                                                                </li>
                                                            )}
                                                            <li className="pr-3 text-right">
                                                                <button
                                                                    type="button"
                                                                    class="btn btn-bordered"
                                                                    onClick={uncheckcheckboxprojects}
                                                                >
                                                                    {t("Clear")}
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                                <li className="list-inline-item">
                                                    <div class="type-drop common-drop dropdown">
                                                        <button class="btn text-white dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                            {t("WorkType")}
                                                        </button>
                                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            {worktypedata && worktypedata.map((item) =>
                                                                <li className="px-3">
                                                                    <a class="dropdown-item">
                                                                        <input type="checkbox" class="form-check-input cursor-pointer" name="checkname"
                                                                            defaultChecked={item.ischecked}

                                                                            onChange={() => { handleWorkTypeCheck(item.id) }}
                                                                        />
                                                                        <label class="form-check-label ml-2 non-clickable" for="exampleCheck1">{item.name}</label>
                                                                    </a>
                                                                </li>
                                                            )}


                                                            <li className="pr-3 text-right">
                                                                <button
                                                                    type="button"
                                                                    class="btn btn-bordered"
                                                                    onClick={uncheckcheckbox}
                                                                >
                                                                    {t("Clear")}
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                                <li className="list-inline-item">
                                                    <div class="common-drop dropdown">
                                                        <button class="btn text-white dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                            {t("WorkState")}
                                                        </button>
                                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            {workstatusdata && workstatusdata.map((item) =>
                                                                <li className="px-3">
                                                                    <a class="dropdown-item">
                                                                        <input type="checkbox" class="form-check-input cursor-pointer" name="checkstatus"
                                                                            defaultChecked={item.ischecked}
                                                                            onChange={() => { handleWorkStatusCheck(item.id) }}
                                                                        />
                                                                        <label class="form-check-label ml-2 non-clickable" for="exampleCheck1">{item.name}</label>
                                                                    </a>
                                                                </li>
                                                            )}


                                                            <li className="pr-3 text-right">
                                                                <button
                                                                    type="button"
                                                                    class="btn btn-bordered"
                                                                    onClick={uncheckcheckboxstatus}
                                                                >
                                                                    {t("Clear")}
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {loading ? <Skeleton active avatar /> :
                                <>
                                    <div style={{ float: 'left' }}>
                                        <SmsIcon style={{ color: 'black', marginRight: '5px' }} fontSize="small" />
                                        <label>{t("VersionSummary")}</label>
                                    </div>

                                    <div className="text-right">
                                        <AccountTreeIcon style={{ color: '#3c98e7', marginRight: '5px' }} fontSize="small" />
                                        <label>{t("TotalCount")} : {totalCount}</label>
                                    </div>

                                    <div className="Viewer-table index--table position-relative common-table mb-4">
                                        <Table
                                            className='antd--detail-table1'
                                            columns={columns}
                                            dataSource={table}
                                            pagination={showPagination ? {
                                                total: totalCount,
                                                pageSize: pageSize,
                                                current: currentPage,
                                                showSizeChanger: true,
                                                onChange: handlePageChange,
                                                pageSizeOptions: ["50", "100"],
                                            } : false}

                                        />
                                    </div>
                                </>
                            }
                        </div>

                    </div>
                </section>
            </Layout>
        </>
    )
}
export default connect()(Assign);
