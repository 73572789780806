import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import '../../../src/custom.css'
import Header from '../PageLayout/Header';
import Footer from '../PageLayout/Footer';
import Edit_light from "../../assets/images/Edit_light.svg";
import { Select } from 'antd';
import Loader from "../loader";
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import axios from "axios";
import * as url from "../../api/uri_helper";
import Table, { TableProps } from "antd/lib/table";
import Trash_light from "../../assets/images/Trash_light.svg";
import { Modal } from 'antd';
import Add from "../../assets/images/Add.svg";
import Layout from '../Layout';
import { Tooltip, Skeleton } from 'antd';
import { fetchAllProjectdata, fetchUserMenudata } from "../../Redux/Api/fetch.api";
import { getallapps } from '../../Redux/Actions/AccessibilityActions';
import { getuserroleandmenu } from '../../Redux/Actions/UsersActions';
import NotAuthorised from "../Authentication/NotAuthorised";
import { useTranslation } from "react-i18next";
import Title from "../Title";

const ProjectsIndex = () => {
    const { t } = useTranslation('projects');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [table, setTable] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [options, setOptions] = useState({ pagination: { current: 1 }, pageSize: pageSize });
    const [totalIndex, setCurrentIndex] = useState()
    const [pageData, setPageData] = useState({});
    const [total, setTotal] = useState()
    let history = useHistory();
    const loggedUserId = localStorage.getItem('UserId')
    const [roleName, setRoleName] = useState(null);
   

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2000)
        getProjectsDetails();
        getUserDetails();
    }, []);

    const getUserDetails = () => {
        if (loggedUserId == null) {
            history.push("/");
        }
        fetchUserMenudata(loggedUserId)
            .then(response => {
                dispatch(getuserroleandmenu(response.data.data))               
                if (response.data.data.userRoleDetails[0].roleName)
                    setRoleName(response.data.data.userRoleDetails[0].roleName);
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }

    const getProjectsDetails = () => {
        setLoading(true)
        fetchAllProjectdata()
            .then(response => {
                dispatch(getallapps(response.data))
                setTable(response.data.data)
                setOptions({
                    ...options,
                    pagination: {
                        pageSize: options.pagination.pageSize,
                        current: options.pagination.current,
                        total: response.data.count,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        showSizeChanger: true,
                    }
                })
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    }

    const handleChange = (pagination, sorter, current) => {
        const updatedOptions = { ...options, pagination };
        setOptions(updatedOptions);
        setPageData(updatedOptions)
    };

    const handleEdit = (projectId) => {
        history.push('/Project/edit/' + projectId)
    }

    const columns = [
        {
            title: t("SrNo"),
            dataIndex: "",
            render: (text, record, index) => { setCurrentIndex(`${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * 10 + index + 1}`); return `${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * 10 + index + 1}` },

        },

        {
            title: t("ProjectName"),
            dataIndex: "name",
        },

        {
            title: t("Description"),
            dataIndex: "description",
            className: "description_projects",
            render: (description) => <Tooltip title={description}>{description}</Tooltip>,
        },
               

        {
            title: t("Status"),
            dataIndex: "isActive",
            render: (data) => (data ? <p> Active</p> : <p>Inactive </p>),
        },

        {
            title: t("Action"),
            dataIndex: "",
            render: (data, companyId) => (<><button className="table-btn" >
                <img src={Edit_light} onClick={() => handleEdit(data.appId)}></img>
            </button>
                </>)
        },
    ]

    return (
        <div>
            <Title title="Projects"></Title>
            {roleName == null ? <Loader /> : roleName && roleName != "SuperAdmin" && roleName && roleName != "Developer" ? <NotAuthorised></NotAuthorised> :
                <>
                    {loading ? <Loader /> : ''}
                    <Layout>
                        <section className="Company_section common ">
                            <Toaster />
                            <div className="container project-container">
                                <div className="mt-4 w-100 position-relative wk-select">
                                    <h4 className="m-0">{t("Projects")}</h4>
                                    <div className="ml-auto">
                                        <Link className="btn btn-primary d-flex align-items-center btn-new" to="/Project/Add"><img src={Add}></img>{t("AddProjects")}</Link>
                                    </div>
                                </div>
                                <div className="Viewer-table index--table position-relative common-table mb-4">
                                   
                                    <Table
                                        className='table-responsive antd--detail-table1'
                                        columns={columns}
                                        dataSource={table}
                                        pagination={options.pagination.total > 10 && totalIndex > 9 ? options.pagination : false}
                                        onChange={handleChange}
                                        // scroll={{ x: "450px" }}
                                        paginationTotalRows={total}

                                    />
                                </div>
                            </div>
                        </section>
                    </Layout >
                </>


            }

        </div>


    )

}
export default connect()(ProjectsIndex);