import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import Layout from "../Layout";
import Table from "antd/lib/table";
import Loader from "../loader";
import Add from "../../assets/images/Add.svg";
import { getuserroleandmenu } from '../../Redux/Actions/UsersActions';
//import Eyeicon from "../../assets/images/eye-icon.png";
import VisibilityIcon from '@mui/icons-material/Visibility';
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import { fetchAllVersiondata, fetchUserRoledata } from "../../Redux/Api/fetch.api";
import { getallversionsummary, updateapprovedsummary } from '../../Redux/Actions/VersionActions';
import { Tooltip, Switch } from 'antd';
import axios from "axios";
import * as url from "../../api/uri_helper";
import NotAuthorised from "../Authentication/NotAuthorised";
import { useTranslation } from "react-i18next";
import Title from "../Title";

const VersionIndex = () => {
    const { t } = useTranslation('version');
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [table, setTable] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [options, setOptions] = useState({ pagination: { current: 1 }, pageSize: pageSize });
    const [totalIndex, setCurrentIndex] = useState()
    const [pageData, setPageData] = useState({});
    const [total, setTotal] = useState()
    let history = useHistory();
    const { REACT_APP_WORLITEM_LINK } = process.env;
    const loggedUserId = localStorage.getItem('UserId')
    const [roleName, setRoleName] = useState(null);

    useEffect(() => {       
        getBannerDetails();
        getRoleDetails();
    }, []);

    const getBannerDetails = () => {
        setLoading(true)
        fetchAllVersiondata()
            .then(response => {
                dispatch(getallversionsummary(response.data))
                setTable(response.data.data)
                setOptions({
                    ...options,
                    pagination: {
                        pageSize: options.pagination.pageSize,
                        current: options.pagination.current,
                        total: response.data.count,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        showSizeChanger: true,
                    }
                })
                setLoading(false)
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    }

    const getRoleDetails = () => {
        if (loggedUserId == null) {
            history.push("/");
        }
        fetchUserRoledata(loggedUserId)
            .then(response => {
                dispatch(getuserroleandmenu(response.data))
                if (response.data.data.userRoleDetails[0].roleName)
                    setRoleName(response.data.data.userRoleDetails[0].roleName);
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }

    const handleChange = (pagination, sorter, current) => {
        const updatedOptions = { ...options, pagination };
        setOptions(updatedOptions);
        setPageData(updatedOptions)
    };   

    const handleReview = (Id) => {
       // var localurl = "https://localhost:44366/Workitem/Edit/" 
        var url = REACT_APP_WORLITEM_LINK 
        var win = window.open(url + Id, '_blank');       
        win.onload = function () {
            var targetElement = win.document.getElementById('version_summary');
            if (targetElement) {
                var targetPosition = targetElement.offsetTop;
                win.scrollTo(0, targetPosition);
            }
        };
    }

    const handleVersionChange = async (checked, record) => {        
        if (record.workItemID) {
            const response =  await axios.put(url.UPDATE_VERSIONSUMMARY, {
                WorkItemID: record.workItemID,
                IsApprovedSummary: checked,               
            }).then(response => {
                dispatch(updateapprovedsummary(response.data))
                if (!response.data.success) // False 
                {
                    toast.error(t("Failedonversionupdate"), {
                        className: "toastSuccess",
                    },);                   
                }
                else {
                    toast.success((checked == true ? t("VersionSummarySuccessfullyApproved") : t("VersionSummaryDenied")), {
                        className: "toastSuccess",
                    },);                   
                }
            })
                .catch((err) => {
                    console.log("Err: ", err);                    
                });
        }

    }
    const getFullDate = (date) => {
        const newDate = new Date(date);
        const returnDate = ((newDate.getMonth() > 8) ? (newDate.getMonth() + 1) : ('0' + (newDate.getMonth() + 1))) + '-' + ((newDate.getDate() > 9) ? newDate.getDate() : ('0' + newDate.getDate())) + '-' + newDate.getFullYear();
        const dateAndTime = date.split("T");
        const time = dateAndTime[1].split(":");
        const seconds = parseInt(time[2]);
        const newtime = time[0] + " : " + time[1] + " : " + seconds;
        const times = time[0] > 12 ? time[0] % 12 : time[0];
        const timeslatest = times < 9 ? ('0' + times) : times;
        const newtime1 = timeslatest + " : " + time[1] + " : " + seconds;
        if (time[0] && time[0] >= 12) {
            return (
                //dateAndTime[0].split("-").reverse().join("-") + ", " + newtime1 + " PM "
                returnDate + ", " + newtime1 + " PM "
            );
        } else {
            return (
                //dateAndTime[0].split("-").reverse().join("-") + ", " + newtime + " AM "
                returnDate + ", " + newtime + " AM "
            );
        }
    };
    const columns = [
        {
            title: t("SrNo"),
            dataIndex: "",
            render: (text, record, index) => { setCurrentIndex(`${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * 10 + index + 1}`); return `${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * 10 + index + 1}` },

        },

        {
            title: t("ProjectName"),
            dataIndex: "projectName",
        },

        {
            title: t("VersionSummary"),
            dataIndex: "versionSummary",
            className: "description_projects",
            render: (versionSummary) => <Tooltip title={versionSummary}>{versionSummary}</Tooltip>,
        },

        {
            title: t("VersionDate"),
            dataIndex: "updatedDate",
            render: (updatedDate) => getFullDate(updatedDate),

        },

        {
            title: t("Approve"),
            key: 'index',
            render: (text, record, index) => (
                <div className="alarm-toggle--wrap">
                    <Switch className="ms-2"
                        defaultChecked={record.isApprovedSummary}
                        onChange={(checked) => handleVersionChange(checked,record)} 
                    />
                </div>
            ),          
        },     

        {
            title: t("Review"),
            key: 'index',
            render: (data, companyId) => (<>
                <Tooltip title={t("ReviewVerisonSummary")}>
                    <VisibilityIcon style={{ color: '#FBAF5D' }} fontSize="small" onClick={() => handleReview(data.workItemID)}/> 
                    </Tooltip>
                
            </>)
        }
    ]

    return (
        <div>
            <Title title="Versions"></Title>
            {roleName == null ? <Loader /> : roleName && roleName != "SuperAdmin" && roleName && roleName != "Developer" ? <NotAuthorised></NotAuthorised> :
                <>

                    <Layout>
                        <section className="Company_section common ">
                            <Toaster />
                            {loading ? <Loader /> : ''}
                            <div className="container banner-container">
                                <div className="mt-4 w-100 position-relative wk-select">
                                    <h4 className="m-0">{t("Versions")}</h4>
                                    <div className="ml-auto">
                                    </div>
                                </div>

                                <div className="Viewer-table index--table position-relative common-table mb-4">
                                    <Table
                                        className='table-responsive antd--detail-table1'
                                        columns={columns}
                                        dataSource={table}
                                        pagination={options.pagination.total > 10 && totalIndex > 9 ? options.pagination : false}
                                        onChange={handleChange}
                                        paginationTotalRows={total}
                                    />
                                </div>
                            </div>

                        </section>
                    </Layout>
                </>
            }
        </div>
    )

}
export default connect()(VersionIndex);