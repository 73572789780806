import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import configureStore from './Store/configureStore';
import App from './App';
import { HelmetProvider } from 'react-helmet-async';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    <Provider store={configureStore}>
        <HelmetProvider>
            <App />
        </HelmetProvider>
    </Provider>,
    document.getElementById('root'));


