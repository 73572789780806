import Layout from "../Layout";
import React, { useEffect, useCallback, useMemo, useState, useRef } from "react";
import Loader from "../loader";
import { red } from "@mui/material/colors";
import { Link, useHistory, useLocation } from "react-router-dom";
import DirectionsIcon from "@mui/icons-material/Directions";
import { GoogleMap, useLoadScript, Marker, MarkerF, InfoWindow, } from "@react-google-maps/api";
import { fetchCompanyDataBySiteId, fetchSiteById } from "../../Redux/Api/fetch.api";
import Arrow_leftbrown from "../../assets/images/Arrow_leftbrown.svg";
import Arrow_Backicon from "../../assets/images/backiconmap.png";
import { Tooltip } from 'antd';
import { useTranslation } from "react-i18next";
import Title from "../Title";


const CompanyPointing = () => {
    const { t } = useTranslation('siteMap'); 
    const { REACT_APP_GOOGLE_KEY } = process.env;
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: REACT_APP_GOOGLE_KEY,
    });
   // const center = useMemo(() => ({ lat: 32.9656398, lng: -96.846783699999 }), []);
    const center = useMemo(() => ({ lat: 38.122705, lng: -85.717826 }), []);
    const [loading, setLoading] = useState(false);   
    const [latitude, setLatitude] = useState();
    const [longitude, setLongitude] = useState();
    const [activeMarker, setActiveMarker] = useState(null);
    const [selected, setSelected] = useState("off");   
    const [allData, setAllData] = useState();   
    const [currentId, setCurrentId] = useState(null);
    const [siteid, setSiteId] = useState();
    const [siteName, setSiteName] = useState('');
    const [markerClick, setMarkerClick] = useState(false);
    const [companyId, setComapnyId] = useState();

    useEffect(() => {
        let urlData = location.pathname.split('/');
        getCompanyDataBySiteId(urlData[2]);
       
    }, [])

    const getCompanyDataBySiteId = (siteid) => {
        setLoading(true)
        fetchCompanyDataBySiteId(siteid)
            .then(response => {                
                setAllData(response.data.data)             
                setSiteName(response.data.data[0].siteName)
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
        setLoading(false)
    }
     
 
    const handleMarkerClick = (data) => {     
        setMarkerClick(true);
        setComapnyId(data)
    }

    const handleInfoClose = () => {
        setSelected(null)
        setComapnyId()
    }

    if (loadError) {
        return <div>{t("Errorloadingmaps")}</div>;
    }

    if (!isLoaded) {
        return <div>{t("LoadingMaps")}</div>;
    }

   
    return (
        <Layout title="Products located on Google Maps">
            <>
                <Title title="Site Map"></Title>                      
            <div className="map-product_container">                                 
                <GoogleMap                  
                    onClick={() => setSelected(null)}
                        mapContainerStyle={{ width: "100%", height: "80vh" }}
                        center={center}
                        zoom={5}                 
                    >
                        {siteName && (
                            <div className="site-name-overlay">
                                <Tooltip title="Click to back">
                                <Link className="backBTNmap" to="/Site">
                                    <img src={Arrow_Backicon} alt="Back Icon" />
                                    </Link>
                                </Tooltip>
                                <h4><span>{t("SiteName")}: </span> {siteName}</h4>
                            </div>
                       )}
                       
                    {allData && allData.map((data, index) => (
                        data.latitude && data.longitude ?
                            <Marker
                               // key={data.companyId}
                                key={index}
                                position={{ lat: data.latitude, lng: data.longitude }}
                                onClick={() => handleMarkerClick(data.companyId)} // Pass 'data' to the click handler
                            >
                                {/* Conditional rendering of InfoWindow */}
                                {companyId == data.companyId && markerClick && (
                                    <InfoWindow
                                        position={{ lat: data.latitude, lng: data.longitude }}
                                        onCloseClick={() => handleInfoClose()}
                                    >
                                        <div className="map-detail-hove_card">

                                            <div className="map-detail-hove_card-img">
                                                <img src={data.companyLogo}></img>
                                            </div>
                                            <div className="map-detail-hove_card-content">
                                                <div className="map-detail_inner">
                                                    {/* Display data for the selected marker */}
                                                    <h5 className="mt-0">
                                                        <b>{data.companyName}</b>
                                                    </h5>
                                                    <span className="map-items_address d-block">
                                                        <small>{data.address}</small>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </InfoWindow>
                                )}
                            </Marker> : ''
                    ))}


                </GoogleMap>
            </div>
            </>
        </Layout>
    );
};
export default CompanyPointing;
